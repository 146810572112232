import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './LangSwitcher.module.scss';
import { ReactComponent as DefaultIcon } from 'assets/icons/lang-switcher.svg';
import { ReactComponent as BluetIcon } from 'assets/icons/langSwitcherBlue.svg';
import { classNamesInterface } from 'types/types';
import LangSwitcherMenu from 'components/LangSwitcher/LangSwitcherMenu/LangSwitcherMenu';

interface languagesListInterface {
  label: string;
  native?: string;
  value: string;
  translateToText?: string;
  seeOriginalText?: string;
}

interface propsInterface extends classNamesInterface {
  variant?: 'square';
  changeLanguage: Function;
  languagesList: languagesListInterface[];
  currentLang?: string;
}

const LangSwitcher = ({ languagesList = [], className, variant, changeLanguage, currentLang }: propsInterface) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const showMenu = (): void => setMenuVisible(true);
  const hideMenu = (): void => setMenuVisible(false);
  const Icon = variant === 'square' ? <BluetIcon /> : <DefaultIcon />;

  const cn = classNames.bind(styles);
  const switcherClasses = cn({
    lang_switcher: true,
    [variant]: variant,
    [className]: className,
  });

  return (
    languagesList.length > 1 && (
      <button type="button" className={switcherClasses} onMouseEnter={showMenu} onMouseLeave={hideMenu}>
        {Icon}
        <LangSwitcherMenu
          currentLang={currentLang}
          langList={languagesList}
          isVisible={menuVisible}
          changeLanguage={changeLanguage}
        />
      </button>
    )
  );
};
export default LangSwitcher;
