import React from 'react';
import RenderBackgroundColor from 'components/CustomizePlatform/components/SiteUserAvatar/RenderBagroundColor';
import RenderClothesAvatar from 'components/CustomizePlatform/components/SiteUserAvatar/RenderClothesStyleAvatar/RenderClothesAvatar';
import RenderHairStyle from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle';
import RenderAccessoriesStyleAvatar from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyleAvatar';
import RenderHairFrontStyle from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairFrontStyle';

const PreviewSiteUserAvatar = ({ userValues }) => {
  return (
    <>
      <rect x="132.5" y="102.5" width="35" height="35" rx="17.5" fill="url(#paint0_linear_8134_58100)" />
      {RenderBackgroundColor(userValues.avatar_background_color)}
      {<RenderHairStyle hairStyle={userValues.hair_style} hairColor={userValues.hair_color} />}
      <rect x="132.5" y="102.5" width="35" height="35" rx="17.5" stroke="#E8F6FF" />
      <g clipPath="url(#clip0_8134_58100)">
        <rect
          x="146.813"
          y="119.25"
          width="6.34836"
          height="12"
          rx="1.29158"
          fill={userValues.body_color}
          stroke="#323232"
          strokeWidth="0.375"
          strokeLinecap="round"
        />
        <path
          d="M146.988 126.989V123.153H153.375C152.894 125.12 150.025 127.095 146.988 126.989Z"
          fill="#7E7E7E"
          fillOpacity="0.07"
        />
        <path
          d="M146.063 118.266C146.063 119.482 144.971 120.469 143.625 120.469C142.279 120.469 141.188 119.482 141.188 118.266C141.188 117.049 142.279 116.062 143.625 116.062C144.971 116.062 146.063 117.049 146.063 118.266Z"
          fill={userValues.body_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.625 120.094C144.801 120.094 145.688 119.241 145.688 118.266C145.688 117.291 144.801 116.438 143.625 116.438C142.449 116.438 141.563 117.291 141.563 118.266C141.563 119.241 142.449 120.094 143.625 120.094ZM143.625 120.469C144.971 120.469 146.063 119.482 146.063 118.266C146.063 117.049 144.971 116.062 143.625 116.062C142.279 116.062 141.188 117.049 141.188 118.266C141.188 119.482 142.279 120.469 143.625 120.469Z"
          fill="#323232"
        />
        <path
          d="M153.844 118.266C153.844 119.482 154.935 120.469 156.281 120.469C157.627 120.469 158.719 119.482 158.719 118.266C158.719 117.049 157.627 116.062 156.281 116.062C154.935 116.062 153.844 117.049 153.844 118.266Z"
          fill={userValues.body_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.281 120.094C155.106 120.094 154.219 119.241 154.219 118.266C154.219 117.291 155.106 116.438 156.281 116.438C157.457 116.438 158.344 117.291 158.344 118.266C158.344 119.241 157.457 120.094 156.281 120.094ZM156.281 120.469C154.935 120.469 153.844 119.482 153.844 118.266C153.844 117.049 154.935 116.062 156.281 116.062C157.627 116.062 158.719 117.049 158.719 118.266C158.719 119.482 157.627 120.469 156.281 120.469Z"
          fill="#323232"
        />
        <path
          d="M156.375 117.306C156.375 121.579 154.106 123.938 150.003 123.938C145.9 123.938 143.531 121.579 143.531 117.306C143.531 113.033 145.9 108.375 150.003 108.375C154.106 108.375 156.375 113.033 156.375 117.306Z"
          fill={userValues.body_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M154.457 121.964C155.45 120.931 156 119.379 156 117.306C156 115.228 155.447 113.068 154.411 111.439C153.379 109.816 151.891 108.75 150.003 108.75C148.113 108.75 146.6 109.817 145.543 111.442C144.483 113.072 143.906 115.231 143.906 117.306C143.906 119.375 144.479 120.927 145.496 121.961C146.512 122.995 148.022 123.563 150.003 123.563C151.983 123.563 153.466 122.995 154.457 121.964ZM150.003 123.938C154.106 123.938 156.375 121.579 156.375 117.306C156.375 113.033 154.106 108.375 150.003 108.375C145.9 108.375 143.531 113.033 143.531 117.306C143.531 121.579 145.9 123.938 150.003 123.938Z"
          fill="#323232"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.815 117.007C149.915 117.036 149.972 117.14 149.943 117.24C149.742 117.934 149.637 118.298 149.626 118.96C149.84 119.041 150.072 119.066 150.467 119.063C150.571 119.062 150.655 119.145 150.656 119.249C150.657 119.352 150.574 119.437 150.47 119.438C149.99 119.441 149.682 119.407 149.357 119.252C149.291 119.221 149.25 119.155 149.25 119.083C149.25 118.287 149.368 117.879 149.582 117.138L149.583 117.135C149.612 117.036 149.716 116.979 149.815 117.007Z"
          fill="#323232"
        />
        <path
          d="M147.281 117.345C147.281 117.811 146.987 118.188 146.625 118.188C146.263 118.188 145.969 117.811 145.969 117.345C145.969 116.879 146.263 116.501 146.625 116.501C146.987 116.501 147.281 116.879 147.281 117.345Z"
          fill="#323232"
        />
        <path
          d="M147.095 117.138C147.095 117.241 147.008 117.325 146.901 117.325C146.794 117.325 146.707 117.241 146.707 117.138C146.707 117.034 146.794 116.951 146.901 116.951C147.008 116.951 147.095 117.034 147.095 117.138Z"
          fill={userValues.body_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.375 117.345C147.375 117.839 147.06 118.282 146.625 118.282C146.19 118.282 145.875 117.839 145.875 117.345C145.875 116.85 146.19 116.407 146.625 116.407C147.06 116.407 147.375 116.85 147.375 117.345ZM146.625 118.188C146.987 118.188 147.281 117.811 147.281 117.345C147.281 116.879 146.987 116.501 146.625 116.501C146.263 116.501 145.969 116.879 145.969 117.345C145.969 117.811 146.263 118.188 146.625 118.188Z"
          fill="#323232"
        />
        <path
          d="M153.938 117.281C153.938 117.747 153.644 118.125 153.281 118.125C152.919 118.125 152.625 117.747 152.625 117.281C152.625 116.815 152.919 116.438 153.281 116.438C153.644 116.438 153.938 116.815 153.938 117.281Z"
          fill="#323232"
        />
        <path
          d="M153.751 117.074C153.751 117.177 153.665 117.261 153.557 117.261C153.45 117.261 153.364 117.177 153.364 117.074C153.364 116.971 153.45 116.887 153.557 116.887C153.665 116.887 153.751 116.971 153.751 117.074Z"
          fill={userValues.body_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.028 120.238C149.103 120.167 149.222 120.171 149.293 120.247C149.406 120.367 149.61 120.51 149.846 120.551C150.068 120.59 150.337 120.541 150.613 120.247C150.684 120.171 150.803 120.167 150.878 120.238C150.954 120.309 150.958 120.428 150.887 120.503C150.526 120.888 150.131 120.981 149.782 120.921C149.446 120.862 149.173 120.666 149.02 120.503C148.949 120.428 148.952 120.309 149.028 120.238Z"
          fill="#323232"
        />
        <g filter="url(#filter0_f_8134_58100)">
          <ellipse cx="146.672" cy="119.953" rx="0.890625" ry="0.515625" fill="#FC9D80" />
        </g>
        <g filter="url(#filter1_f_8134_58100)">
          <ellipse cx="154.078" cy="119.953" rx="0.890625" ry="0.515625" fill="#FC9D80" />
        </g>
        <path
          d="M148.914 116.888C149.752 116.542 150.208 116.533 150.992 116.888"
          stroke="#323232"
          strokeWidth="0.375"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.82 114.38L147.154 114.714L144.383 117.485L144.253 117.354C144.237 117.294 144.225 117.233 144.214 117.171C144.183 116.988 144.174 116.806 144.184 116.628L146.436 114.377C146.566 114.368 146.694 114.369 146.82 114.38Z"
          fill={userValues.body_color}
          fillOpacity="0.15"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.718 116.953C155.734 116.759 155.726 116.56 155.692 116.36C155.685 116.315 155.676 116.27 155.665 116.226L155.525 116.085L152.599 119.007L152.671 119.079C152.94 119.151 153.226 119.177 153.517 119.15L155.718 116.953Z"
          fill={userValues.body_color}
          fillOpacity="0.15"
        />
        {RenderClothesAvatar(userValues.clothes)}
        {<RenderHairFrontStyle hairStyle={userValues.hair_style} hairColor={userValues.hair_color} />}
        {<RenderAccessoriesStyleAvatar accessories={userValues.accessories} />}
      </g>
      <defs>
        <filter
          id="filter0_f_8134_58100"
          x="144.656"
          y="118.312"
          width="4.03125"
          height="3.28125"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.5625" result="effect1_foregroundBlur_8134_58100" />
        </filter>
        <filter
          id="filter1_f_8134_58100"
          x="152.062"
          y="118.312"
          width="4.03125"
          height="3.28125"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.5625" result="effect1_foregroundBlur_8134_58100" />
        </filter>
        <linearGradient
          id="paint1_linear_8134_58100"
          x1="146.198"
          y1="114.406"
          x2="148.437"
          y2="119.004"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8F6FF" />
          <stop offset="1" stopColor="#C0C8FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8134_58100"
          x1="152.905"
          y1="114.406"
          x2="155.145"
          y2="119.004"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8F6FF" />
          <stop offset="1" stopColor="#C0C8FE" />
        </linearGradient>
      </defs>
    </>
  );
};

export default PreviewSiteUserAvatar;
