import classNames from 'classnames/bind';

import styles from './CertificateModal.module.scss';
import CertificateForm from 'components/shared/ui/CertificateForm/CertificateForm';

import { ReactComponent as DecorImg } from 'assets/icons/ball-cert-decor.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon-rev.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_blue.svg';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import useLocalization from 'app/hooks/useLocalization';

type CertificateModalProps = {
  className?: string;
  data: { courseId?: string; courseName?: string; closeModal?: () => void };
};

export default function CertificateModal({ className, data = {} }: CertificateModalProps) {
  const cn = classNames.bind(styles);
  const { toLocalize } = useLocalization();
  const { closeModal, courseId, courseName } = data;

  const mainCls: string = cn({
    content: true,
    [String(className)]: className,
  });

  return (
    <div className={mainCls}>
      <CloseIcon className={styles.close} onClick={closeModal} />
      <div className={styles.left}>
        <SimpleBar className={styles.scroll}>
          <h3 className={styles.course_name}>#{courseName}</h3>
          <h2 className={styles.title}>{toLocalize('congrats')}</h2>
          <p className={styles.text}>{toLocalize('certificate_modal_text')}</p>
          <div className={styles.info}>
            <InfoIcon className={styles.info_icon} />
            <p className={styles.info_text}>{toLocalize('certificate_info_text')}</p>
          </div>
          <CertificateForm courseId={courseId} buttonClassName={styles.submit} />
        </SimpleBar>
      </div>
      <div className={styles.right}>
        <DecorImg className={styles.decor} />
      </div>
    </div>
  );
}
