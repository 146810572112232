import React from 'react';

const HairStyle3 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.08 105C153.815 105 156.62 107.116 157.969 110.393C160.828 111.127 163.126 113.453 163.126 116.471C163.126 117.542 162.817 118.594 162.287 119.546C164.191 121.104 165.349 123.291 165.284 125.683C165.159 130.232 162.034 134.212 156.6 134.063C154.285 134 151.926 133.198 149.988 131.947C148.021 133.231 145.601 134.028 143.25 134.028C137.943 134.028 135 129.964 135 125.413C135 123.436 135.812 121.622 137.166 120.202C136.57 119.201 136.219 118.081 136.219 116.939C136.219 113.9 138.55 111.561 141.437 110.846C143.029 107.379 146.301 105 150.08 105Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle3;
