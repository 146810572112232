import classNames from 'classnames/bind';

import styles from './CertificateForm.module.scss';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from 'components/common/Button/Button';
import useLocalization from 'app/hooks/useLocalization';
import { ReactComponent as Icon } from 'assets/icons/cert-btn-icon.svg';
import { coursesApi } from 'features/courses/coursesApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

type CertificateFormProps = {
  className?: string;
  courseId: string;
  buttonClassName?: string;
};

export default function CertificateForm({ className, courseId, buttonClassName }: CertificateFormProps) {
  const cn = classNames.bind(styles);
  const { toLocalize } = useLocalization();
  const [getCertificate, { isLoading }] = coursesApi.useGetCourseCertificateMutation();
  const { refetch } = coursesApi.useGetSingleCourseQuery(courseId, { refetchOnMountOrArgChange: true });

  const mainCls: string = cn({
    certificateForm: true,
    [String(className)]: className,
  });

  return (
    <Formik
      validateOnMount
      initialValues={{ name: '' }}
      validationSchema={Yup.object({ name: Yup.string().required('Required').min(2).max(40) })}
      onSubmit={async (values, { setFieldError, resetForm }) => {
        const defaultError = 'Sorry, error occurred. Please try again later.';
        const response: { data?: any; error?: FetchBaseQueryError | SerializedError } = await getCertificate({
          courseId,
          name: values.name,
        });

        if (response.data) {
          window.open(window.URL.createObjectURL(response.data), '_blank');
          refetch();
          resetForm();
        }

        if (response.error) {
          try {
            // @ts-ignore
            const errData = response.error.data;
            const errMessage: string = errData.errors?.map((err: { msg: string; param: string }) => err.msg).join('; ');

            setFieldError('name', errMessage || defaultError);
          } catch (error) {
            setFieldError('name', defaultError);
          }
        }
      }}>
      {({ isValid }) => (
        <Form className={mainCls}>
          <label className={styles.label} htmlFor="name">
            {toLocalize('name')} <span className={styles.required}>*</span>
          </label>
          <Field className={styles.input} name="name" type="text" placeholder={toLocalize('enter_name')} />
          <ErrorMessage className={styles.error} name="name" component="p" />

          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!isValid || isLoading}
            className={cn('button', 'button_fill', styles.submit, { [String(buttonClassName)]: buttonClassName })}>
            <p className={styles.inner}>
              {toLocalize('generate_certificate')}
              <Icon className={styles.icon} />
            </p>
          </Button>
        </Form>
      )}
    </Formik>
  );
}
