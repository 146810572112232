import React from 'react';
import BangStyle1 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style1';
import BangStyle2_3_4_5_6_7_8_9 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style2';
import BangStyle11 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style3';
import BangStyle12 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style4';
import BangStyle13 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style5';
import BangStyle14 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style6';
import BangStyle15 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style7';
import BangStyle16 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style8';
import BangStyle17 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style9';
import BangStyle18 from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle/style10';

const renderBangStyle = (userValues, userColor) => {
  switch (userValues) {
    case 1:
      return <BangStyle1 userColor={userColor} />;
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return <BangStyle2_3_4_5_6_7_8_9 userColor={userColor} />;
    case 10:
      return <></>;
    case 11:
      return <BangStyle11 userColor={userColor} />;
    case 12:
      return <BangStyle12 userColor={userColor} />;
    case 13:
      return <BangStyle13 userColor={userColor} />;
    case 14:
      return <BangStyle14 userColor={userColor} />;
    case 15:
      return <BangStyle15 />;
    case 16:
      return <BangStyle16 userColor={userColor} />;
    case 17:
      return <BangStyle17 userColor={userColor} />;
    case 18:
      return <BangStyle18 />;
    default:
      return <></>;
  }
};

export default renderBangStyle;
