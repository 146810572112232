import classNames from 'classnames/bind';

import styles from './Levels.module.scss';

type LevelsProps = {
  className?: string;
  level: number;
};

export function Levels({ className, level = 1 }: LevelsProps) {
  const cn = classNames.bind(styles);

  const mainCls: string = cn('levels', `level_${level}`, { [String(className)]: className });

  return (
    <div className={mainCls}>
      <div className={styles.level}></div>
      <div className={styles.level}></div>
      <div className={styles.level}></div>
      <div className={styles.level}></div>
    </div>
  );
}
