import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import constants from 'features/constants';
import { modalOpen } from 'features/modal/modalSlice';
import { modulesApi } from 'features/modules/modulesApi';
import styles from './FeedbackEducator.module.scss';

const FeedbackEducator = () => {
  const dispatch = useAppDispatch();
  const { modalData } = useAppSelector((state) => state.modal);

  const { data: getDataSingleModule } = modulesApi.useGetSingleModuleQuery(modalData[1]);

  const openFeadbackModal = () => {
    dispatch(
      modalOpen({
        modalType: constants.FEEDBACK,
        modalData: [...modalData],
        modalSize: '',
        modalIsRequired: getDataSingleModule?.surveyMandatory,
      })
    );
  };

  return (
    <div className={styles.feedback_content}>
      <p>
        Your Story can not be published and will be saved as a Draft. You may review your story on the My stories page.
      </p>
      <button className={'button button_fill'} onClick={() => openFeadbackModal()}>
        Proceed to the next step
      </button>
    </div>
  );
};

export default FeedbackEducator;
