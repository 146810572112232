import React from 'react';
import styles from '../../Customization/Customization.module.scss';
import classNames from 'classnames';

const DefaultUserAvatar = ({ customStyles }) => {
  return (
    <div className={classNames(styles.preview_user_avatar, customStyles)}>
      <div className={classNames(styles.default_avatar)}>
        <svg width="384" height="384" viewBox="0 0 384 384" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <rect width="384" height="384" fill="#C0E3FB" />
            <path
              d="M191.5 67C139.896 67 98.0625 109.981 98.0625 163C98.0625 216.019 139.896 259 191.5 259C243.104 259 284.937 216.019 284.937 163C284.937 109.981 243.104 67 191.5 67ZM81.7098 279.4C59.7836 279.4 42.0002 297.652 42 320.189L42 327.4C42 363.453 60.1425 390.442 87.9728 407.85C115.34 424.968 152.126 433 191.5 433C230.874 433 267.66 424.968 295.027 407.85C322.857 390.442 341 363.453 341 327.4L341 320.188C341 297.651 323.216 279.4 301.291 279.4H81.7098Z"
              fill="white"
            />
          </g>
          <defs>
            <rect width="384" height="384" fill="white" />
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default DefaultUserAvatar;
