import React, { useState } from 'react';
import BtnSlider from 'components/pages/Account/components/Slider/BtnSlider';
import styles from './Slider.module.scss';
import classNames from 'classnames/bind';
import { setUserAccountData } from 'features/userAccount/userAccountSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const AccountSliderSetting = ({ initialState, sectionTitle, initialValue, sliderItems, setFieldValue, fieldName }) => {
  const [slideIndex, setSlideIndex] = useState(initialValue);
  const dispatch = useAppDispatch();
  const setinitialValueSlider = (fieldValue) => {
    let spredValues = {
      ...initialState,
      [fieldName]: fieldValue,
    };
    dispatch(setUserAccountData({ userSiteSettings: spredValues }));
  };
  const nextSlide = () => {
    if (slideIndex !== sliderItems.length) {
      setSlideIndex(slideIndex + 1);
      setinitialValueSlider(slideIndex + 1);
      setFieldValue(fieldName, slideIndex + 1);
    } else if (slideIndex === sliderItems.length) {
      setSlideIndex(1);
      setinitialValueSlider(1);
      setFieldValue(fieldName, 1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
      setinitialValueSlider(slideIndex - 1);
      setFieldValue(fieldName, slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(sliderItems.length);
      setinitialValueSlider(sliderItems.length);
      setFieldValue(fieldName, sliderItems.length);
    }
  };
  const cn: Function = classNames.bind(styles);
  const slideStyle = (compare: boolean) =>
    cn({
      slide: true,
      active: compare,
    });
  let sliderName = '';
  return (
    <>
      <div className={styles.section_title}>{sectionTitle}</div>
      <div className={styles.container_slider}>
        <BtnSlider moveSlide={prevSlide} direction={'prev'} />
        {sliderItems.map((singleItem, index) => {
          if (initialValue === singleItem.id) {
            sliderName = singleItem.title;
          }

          return (
            <div key={singleItem.id} className={slideStyle(slideIndex === index + 1)}>
              {sliderName}
            </div>
          );
        })}
        <BtnSlider moveSlide={nextSlide} direction={'next'} />
      </div>
    </>
  );
};

export default AccountSliderSetting;
