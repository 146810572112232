/* eslint-disable operator-linebreak */
import React, { useEffect, useRef } from 'react';
import styles from '../SingleStory.module.scss';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { ReactComponent as CloseIcon } from 'assets/icons/closeModal.svg';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import PreviewUserAvatar from 'components/pages/Account/components/PreviewUserAvatar/PreviewUserAvatar';
import RecentStories from 'components/pages/Stories/RecentStories';
import { useAppSelector } from 'app/hooks/reduxHooks';
import { localizationSite } from 'features/localization/localizationSlice';
import { authorsInfo } from 'features/authorsInfo/AuthorsInfo';
import EmptyCourseCard from 'components/EmptyCourseCard/EmptyCourseCard';

interface userInterface {
  setUserInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAuthorStory?: any;
  handleLoadMoreStories?: any;
  requestParams?: Object;
  setUserInfoData?: any;
}

const UserInfo = ({
  setUserInfo,
  handleAuthorStory,
  handleLoadMoreStories,
  requestParams,
  setUserInfoData,
}: userInterface) => {
  const scrollRef = useRef(null);
  const stateRef: any = useRef();

  const lang = useAppSelector((state) => state.login.acceptLanguage);
  const { data: getlocalizationInfo } = localizationSite.useGetTranslationsQuery(lang);

  const { data: authorMainInfo }: any = authorsInfo.useGetAuthorsInfoQuery(requestParams);

  useEffect(() => {
    if (authorMainInfo) {
      setUserInfoData(authorMainInfo);
    }
  }, [authorMainInfo]);

  useEffect(() => {
    if (authorMainInfo) stateRef.current = authorMainInfo;
  }, [authorMainInfo]);

  const handleScroll = () => {
    if (
      scrollRef?.current?.scrollHeight - (scrollRef.current.scrollTop + scrollRef.current.offsetHeight) <= 1 &&
      stateRef?.current?.stories?.length < authorMainInfo?.storiesConnection?.aggregate?.count
    ) {
      handleLoadMoreStories({ user_id: authorMainInfo?.stories[0]?.userId, skip: stateRef.current?.stories?.length });
    }
  };

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', handleScroll);
  }, [authorMainInfo, scrollRef]);

  return (
    <SimpleBar
      className={styles.detailed_user_wrap}
      onClick={() => setUserInfo(false)}
      scrollableNodeProps={{ ref: scrollRef }}>
      <div className={styles.detailes_user_content} onClick={(event) => event.stopPropagation()}>
        {authorMainInfo ? (
          <>
            <div className={styles.detailed_user_info}>
              {authorMainInfo?.authorInfo?.avatar && (
                <PreviewUserAvatar
                  customStyles={styles.detailed_user_avatar}
                  userValues={authorMainInfo?.authorInfo?.avatar}
                />
              )}
              <div className={styles.detailed_user_main}>
                <div className={styles.detailed_user_name}>
                  {authorMainInfo?.authorInfo?.firstName} {authorMainInfo?.authorInfo?.lastName}
                </div>
                <div className={styles.detailed_user_role}>{authorMainInfo?.authorInfo?.role}</div>
                <div className={styles.detailed_user_location}>
                  <Location />
                  {`${
                    authorMainInfo?.authorInfo?.location?.city
                      ? `${authorMainInfo?.authorInfo?.location?.country?.label}, 
                  ${authorMainInfo?.authorInfo?.location?.city?.label}`
                      : `${authorMainInfo?.authorInfo?.location?.country?.label}`
                  }`}
                </div>
              </div>
              <div className={styles.close_user_info} onClick={() => setUserInfo(false)}>
                <CloseIcon />
              </div>
            </div>
            <RecentStories
              getlocalizationInfo={getlocalizationInfo}
              moduleStories={authorMainInfo?.stories}
              nested={true}
              closeUserInfo={setUserInfo}
              handleAuthorStory={handleAuthorStory}
            />
          </>
        ) : (
          <>
            <EmptyCourseCard />
            <EmptyCourseCard />
          </>
        )}
      </div>
    </SimpleBar>
  );
};

export default UserInfo;
