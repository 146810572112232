import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const { REACT_APP_API_BASE_URL } = process.env;

interface getTranslationsInterface {
  [key: string]: any;
}

export const localizationSite = createApi({
  reducerPath: 'getTranslationsReducer',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { userToken } = (getState() as RootState).login;
      if (userToken) {
        headers.set('authorization', `Bearer ${userToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTranslations: builder.query<getTranslationsInterface, any>({
      query(acceptLanguage) {
        return {
          url: '/get-translations',
          method: 'GET',
          headers: { 'accept-Language': acceptLanguage },
        };
      },
      transformResponse: (response: any) => {
        return response.uiTranslations.reduce((acc, item: { key: string; value: string }) => {
          acc[item.key] = item.value;
          return acc;
        }, {});
      },
    }),
  }),
});

interface translateInterface {
  translate: object;
}

const userAccountChoice = { translate: {} };

const initialState: translateInterface = userAccountChoice;
export const translationsSite = createSlice({
  name: 'translateSite',
  initialState,
  reducers: {
    setTranslations: (state, action: PayloadAction<translateInterface>) => {
      state.translate = action.payload;
    },
  },
});
export const { setTranslations } = translationsSite.actions;
export default translationsSite.reducer;
