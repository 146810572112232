import { configureStore } from '@reduxjs/toolkit';
import modalReducer from 'features/modal/modalSlice';
import notificationModalReducer from 'features/notifications/notifications';
import loginReducer from 'features/login/loginSlice';
import setDataForgotPassSlice from 'features/forgotPass/forgotPass';
import joinClassSlice from 'features/joinClass/joinClass';
import studentRegistration from 'features/registration/studentRegistrationSlice';
import educatorRegistration from 'features/registration/educatorRegistrationSlice';
import translationsSite, { localizationSite } from 'features/localization/localizationSlice';
import userAccountSlice from 'features/userAccount/userAccountSlice';
import settingPage from 'features/userAccount/settingPage';
import { entryApi } from 'app/entryApi';
import userModuleStorySlice from 'features/moduleStory/moduleStory';
import redstoneUserTokenSlice, { redstoneToken } from 'features/firebaseTokenRedstone/firebaseTokenRedstoneSlice';
import currentModuleSlice from 'features/modules/modulesApi';
import customizeProfileModalSlice from 'features/customizeProfile/customizeProfile';

export const store = configureStore({
  reducer: {
    [entryApi.reducerPath]: entryApi.reducer,
    customizeProfileModal: customizeProfileModalSlice,
    redstoneToken: redstoneUserTokenSlice,
    userAccount: userAccountSlice,
    userPageSetting: settingPage,
    currentModule: currentModuleSlice,
    userModuleStory: userModuleStorySlice,
    modal: modalReducer,
    notificationModal: notificationModalReducer,
    login: loginReducer,
    forgotPassword: setDataForgotPassSlice,
    joinClass: joinClassSlice,
    student: studentRegistration,
    educator: educatorRegistration,
    localize: translationsSite,
    [localizationSite.reducerPath]: localizationSite.reducer,
    [redstoneToken.reducerPath]: redstoneToken.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      entryApi.middleware,
      localizationSite.middleware,
      redstoneToken.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
