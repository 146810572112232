import React from 'react';

const ClothesStyle7 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59364)">
        <path
          d="M146.771 127.226C146.814 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.942 128.387 157.609 129.611 158.748 131.209C159.888 132.809 160.512 134.804 160.927 137.523C160.936 137.58 160.891 137.631 160.836 137.631H139.17C139.114 137.631 139.07 137.58 139.079 137.523C139.527 134.667 140.265 132.649 141.474 131.07C142.682 129.492 144.376 128.334 146.771 127.226Z"
          fill="white"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.036 132.387L150.194 132.618L150.347 132.384L153.602 127.434C155.188 128.168 155.993 128.709 157.395 129.918V137.273H142.704V129.918C144.107 128.712 145.04 128.17 146.641 127.432L150.036 132.387Z"
          fill="#323232"
          stroke="#323232"
          strokeWidth="0.375062"
        />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle7;
