import React from 'react';

const ClothesStyle3 = () => {
  return (
    <>
      <path
        d="M126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1514 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032C126 236.821 126.666 236.989 126.999 237.566Z"
        fill="#ECFCCB"
        stroke="#4D7C0F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M210.211 244.043C194.646 280.701 129.873 281.203 114.308 241.532" stroke="#4D7C0F" strokeWidth="4" />
      <path
        d="M193.633 286.54H236.831V311.363C236.831 323.292 227.161 332.962 215.232 332.962C203.303 332.962 193.633 323.292 193.633 311.363V286.54Z"
        fill="#ECFCCB"
        stroke="#4D7C0F"
        strokeWidth="4"
      />
      <line x1="193.778" y1="298.632" x2="236.686" y2="298.632" stroke="#4D7C0F" strokeWidth="4" />
    </>
  );
};

export default ClothesStyle3;
