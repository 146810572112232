import React from 'react';
import SidebarPattern2 from 'components/CustomizePlatform/components/PreviewUserSite/patterns/sidebar_pattern/sidebar_pattern_2';
import SidebarPattern3 from 'components/CustomizePlatform/components/PreviewUserSite/patterns/sidebar_pattern/sidebar_pattern_3';
import SidebarPattern4 from 'components/CustomizePlatform/components/PreviewUserSite/patterns/sidebar_pattern/sidebar_pattern_4';
import SidebarPattern5 from 'components/CustomizePlatform/components/PreviewUserSite/patterns/sidebar_pattern/sidebar_pattern_5';
import SidebarPattern6 from 'components/CustomizePlatform/components/PreviewUserSite/patterns/sidebar_pattern/sidebar_pattern_6';

const renderPatterns = (userValues) => {
  switch (userValues) {
    case 'pattern_1':
      return <></>;
    case 'pattern_2':
      return <SidebarPattern2 />;
    case 'pattern_3':
      return <SidebarPattern3 />;
    case 'pattern_4':
      return <SidebarPattern4 />;
    case 'pattern_5':
      return <SidebarPattern5 />;
    case 'pattern_6':
      return <SidebarPattern6 />;
    default:
      return <></>;
  }
};

export default renderPatterns;
