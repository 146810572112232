const routes = {
  accountSettings: '/account-settings',
  courses: '/courses',
  myStories: '/my-stories',
  favorites: '/favorites',
  settings: '/settings',
  customization: '/customization',
  joinClass: '/join-the-class',
  registerEducator: '/register-as-educator',
  history: '/history',
  help: '/help',
  login: '/login',
  forgotPassword: '/forgot-password',
  studentRegistration: '/student-registration',
  educatorRegistration: '/educator-registration',
  loginAccess: '/registration-finish',
  messages: '/messages',
};

export default routes;
