import React from 'react';

const ClothesStyle14 = () => {
  return (
    <>
      <path
        d="M126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1514 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032C126 236.821 126.666 236.989 126.999 237.566Z"
        fill="white"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.2364 254.832C104.748 249.803 109.174 247.43 117.321 243.208V295.264H206.696V244.358C215.023 248.565 219.41 251.44 226.781 256.401V295.264H226.814C231.233 295.264 234.814 298.846 234.814 303.264V345.983H86.1899V303.264C86.1899 298.846 89.7717 295.264 94.1899 295.264H97.2364V254.832Z"
        fill="#0E7490"
      />
      <path
        d="M226.781 345.982V310.294C226.781 305.876 223.199 302.294 218.781 302.294H102.224C97.8054 302.294 94.2236 305.876 94.2236 310.294V345.982"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
      <line
        x1="103.766"
        y1="294.764"
        x2="103.766"
        y2="254.586"
        stroke="white"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
      <line
        x1="220.255"
        y1="294.764"
        x2="220.255"
        y2="254.586"
        stroke="white"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
      <ellipse cx="104.266" cy="301.29" rx="6.02532" ry="6.02597" fill="white" />
      <ellipse cx="218.747" cy="301.29" rx="6.02532" ry="6.02597" fill="white" />
      <path
        d="M122.342 327.355C122.342 321.28 127.267 316.355 133.342 316.355H186.658C192.733 316.355 197.658 321.28 197.658 327.355V346.485H122.342V327.355Z"
        fill="#164E63"
      />
    </>
  );
};

export default ClothesStyle14;
