import React from 'react';

const SidebarPattern2 = () => {
  return (
    <>
      <g clipPath="url(#clip3_8054_52955)">
        <g clipPath="url(#clip4_8054_52955)">
          <path
            d="M325.773 630.501L317.518 618.49C313.011 611.911 305.669 611.911 301.162 618.49C296.655 625.069 289.094 625.178 284.501 618.49C279.909 611.802 272.652 611.911 268.099 618.49C263.545 625.069 256.039 625.178 251.446 618.49C246.853 611.802 239.597 611.911 235.083 618.49C230.568 625.069 223.015 625.178 218.422 618.49C213.829 611.802 206.573 611.911 202.066 618.49C197.56 625.069 189.999 625.178 185.406 618.49C180.813 611.802 173.557 611.911 169.003 618.49C164.45 625.069 156.936 625.178 152.343 618.49C147.75 611.802 140.494 611.911 135.94 618.49C131.387 625.069 123.88 625.178 119.28 618.49C114.679 611.802 107.431 611.911 102.924 618.49C98.4174 625.069 90.8487 625.178 86.256 618.49L78 606.478L78.1484 605.223L86.4044 617.234C90.919 623.813 98.2612 623.813 102.768 617.234C107.275 610.655 114.836 610.546 119.436 617.234C124.037 623.922 131.285 623.813 135.792 617.234C140.299 610.655 147.86 610.546 152.452 617.234C157.045 623.922 164.309 623.813 168.855 617.234C173.401 610.655 180.923 610.546 185.515 617.234C190.108 623.922 197.364 623.813 201.871 617.234C206.378 610.655 213.939 610.546 218.532 617.234C223.124 623.922 230.38 623.813 234.895 617.234C239.41 610.655 246.963 610.546 251.555 617.234C256.148 623.922 263.404 623.813 267.911 617.234C272.418 610.655 279.979 610.546 284.572 617.234C289.164 623.922 296.421 623.813 300.974 617.234C305.528 610.655 313.034 610.546 317.627 617.234L325.883 629.246L325.773 630.501Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 607.597L317.518 595.558C313.011 589.007 305.669 589.007 301.162 595.558C296.655 602.11 289.094 602.247 284.501 595.558C279.909 588.87 272.652 589.007 268.099 595.558C263.545 602.11 256.039 602.247 251.446 595.558C246.853 588.87 239.597 589.007 235.083 595.558C230.568 602.11 223.015 602.247 218.422 595.558C213.829 588.87 206.573 589.007 202.066 595.558C197.56 602.11 189.999 602.247 185.406 595.558C180.813 588.87 173.557 589.007 169.003 595.558C164.45 602.11 156.936 602.247 152.343 595.558C147.75 588.87 140.494 589.007 135.94 595.558C131.387 602.11 123.88 602.247 119.28 595.558C114.679 588.87 107.431 589.007 102.924 595.558C98.4174 602.11 90.8487 602.247 86.256 595.558L78 583.547L78.1484 582.291L86.4044 594.303C90.919 600.882 98.2612 600.882 102.768 594.303C107.275 587.724 114.836 587.614 119.436 594.303C124.037 600.991 131.285 600.882 135.792 594.303C140.299 587.724 147.86 587.614 152.452 594.303C157.045 600.991 164.309 600.882 168.855 594.303C173.401 587.724 180.923 587.614 185.515 594.303C190.108 600.991 197.364 600.882 201.871 594.303C206.378 587.724 213.939 587.614 218.532 594.303C223.124 600.991 230.38 600.882 234.895 594.303C239.41 587.724 246.963 587.614 251.555 594.303C256.148 600.991 263.404 600.882 267.911 594.303C272.418 587.724 279.979 587.614 284.572 594.303C289.164 600.991 296.421 600.882 300.974 594.303C305.528 587.724 313.034 587.614 317.627 594.303L325.883 606.314L325.773 607.597Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 584.667L317.518 572.629C313.011 566.077 305.669 566.077 301.162 572.629C296.655 579.18 289.094 579.317 284.501 572.629C279.909 565.94 272.652 566.077 268.099 572.629C263.545 579.18 256.039 579.317 251.446 572.629C246.853 565.94 239.597 566.077 235.083 572.629C230.568 579.18 223.015 579.317 218.422 572.629C213.829 565.94 206.573 566.077 202.066 572.629C197.56 579.18 189.999 579.317 185.406 572.629C180.813 565.94 173.557 566.077 169.003 572.629C164.45 579.18 156.936 579.317 152.343 572.629C147.75 565.94 140.494 566.077 135.94 572.629C131.387 579.18 123.88 579.317 119.28 572.629C114.679 565.94 107.431 566.077 102.924 572.629C98.4174 579.18 90.8487 579.317 86.256 572.629L78 560.617L78.1484 559.361L86.4044 571.373C90.919 577.952 98.2612 577.952 102.768 571.373C107.275 564.794 114.836 564.685 119.436 571.373C124.037 578.061 131.285 577.952 135.792 571.373C140.299 564.794 147.86 564.685 152.452 571.373C157.045 578.061 164.309 577.952 168.855 571.373C173.401 564.794 180.923 564.685 185.515 571.373C190.108 578.061 197.364 577.952 201.871 571.373C206.378 564.794 213.939 564.685 218.532 571.373C223.124 578.061 230.38 577.952 234.895 571.373C239.41 564.794 246.963 564.685 251.555 571.373C256.148 578.061 263.404 577.952 267.911 571.373C272.418 564.794 279.979 564.685 284.572 571.373C289.164 578.061 296.421 577.952 300.974 571.373C305.528 564.794 313.034 564.685 317.627 571.373L325.883 583.384L325.773 584.667Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 561.736L317.518 549.697C313.011 543.145 305.669 543.145 301.162 549.697C296.655 556.249 289.094 556.385 284.501 549.697C279.909 543.009 272.652 543.145 268.099 549.697C263.545 556.249 256.039 556.385 251.446 549.697C246.853 543.009 239.597 543.145 235.083 549.697C230.568 556.249 223.015 556.385 218.422 549.697C213.829 543.009 206.573 543.145 202.066 549.697C197.56 556.249 189.999 556.385 185.406 549.697C180.813 543.009 173.557 543.145 169.003 549.697C164.45 556.249 156.936 556.385 152.343 549.697C147.75 543.009 140.494 543.145 135.94 549.697C131.387 556.249 123.873 556.385 119.28 549.697C114.687 543.009 107.431 543.145 102.924 549.697C98.4174 556.249 90.8487 556.385 86.256 549.697L78 537.685L78.1484 536.43L86.4044 548.441C90.919 555.02 98.2612 555.02 102.768 548.441C107.275 541.862 114.836 541.753 119.436 548.441C124.037 555.129 131.285 555.02 135.792 548.441C140.299 541.862 147.86 541.753 152.452 548.441C157.045 555.129 164.309 555.02 168.855 548.441C173.401 541.862 180.923 541.753 185.515 548.441C190.108 555.129 197.364 555.02 201.871 548.441C206.378 541.862 213.939 541.753 218.532 548.441C223.124 555.129 230.38 555.02 234.895 548.441C239.41 541.862 246.963 541.753 251.555 548.441C256.148 555.129 263.404 555.02 267.911 548.441C272.418 541.862 279.979 541.753 284.572 548.441C289.164 555.129 296.421 555.02 300.974 548.441C305.528 541.862 313.034 541.753 317.627 548.441L325.883 560.48L325.773 561.736Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 538.804L317.518 526.793C313.011 520.214 305.669 520.214 301.162 526.793C296.655 533.372 289.094 533.481 284.501 526.793C279.909 520.104 272.652 520.214 268.099 526.793C263.545 533.372 256.039 533.481 251.446 526.793C246.853 520.104 239.597 520.214 235.083 526.793C230.568 533.372 223.015 533.481 218.422 526.793C213.829 520.104 206.573 520.214 202.066 526.793C197.56 533.372 189.999 533.481 185.406 526.793C180.813 520.104 173.557 520.214 169.003 526.793C164.45 533.372 156.936 533.481 152.343 526.793C147.75 520.104 140.494 520.214 135.94 526.793C131.387 533.372 123.873 533.481 119.28 526.793C114.687 520.104 107.431 520.214 102.924 526.793C98.4174 533.372 90.8487 533.481 86.256 526.793L78 514.754L78.1484 513.498L86.4044 525.51C90.919 532.089 98.2612 532.089 102.768 525.51C107.275 518.931 114.836 518.821 119.436 525.51C124.037 532.198 131.285 532.089 135.792 525.51C140.299 518.931 147.86 518.821 152.452 525.51C157.045 532.198 164.309 532.089 168.855 525.51C173.401 518.931 180.923 518.821 185.515 525.51C190.108 532.198 197.364 532.089 201.871 525.51C206.378 518.931 213.939 518.821 218.532 525.51C223.124 532.198 230.38 532.089 234.895 525.51C239.41 518.931 246.963 518.821 251.555 525.51C256.148 532.198 263.404 532.089 267.911 525.51C272.418 518.931 279.979 518.821 284.572 525.51C289.164 532.198 296.421 532.089 300.974 525.51C305.528 518.931 313.034 518.821 317.627 525.51L325.883 537.548L325.773 538.804Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.774 515.873L317.518 503.861C313.011 497.282 305.669 497.282 301.162 503.861C296.655 510.44 289.094 510.549 284.501 503.861C279.909 497.173 272.652 497.282 268.099 503.861C263.545 510.44 256.039 510.549 251.446 503.861C246.853 497.173 239.597 497.282 235.083 503.861C230.568 510.44 223.015 510.549 218.422 503.861C213.829 497.173 206.573 497.282 202.066 503.861C197.56 510.44 189.999 510.549 185.406 503.861C180.813 497.173 173.557 497.282 169.003 503.861C164.45 510.44 156.936 510.549 152.343 503.861C147.75 497.173 140.494 497.282 135.94 503.861C131.387 510.44 123.873 510.549 119.28 503.861C114.687 497.173 107.431 497.282 102.924 503.861C98.4174 510.44 90.8487 510.549 86.256 503.861L78 491.822L78.1484 490.566L86.4044 502.578C90.919 509.157 98.2612 509.157 102.768 502.578C107.275 495.999 114.836 495.89 119.436 502.578C124.037 509.266 131.285 509.157 135.792 502.578C140.299 495.999 147.86 495.89 152.452 502.578C157.045 509.266 164.309 509.157 168.855 502.578C173.401 495.999 180.923 495.89 185.515 502.578C190.108 509.266 197.364 509.157 201.871 502.578C206.378 495.999 213.939 495.89 218.532 502.578C223.124 509.266 230.38 509.157 234.895 502.578C239.41 495.999 246.963 495.89 251.556 502.578C256.148 509.266 263.404 509.157 267.911 502.578C272.418 495.999 279.979 495.89 284.572 502.578C289.164 509.266 296.421 509.157 300.974 502.578C305.528 495.999 313.034 495.89 317.627 502.578L325.922 514.535L325.774 515.873Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 492.943L317.518 480.931C313.011 474.352 305.669 474.352 301.162 480.931C296.655 487.51 289.094 487.62 284.501 480.931C279.909 474.243 272.652 474.352 268.099 480.931C263.545 487.51 256.039 487.62 251.446 480.931C246.853 474.243 239.597 474.352 235.083 480.931C230.568 487.51 223.015 487.62 218.422 480.931C213.829 474.243 206.573 474.352 202.066 480.931C197.56 487.51 189.999 487.62 185.406 480.931C180.813 474.243 173.557 474.352 169.003 480.931C164.45 487.51 156.936 487.62 152.343 480.931C147.75 474.243 140.494 474.352 135.94 480.931C131.387 487.51 123.873 487.62 119.28 480.931C114.687 474.243 107.431 474.352 102.924 480.931C98.4174 487.51 90.8487 487.62 86.256 480.931L78 468.893L78.1484 467.637L86.4044 479.675C90.919 486.227 98.2612 486.227 102.768 479.675C107.275 473.124 114.836 472.987 119.436 479.675C124.037 486.364 131.285 486.227 135.792 479.675C140.299 473.124 147.86 472.987 152.452 479.675C157.045 486.364 164.309 486.227 168.855 479.675C173.401 473.124 180.923 472.987 185.515 479.675C190.108 486.364 197.364 486.227 201.871 479.675C206.378 473.124 213.939 472.987 218.532 479.675C223.124 486.364 230.38 486.227 234.895 479.675C239.41 473.124 246.963 472.987 251.555 479.675C256.148 486.364 263.404 486.227 267.911 479.675C272.418 473.124 279.979 472.987 284.572 479.675C289.164 486.364 296.421 486.227 300.974 479.675C305.528 473.124 313.034 472.987 317.627 479.675L325.883 491.687L325.773 492.943Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 470.011L317.518 458C313.011 451.421 305.669 451.421 301.162 458C296.655 464.579 289.094 464.688 284.501 458C279.909 451.311 272.652 451.421 268.099 458C263.545 464.579 256.039 464.688 251.446 458C246.853 451.311 239.597 451.421 235.083 458C230.568 464.579 223.015 464.688 218.422 458C213.829 451.311 206.573 451.421 202.066 458C197.56 464.579 189.999 464.688 185.406 458C180.813 451.311 173.557 451.421 169.003 458C164.45 464.579 156.936 464.688 152.343 458C147.75 451.311 140.494 451.421 135.94 458C131.387 464.579 123.873 464.688 119.28 458C114.687 451.311 107.431 451.421 102.924 458C98.4174 464.579 90.8487 464.688 86.256 458L78 445.961L78.1484 444.705L86.4044 456.744C90.919 463.296 98.2612 463.296 102.768 456.744C107.275 450.192 114.836 450.056 119.436 456.744C124.037 463.432 131.285 463.296 135.792 456.744C140.299 450.192 147.86 450.056 152.452 456.744C157.045 463.432 164.309 463.296 168.855 456.744C173.401 450.192 180.923 450.056 185.515 456.744C190.108 463.432 197.364 463.296 201.871 456.744C206.378 450.192 213.939 450.056 218.532 456.744C223.124 463.432 230.38 463.296 234.895 456.744C239.41 450.192 246.963 450.056 251.555 456.744C256.148 463.432 263.404 463.296 267.911 456.744C272.418 450.192 279.979 450.056 284.572 456.744C289.164 463.432 296.421 463.296 300.974 456.744C305.528 450.192 313.034 450.056 317.627 456.744L325.883 468.755L325.773 470.011Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 447.081L317.518 435.07C313.011 428.491 305.669 428.491 301.162 435.07C296.655 441.649 289.094 441.758 284.501 435.07C279.909 428.382 272.652 428.491 268.099 435.07C263.545 441.649 256.039 441.758 251.446 435.07C246.853 428.382 239.597 428.491 235.083 435.07C230.568 441.649 223.015 441.758 218.422 435.07C213.829 428.382 206.573 428.491 202.066 435.07C197.56 441.649 189.999 441.758 185.406 435.07C180.813 428.382 173.557 428.491 169.003 435.07C164.45 441.649 156.936 441.758 152.343 435.07C147.75 428.382 140.494 428.491 135.94 435.07C131.387 441.649 123.873 441.758 119.28 435.07C114.687 428.382 107.431 428.491 102.924 435.07C98.4174 441.649 90.8487 441.758 86.256 435.07L78 423.058L78.1484 421.775L86.4044 433.814C90.919 440.366 98.2612 440.366 102.768 433.814C107.275 427.262 114.836 427.126 119.436 433.814C124.037 440.502 131.285 440.366 135.792 433.814C140.299 427.262 147.86 427.126 152.452 433.814C157.045 440.502 164.309 440.366 168.855 433.814C173.401 427.262 180.923 427.126 185.515 433.814C190.108 440.502 197.364 440.366 201.871 433.814C206.378 427.262 213.939 427.126 218.532 433.814C223.124 440.502 230.38 440.366 234.895 433.814C239.41 427.262 246.963 427.126 251.555 433.814C256.148 440.502 263.404 440.366 267.911 433.814C272.418 427.262 279.979 427.126 284.572 433.814C289.164 440.502 296.421 440.366 300.974 433.814C305.528 427.262 313.034 427.126 317.627 433.814L325.883 445.826L325.773 447.081Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 424.15L317.518 412.138C313.011 405.559 305.669 405.559 301.162 412.138C296.655 418.717 289.094 418.827 284.501 412.138C279.909 405.45 272.652 405.559 268.099 412.138C263.545 418.717 256.039 418.827 251.446 412.138C246.853 405.45 239.597 405.559 235.083 412.138C230.568 418.717 223.015 418.827 218.422 412.138C213.829 405.45 206.573 405.559 202.066 412.138C197.56 418.717 189.999 418.827 185.406 412.138C180.813 405.45 173.557 405.559 169.003 412.138C164.45 418.717 156.936 418.827 152.343 412.138C147.75 405.45 140.494 405.559 135.94 412.138C131.387 418.717 123.88 418.827 119.28 412.138C114.679 405.45 107.431 405.559 102.924 412.138C98.4174 418.717 90.8487 418.827 86.256 412.138L78 400.127L78.1484 398.844L86.5215 410.801C91.0362 417.38 98.3783 417.38 102.885 410.801C107.392 404.222 114.953 404.112 119.553 410.801C124.154 417.489 131.402 417.38 135.909 410.801C140.416 404.222 147.977 404.112 152.569 410.801C157.162 417.489 164.426 417.352 168.972 410.801C173.518 404.249 181.04 404.112 185.632 410.801C190.225 417.489 197.481 417.38 201.988 410.801C206.495 404.222 214.056 404.112 218.649 410.801C223.241 417.489 230.498 417.352 235.012 410.801C239.527 404.249 247.08 404.112 251.673 410.801C256.265 417.489 263.522 417.38 268.028 410.801C272.535 404.222 280.096 404.112 284.689 410.801C289.282 417.489 296.538 417.38 301.091 410.801C305.645 404.222 313.151 404.112 317.744 410.801L326 422.812L325.773 424.15Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 401.216L317.518 389.205C313.011 382.626 305.669 382.626 301.162 389.205C296.655 395.784 289.094 395.893 284.501 389.205C279.909 382.517 272.652 382.626 268.099 389.205C263.545 395.784 256.039 395.893 251.446 389.205C246.853 382.517 239.597 382.653 235.083 389.205C230.568 395.757 223.015 395.893 218.422 389.205C213.829 382.517 206.573 382.626 202.066 389.205C197.56 395.784 189.999 395.893 185.406 389.205C180.813 382.517 173.557 382.653 169.003 389.205C164.45 395.757 156.936 395.893 152.343 389.205C147.75 382.517 140.494 382.653 135.94 389.205C131.387 395.757 123.88 395.893 119.28 389.205C114.679 382.517 107.431 382.653 102.924 389.205C98.4174 395.757 90.8487 395.893 86.256 389.205L78 377.193L78.1484 375.938L86.4044 387.949C90.919 394.528 98.2612 394.528 102.768 387.949C107.275 381.37 114.836 381.261 119.436 387.949C124.037 394.637 131.285 394.528 135.792 387.949C140.299 381.37 147.86 381.261 152.452 387.949C157.045 394.637 164.309 394.528 168.855 387.949C173.401 381.37 180.923 381.261 185.515 387.949C190.108 394.637 197.364 394.528 201.871 387.949C206.378 381.37 213.939 381.261 218.532 387.949C223.124 394.637 230.38 394.528 234.895 387.949C239.41 381.37 246.963 381.261 251.555 387.949C256.148 394.637 263.404 394.528 267.911 387.949C272.418 381.37 279.979 381.261 284.572 387.949C289.164 394.637 296.421 394.528 300.974 387.949C305.528 381.37 313.034 381.261 317.627 387.949L325.883 399.961L325.773 401.216Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 378.314L317.518 366.275C313.011 359.723 305.669 359.723 301.162 366.275C296.655 372.827 289.094 372.963 284.501 366.275C279.909 359.587 272.652 359.723 268.099 366.275C263.545 372.827 256.039 372.963 251.446 366.275C246.853 359.587 239.597 359.723 235.083 366.275C230.568 372.827 223.015 372.963 218.422 366.275C213.829 359.587 206.573 359.723 202.066 366.275C197.56 372.827 189.999 372.963 185.406 366.275C180.813 359.587 173.557 359.723 169.003 366.275C164.45 372.827 156.936 372.963 152.343 366.275C147.75 359.587 140.494 359.723 135.94 366.275C131.387 372.827 123.88 372.963 119.28 366.275C114.679 359.587 107.431 359.723 102.924 366.275C98.4174 372.827 90.8487 372.963 86.256 366.275L78 354.263L78.1484 353.008L86.4044 365.019C90.919 371.598 98.2612 371.598 102.768 365.019C107.275 358.44 114.836 358.331 119.436 365.019C124.037 371.707 131.285 371.598 135.792 365.019C140.299 358.44 147.86 358.331 152.452 365.019C157.045 371.707 164.309 371.598 168.855 365.019C173.401 358.44 180.923 358.331 185.515 365.019C190.108 371.707 197.364 371.598 201.871 365.019C206.378 358.44 213.939 358.331 218.532 365.019C223.124 371.707 230.38 371.598 234.895 365.019C239.41 358.44 246.963 358.331 251.555 365.019C256.148 371.707 263.404 371.598 267.911 365.019C272.418 358.44 279.979 358.331 284.572 365.019C289.164 371.707 296.421 371.598 300.974 365.019C305.528 358.44 313.034 358.331 317.627 365.019L325.883 377.031L325.773 378.314Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 355.384L317.518 343.345C313.011 336.794 305.669 336.794 301.162 343.345C296.655 349.897 289.094 350.034 284.501 343.345C279.909 336.657 272.652 336.794 268.099 343.345C263.545 349.897 256.039 350.034 251.446 343.345C246.853 336.657 239.597 336.794 235.083 343.345C230.568 349.897 223.015 350.034 218.422 343.345C213.829 336.657 206.573 336.794 202.066 343.345C197.56 349.897 189.999 350.034 185.406 343.345C180.813 336.657 173.557 336.794 169.003 343.345C164.45 349.897 156.936 350.034 152.343 343.345C147.75 336.657 140.494 336.794 135.94 343.345C131.387 349.897 123.88 350.034 119.28 343.345C114.679 336.657 107.431 336.794 102.924 343.345C98.4174 349.897 90.8487 350.034 86.256 343.345L78 331.334L78.1484 330.078L86.4044 342.09C90.919 348.669 98.2612 348.669 102.768 342.09C107.275 335.511 114.836 335.401 119.436 342.09C124.037 348.778 131.285 348.669 135.792 342.09C140.299 335.511 147.86 335.401 152.452 342.09C157.045 348.778 164.309 348.669 168.855 342.09C173.401 335.511 180.923 335.401 185.515 342.09C190.108 348.778 197.364 348.669 201.871 342.09C206.378 335.511 213.939 335.401 218.532 342.09C223.124 348.778 230.38 348.669 234.895 342.09C239.41 335.511 246.963 335.401 251.555 342.09C256.148 348.778 263.404 348.669 267.911 342.09C272.418 335.511 279.979 335.401 284.572 342.09C289.164 348.778 296.421 348.669 300.974 342.09C305.528 335.511 313.034 335.401 317.627 342.09L325.883 354.128L325.773 355.384Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 332.452L317.518 320.414C313.011 313.862 305.669 313.862 301.162 320.414C296.655 326.965 289.094 327.102 284.501 320.414C279.909 313.725 272.652 313.862 268.099 320.414C263.545 326.965 256.039 327.102 251.446 320.414C246.853 313.725 239.597 313.862 235.083 320.414C230.568 326.965 223.015 327.102 218.422 320.414C213.829 313.725 206.573 313.862 202.066 320.414C197.56 326.965 189.999 327.102 185.406 320.414C180.813 313.725 173.557 313.862 169.003 320.414C164.45 326.965 156.936 327.102 152.343 320.414C147.75 313.725 140.494 313.862 135.94 320.414C131.387 326.965 123.873 327.102 119.28 320.414C114.687 313.725 107.431 313.862 102.924 320.414C98.4174 326.965 90.8487 327.102 86.256 320.414L78 308.402L78.1484 307.146L86.4044 319.158C90.919 325.737 98.2612 325.737 102.768 319.158C107.275 312.579 114.836 312.47 119.436 319.158C124.037 325.846 131.285 325.737 135.792 319.158C140.299 312.579 147.86 312.47 152.452 319.158C157.045 325.846 164.309 325.737 168.855 319.158C173.401 312.579 180.923 312.47 185.515 319.158C190.108 325.846 197.364 325.737 201.871 319.158C206.378 312.579 213.939 312.47 218.532 319.158C223.124 325.846 230.38 325.737 234.895 319.158C239.41 312.579 246.963 312.47 251.555 319.158C256.148 325.846 263.404 325.737 267.911 319.158C272.418 312.579 279.979 312.47 284.572 319.158C289.164 325.846 296.421 325.737 300.974 319.158C305.528 312.579 313.034 312.47 317.627 319.158L325.883 331.197L325.773 332.452Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 309.521L317.518 297.509C313.011 290.93 305.669 290.93 301.162 297.509C296.655 304.088 289.094 304.198 284.501 297.509C279.909 290.821 272.652 290.93 268.099 297.509C263.545 304.088 256.039 304.198 251.446 297.509C246.853 290.821 239.597 290.93 235.083 297.509C230.568 304.088 223.015 304.198 218.422 297.509C213.829 290.821 206.573 290.93 202.066 297.509C197.56 304.088 189.999 304.17 185.406 297.509C180.813 290.848 173.557 290.93 169.003 297.509C164.45 304.088 156.936 304.198 152.343 297.509C147.75 290.821 140.494 290.93 135.94 297.509C131.387 304.088 123.873 304.198 119.28 297.509C114.687 290.821 107.431 290.93 102.924 297.509C98.4174 304.088 90.8487 304.198 86.256 297.509L78 285.471L78.1484 284.215L86.4044 296.226C90.919 302.805 98.2612 302.805 102.768 296.226C107.275 289.647 114.836 289.538 119.436 296.226C124.037 302.915 131.285 302.805 135.792 296.226C140.299 289.647 147.86 289.538 152.452 296.226C157.045 302.915 164.309 302.805 168.855 296.226C173.401 289.647 180.923 289.538 185.515 296.226C190.108 302.915 197.364 302.805 201.871 296.226C206.378 289.647 213.939 289.538 218.532 296.226C223.124 302.915 230.38 302.805 234.895 296.226C239.41 289.647 246.963 289.538 251.555 296.226C256.148 302.915 263.404 302.805 267.911 296.226C272.418 289.647 279.979 289.538 284.572 296.226C289.164 302.915 296.421 302.805 300.974 296.226C305.528 289.647 313.034 289.538 317.627 296.226L325.883 308.265L325.773 309.521Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.774 286.589L317.518 274.578C313.011 267.999 305.669 267.999 301.162 274.578C296.655 281.157 289.094 281.266 284.501 274.578C279.909 267.89 272.652 267.999 268.099 274.578C263.545 281.157 256.039 281.266 251.446 274.578C246.853 267.89 239.597 267.999 235.083 274.578C230.568 281.157 223.015 281.266 218.422 274.578C213.829 267.89 206.573 267.999 202.066 274.578C197.56 281.157 189.999 281.266 185.406 274.578C180.813 267.89 173.557 267.999 169.003 274.578C164.45 281.157 156.936 281.266 152.343 274.578C147.75 267.89 140.494 267.999 135.94 274.578C131.387 281.157 123.873 281.266 119.28 274.578C114.687 267.89 107.431 267.999 102.924 274.578C98.4174 281.157 90.8487 281.266 86.256 274.578L78 262.539L78.1484 261.283L86.4044 273.295C90.919 279.874 98.2612 279.874 102.768 273.295C107.275 266.716 114.836 266.607 119.436 273.295C124.037 279.983 131.285 279.874 135.792 273.295C140.299 266.716 147.86 266.607 152.452 273.295C157.045 279.983 164.309 279.874 168.855 273.295C173.401 266.716 180.923 266.607 185.515 273.295C190.108 279.983 197.364 279.874 201.871 273.295C206.378 266.716 213.939 266.607 218.532 273.295C223.124 279.983 230.38 279.874 234.895 273.295C239.41 266.716 246.963 266.607 251.556 273.295C256.148 279.983 263.404 279.874 267.911 273.295C272.418 266.716 279.979 266.607 284.572 273.295C289.164 279.983 296.421 279.874 300.974 273.295C305.528 266.716 313.034 266.607 317.627 273.295L325.922 285.224L325.774 286.589Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 263.658L317.518 251.646C313.011 245.067 305.669 245.067 301.162 251.646C296.655 258.225 289.094 258.334 284.501 251.646C279.909 244.958 272.652 245.067 268.099 251.646C263.545 258.225 256.039 258.334 251.446 251.646C246.853 244.958 239.597 245.067 235.083 251.646C230.568 258.225 223.015 258.334 218.422 251.646C213.829 244.958 206.573 245.067 202.066 251.646C197.56 258.225 189.999 258.334 185.406 251.646C180.813 244.958 173.557 245.067 169.003 251.646C164.45 258.225 156.936 258.334 152.343 251.646C147.75 244.958 140.494 245.067 135.94 251.646C131.387 258.225 123.873 258.334 119.28 251.646C114.687 244.958 107.431 245.067 102.924 251.646C98.4174 258.225 90.8487 258.334 86.256 251.646L78 239.607L78.1484 238.352L86.4044 250.39C90.919 256.942 98.2612 256.942 102.768 250.39C107.275 243.839 114.836 243.702 119.436 250.39C124.037 257.079 131.285 256.942 135.792 250.39C140.299 243.839 147.86 243.702 152.452 250.39C157.045 257.079 164.309 256.942 168.855 250.39C173.401 243.839 180.923 243.702 185.515 250.39C190.108 257.079 197.364 256.942 201.871 250.39C206.378 243.839 213.939 243.702 218.532 250.39C223.124 257.079 230.38 256.942 234.895 250.39C239.41 243.839 246.963 243.702 251.555 250.39C256.148 257.079 263.404 256.942 267.911 250.39C272.418 243.839 279.979 243.702 284.572 250.39C289.164 257.079 296.421 256.942 300.974 250.39C305.528 243.839 313.034 243.702 317.627 250.39L325.883 262.402L325.773 263.658Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 240.728L317.518 228.716C313.011 222.137 305.669 222.137 301.162 228.716C296.655 235.295 289.094 235.405 284.501 228.716C279.909 222.028 272.652 222.137 268.099 228.716C263.545 235.295 256.039 235.405 251.446 228.716C246.853 222.028 239.597 222.137 235.083 228.716C230.568 235.295 223.015 235.405 218.422 228.716C213.829 222.028 206.573 222.137 202.066 228.716C197.56 235.295 189.999 235.405 185.406 228.716C180.813 222.028 173.557 222.137 169.003 228.716C164.45 235.295 156.936 235.405 152.343 228.716C147.75 222.028 140.494 222.137 135.94 228.716C131.387 235.295 123.873 235.405 119.28 228.716C114.687 222.028 107.431 222.137 102.924 228.716C98.4174 235.295 90.8487 235.405 86.256 228.716L78 216.678L78.1484 215.422L86.4044 227.461C90.919 234.013 98.2612 234.013 102.768 227.461C107.275 220.909 114.836 220.773 119.436 227.461C124.037 234.149 131.285 234.013 135.792 227.461C140.299 220.909 147.86 220.773 152.452 227.461C157.045 234.149 164.309 234.013 168.855 227.461C173.401 220.909 180.923 220.773 185.515 227.461C190.108 234.149 197.364 234.013 201.871 227.461C206.378 220.909 213.939 220.773 218.532 227.461C223.124 234.149 230.38 234.013 234.895 227.461C239.41 220.909 246.963 220.773 251.555 227.461C256.148 234.149 263.404 234.013 267.911 227.461C272.418 220.909 279.979 220.773 284.572 227.461C289.164 234.149 296.421 234.013 300.974 227.461C305.528 220.909 313.034 220.773 317.627 227.461L325.883 239.472L325.773 240.728Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 217.796L317.518 205.785C313.011 199.206 305.669 199.206 301.162 205.785C296.655 212.364 289.094 212.473 284.501 205.785C279.909 199.097 272.652 199.206 268.099 205.785C263.545 212.364 256.039 212.473 251.446 205.785C246.853 199.097 239.597 199.206 235.083 205.785C230.568 212.364 223.015 212.473 218.422 205.785C213.829 199.097 206.573 199.206 202.066 205.785C197.56 212.364 189.999 212.473 185.406 205.785C180.813 199.097 173.557 199.206 169.003 205.785C164.45 212.364 156.936 212.473 152.343 205.785C147.75 199.097 140.494 199.206 135.94 205.785C131.387 212.364 123.873 212.473 119.28 205.785C114.687 199.097 107.431 199.206 102.924 205.785C98.4174 212.364 90.8487 212.473 86.256 205.785L78 193.773L78.1484 192.49L86.4044 204.529C90.919 211.081 98.2612 211.081 102.768 204.529C107.275 197.977 114.836 197.841 119.436 204.529C124.037 211.217 131.285 211.081 135.792 204.529C140.299 197.977 147.86 197.841 152.452 204.529C157.045 211.217 164.309 211.081 168.855 204.529C173.401 197.977 180.923 197.841 185.515 204.529C190.108 211.217 197.364 211.081 201.871 204.529C206.378 197.977 213.939 197.841 218.532 204.529C223.124 211.217 230.38 211.081 234.895 204.529C239.41 197.977 246.963 197.841 251.555 204.529C256.148 211.217 263.404 211.081 267.911 204.529C272.418 197.977 279.979 197.841 284.572 204.529C289.164 211.217 296.421 211.081 300.974 204.529C305.528 197.977 313.034 197.841 317.627 204.529L325.883 216.541L325.773 217.796Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 194.865L317.518 182.853C313.011 176.274 305.669 176.274 301.162 182.853C296.655 189.432 289.094 189.541 284.501 182.853C279.909 176.165 272.652 176.274 268.099 182.853C263.545 189.432 256.039 189.541 251.446 182.853C246.853 176.165 239.597 176.274 235.083 182.853C230.568 189.432 223.015 189.541 218.422 182.853C213.829 176.165 206.573 176.274 202.066 182.853C197.56 189.432 189.999 189.541 185.406 182.853C180.813 176.165 173.557 176.274 169.003 182.853C164.45 189.432 156.936 189.541 152.343 182.853C147.75 176.165 140.494 176.274 135.94 182.853C131.387 189.432 123.88 189.541 119.28 182.853C114.679 176.165 107.431 176.274 102.924 182.853C98.4174 189.432 90.8487 189.541 86.256 182.853L78 170.842L78.1484 169.559L86.4044 181.597C90.919 188.176 98.2612 188.176 102.768 181.597C107.275 175.018 114.836 174.909 119.436 181.597C124.037 188.286 131.285 188.176 135.792 181.597C140.299 175.018 147.86 174.909 152.452 181.597C157.045 188.286 164.309 188.149 168.855 181.597C173.401 175.046 180.923 174.909 185.515 181.597C190.108 188.286 197.364 188.176 201.871 181.597C206.378 175.018 213.939 174.909 218.532 181.597C223.124 188.286 230.38 188.176 234.895 181.597C239.41 175.018 246.963 174.909 251.555 181.597C256.148 188.286 263.404 188.176 267.911 181.597C272.418 175.018 279.979 174.909 284.572 181.597C289.164 188.286 296.421 188.176 300.974 181.597C305.528 175.018 313.034 174.909 317.627 181.597L325.883 193.609L325.773 194.865Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 171.96L317.518 159.922C313.011 153.342 305.669 153.342 301.162 159.922C296.655 166.501 289.094 166.61 284.501 159.922C279.909 153.233 272.652 153.342 268.099 159.922C263.545 166.501 256.039 166.61 251.446 159.922C246.853 153.233 239.597 153.37 235.083 159.922C230.568 166.473 223.015 166.61 218.422 159.922C213.829 153.233 206.573 153.342 202.066 159.922C197.56 166.501 189.999 166.61 185.406 159.922C180.813 153.233 173.557 153.37 169.003 159.922C164.45 166.473 156.936 166.61 152.343 159.922C147.75 153.233 140.494 153.37 135.94 159.922C131.387 166.473 123.88 166.61 119.28 159.922C114.679 153.233 107.431 153.342 102.924 159.922C98.4174 166.501 90.8487 166.61 86.256 159.922L78 147.91L78.1484 146.654L86.4044 158.666C90.919 165.245 98.2612 165.245 102.768 158.666C107.275 152.087 114.836 151.978 119.436 158.666C124.037 165.354 131.285 165.245 135.792 158.666C140.299 152.087 147.86 151.978 152.452 158.666C157.045 165.354 164.309 165.245 168.855 158.666C173.401 152.087 180.923 151.978 185.515 158.666C190.108 165.354 197.364 165.245 201.871 158.666C206.378 152.087 213.939 151.978 218.532 158.666C223.124 165.354 230.38 165.245 234.895 158.666C239.41 152.087 246.963 151.978 251.555 158.666C256.148 165.354 263.404 165.245 267.911 158.666C272.418 152.087 279.979 151.978 284.572 158.666C289.164 165.354 296.421 165.245 300.974 158.666C305.528 152.087 313.034 151.978 317.627 158.666L325.883 170.677L325.773 171.96Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 149.031L317.518 136.992C313.011 130.44 305.669 130.44 301.162 136.992C296.655 143.544 289.094 143.68 284.501 136.992C279.909 130.304 272.652 130.44 268.099 136.992C263.545 143.544 256.039 143.68 251.446 136.992C246.853 130.304 239.597 130.44 235.083 136.992C230.568 143.544 223.015 143.68 218.422 136.992C213.829 130.304 206.573 130.44 202.066 136.992C197.56 143.544 189.999 143.68 185.406 136.992C180.813 130.304 173.557 130.44 169.003 136.992C164.45 143.544 156.936 143.68 152.343 136.992C147.75 130.304 140.494 130.44 135.94 136.992C131.387 143.544 123.88 143.68 119.28 136.992C114.679 130.304 107.431 130.44 102.924 136.992C98.4174 143.544 90.8487 143.68 86.256 136.992L78 124.981L78.1484 123.725L86.4044 135.736C90.919 142.315 98.2612 142.315 102.768 135.736C107.275 129.157 114.836 129.048 119.436 135.736C124.037 142.424 131.285 142.315 135.792 135.736C140.299 129.157 147.86 129.048 152.452 135.736C157.045 142.424 164.309 142.315 168.855 135.736C173.401 129.157 180.923 129.048 185.515 135.736C190.108 142.424 197.364 142.315 201.871 135.736C206.378 129.157 213.939 129.048 218.532 135.736C223.124 142.424 230.38 142.315 234.895 135.736C239.41 129.157 246.963 129.048 251.555 135.736C256.148 142.424 263.404 142.315 267.911 135.736C272.418 129.157 279.979 129.048 284.572 135.736C289.164 142.424 296.421 142.315 300.974 135.736C305.528 129.157 313.034 129.048 317.627 135.736L325.883 147.748L325.773 149.031Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 126.099L317.518 114.061C313.011 107.509 305.669 107.509 301.162 114.061C296.655 120.612 289.094 120.749 284.501 114.061C279.909 107.372 272.652 107.509 268.099 114.061C263.545 120.612 256.039 120.749 251.446 114.061C246.853 107.372 239.597 107.509 235.083 114.061C230.568 120.612 223.015 120.749 218.422 114.061C213.829 107.372 206.573 107.509 202.066 114.061C197.56 120.612 189.999 120.749 185.406 114.061C180.813 107.372 173.557 107.509 169.003 114.061C164.45 120.612 156.936 120.749 152.343 114.061C147.75 107.372 140.494 107.509 135.94 114.061C131.387 120.612 123.88 120.749 119.28 114.061C114.679 107.372 107.431 107.509 102.924 114.061C98.4174 120.612 90.8487 120.749 86.256 114.061L78 102.049L78.1484 100.793L86.4044 112.805C90.919 119.384 98.2612 119.384 102.768 112.805C107.275 106.226 114.836 106.116 119.436 112.805C124.037 119.493 131.285 119.384 135.792 112.805C140.299 106.226 147.86 106.116 152.452 112.805C157.045 119.493 164.309 119.384 168.855 112.805C173.401 106.226 180.923 106.116 185.515 112.805C190.108 119.493 197.364 119.384 201.871 112.805C206.378 106.226 213.939 106.116 218.532 112.805C223.124 119.493 230.38 119.384 234.895 112.805C239.41 106.226 246.963 106.116 251.555 112.805C256.148 119.493 263.404 119.384 267.911 112.805C272.418 106.226 279.979 106.116 284.572 112.805C289.164 119.493 296.421 119.384 300.974 112.805C305.528 106.226 313.034 106.116 317.627 112.805L325.883 124.843L325.773 126.099Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 103.169L317.518 91.1305C313.011 84.5788 305.669 84.5788 301.162 91.1305C296.655 97.6823 289.094 97.8188 284.501 91.1305C279.909 84.4423 272.652 84.5788 268.099 91.1305C263.545 97.6823 256.039 97.8461 251.446 91.1305C246.853 84.415 239.597 84.5788 235.083 91.1305C230.568 97.6823 223.015 97.8461 218.422 91.1305C213.829 84.415 206.573 84.5788 202.066 91.1305C197.56 97.6823 189.999 97.8188 185.406 91.1305C180.813 84.4423 173.557 84.5788 169.003 91.1305C164.45 97.6823 156.936 97.8461 152.343 91.1305C147.75 84.415 140.494 84.5788 135.94 91.1305C131.387 97.6823 123.873 97.8461 119.28 91.1305C114.687 84.415 107.431 84.5788 102.924 91.1305C98.4174 97.6823 90.8487 97.8188 86.256 91.1305L78 79.1189L78.1484 77.8633L86.4044 89.8745C90.919 96.4536 98.2612 96.4536 102.768 89.8745C107.275 83.2955 114.836 83.1863 119.436 89.8745C124.037 96.5628 131.285 96.4536 135.792 89.8745C140.299 83.2955 147.86 83.1863 152.452 89.8745C157.045 96.5628 164.309 96.4536 168.855 89.8745C173.401 83.2955 180.923 83.1863 185.515 89.8745C190.108 96.5628 197.364 96.4536 201.871 89.8745C206.378 83.2955 213.939 83.1863 218.532 89.8745C223.124 96.5628 230.38 96.4536 234.895 89.8745C239.41 83.2955 246.963 83.1863 251.555 89.8745C256.148 96.5628 263.404 96.4536 267.911 89.8745C272.418 83.2955 279.979 83.1863 284.572 89.8745C289.164 96.5628 296.421 96.4536 300.974 89.8745C305.528 83.2955 313.034 83.1863 317.627 89.8745L325.883 101.913L325.773 103.169Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
          <path
            d="M325.773 80.2358L317.518 68.2243C313.011 61.6452 305.669 61.6452 301.162 68.2243C296.655 74.8033 289.094 74.9125 284.501 68.2243C279.909 61.536 272.652 61.6452 268.099 68.2243C263.545 74.8033 256.039 74.9125 251.446 68.2243C246.853 61.536 239.597 61.6452 235.083 68.2243C230.568 74.8033 223.015 74.9125 218.422 68.2243C213.829 61.536 206.573 61.6452 202.066 68.2243C197.56 74.8033 189.999 74.9125 185.406 68.2243C180.813 61.536 173.557 61.6452 169.003 68.2243C164.45 74.8033 156.936 74.9125 152.343 68.2243C147.75 61.536 140.494 61.6452 135.94 68.2243C131.387 74.8033 123.873 74.9125 119.28 68.2243C114.687 61.536 107.431 61.6452 102.924 68.2243C98.4174 74.8033 90.8487 74.9125 86.256 68.2243L78 56.1853L78.1484 54.9297L86.4044 66.9413C90.919 73.5203 98.2612 73.5203 102.768 66.9413C107.275 60.3622 114.836 60.253 119.436 66.9413C124.037 73.6295 131.285 73.5203 135.792 66.9413C140.299 60.3622 147.86 60.253 152.452 66.9413C157.045 73.6295 164.309 73.5203 168.855 66.9413C173.401 60.3622 180.923 60.253 185.515 66.9413C190.108 73.6295 197.364 73.5203 201.871 66.9413C206.378 60.3622 213.939 60.253 218.532 66.9413C223.124 73.6295 230.38 73.5203 234.895 66.9413C239.41 60.3622 246.963 60.253 251.555 66.9413C256.148 73.6295 263.404 73.5203 267.911 66.9413C272.418 60.3622 279.979 60.253 284.572 66.9413C289.164 73.6295 296.421 73.5203 300.974 66.9413C305.528 60.3622 313.034 60.253 317.627 66.9413L325.883 78.9799L325.773 80.2358Z"
            fill="#4F88B3"
            fillOpacity="0.1"
          />
        </g>
      </g>
    </>
  );
};

export default SidebarPattern2;
