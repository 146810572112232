import React from 'react';

const ClothesStyle2 = () => {
  return (
    <>
      <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_8134_58325)">
        <path
          d="M146.693 127.056C146.831 126.992 146.995 127.051 147.07 127.182C148.52 129.696 152.01 129.478 153.088 127.219C153.158 127.072 153.332 126.998 153.48 127.065C158.593 129.382 160.278 132.032 161.113 137.495C161.138 137.664 161.007 137.818 160.836 137.818H139.17C138.998 137.818 138.867 137.664 138.893 137.494C139.796 131.749 141.875 129.285 146.693 127.056Z"
          fill="#BAE6FD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.614 126.885C146.847 126.778 147.112 126.879 147.233 127.088C148.613 129.481 151.911 129.251 152.918 127.138C153.027 126.91 153.306 126.78 153.558 126.894C156.131 128.06 157.863 129.32 159.054 130.991C160.243 132.661 160.879 134.723 161.298 137.467C161.341 137.749 161.123 138.006 160.836 138.006H139.17C138.882 138.006 138.664 137.747 138.708 137.465C139.162 134.575 139.915 132.489 141.176 130.842C142.437 129.196 144.192 128.006 146.614 126.885ZM146.908 127.276C146.877 127.222 146.814 127.206 146.772 127.226C146.446 127.376 146.133 127.528 145.833 127.681C146.55 129.387 148.344 130.198 150.178 130.168C151.983 130.138 153.754 129.292 154.494 127.762C154.149 127.584 153.786 127.409 153.403 127.236C153.358 127.215 153.288 127.235 153.257 127.3C152.11 129.705 148.428 129.911 146.908 127.276ZM154.825 127.938C154.003 129.625 152.076 130.511 150.184 130.543C148.262 130.575 146.301 129.726 145.5 127.855C143.759 128.782 142.456 129.787 141.474 131.07C140.265 132.649 139.527 134.667 139.079 137.523C139.07 137.58 139.114 137.631 139.17 137.631H160.836C160.892 137.631 160.936 137.58 160.927 137.523C160.512 134.803 159.888 132.809 158.748 131.209C157.837 129.93 156.588 128.891 154.825 127.938Z"
          fill="#323232"
        />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle2;
