import React from 'react';

const HairStyle17 = ({ hairColor }) => {
  return (
    <>
      <path
        d="M143.813 116.721V112.689C143.813 110.204 145.829 108.188 148.314 108.188H151.927C154.393 108.188 156.4 110.173 156.427 112.639L156.472 116.669C156.134 115.495 155.789 114.741 155.071 114.058C154.399 113.419 153.365 113.538 152.572 114.021C150.774 115.115 149.398 115.184 147.622 114.134C146.747 113.617 145.587 113.518 144.968 114.324C144.468 114.976 144.179 115.702 143.813 116.721Z"
        fill={hairColor}
      />
      <g filter="url(#filter0_d_8170_60237)">
        <path
          d="M143.57 111.206C143.909 110.404 144.361 109.622 145.005 109.002C145.649 108.382 146.419 107.886 147.271 107.543C148.122 107.2 149.039 107.017 149.968 107.003C150.897 106.989 151.821 107.145 152.686 107.463C153.551 107.781 154.342 108.253 155.011 108.854C155.681 109.454 156.217 110.171 156.59 110.963C156.962 111.755 157.163 112.607 157.181 113.47C157.199 114.333 156.755 117.202 156.416 118.004C153.697 115.379 149.49 112.485 143.57 111.206Z"
          fill="#323232"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.519 110.959C156.55 111.026 156.58 111.092 156.609 111.159C156.662 111.284 156.711 111.41 156.756 111.536L149.544 106.984C149.738 106.973 149.933 106.969 150.128 106.973C150.154 106.974 150.181 106.974 150.207 106.975L156.519 110.959ZM148.134 107.199L157.066 112.837C157.085 112.988 157.098 113.139 157.106 113.29L147.668 107.333C147.822 107.283 147.977 107.239 148.134 107.199ZM146.334 107.915L157.006 114.709C156.989 114.827 156.969 114.949 156.948 115.074L145.99 108.122C146.103 108.05 146.217 107.981 146.334 107.915ZM145.158 108.747L156.758 116.104C156.731 116.237 156.702 116.37 156.674 116.5L144.885 109.003C144.954 108.933 145.026 108.865 145.099 108.799C145.118 108.782 145.138 108.765 145.158 108.747Z"
        fill="white"
        fillOpacity="0.19"
      />
      <g filter="url(#filter1_d_8170_60237)">
        <path
          d="M156.685 111.164C156.346 110.361 155.941 109.532 155.297 108.912C154.652 108.292 153.883 107.796 153.031 107.453C152.18 107.11 151.263 106.927 150.334 106.913C149.405 106.899 148.481 107.056 147.616 107.373C146.75 107.691 145.96 108.163 145.29 108.764C144.621 109.364 144.084 110.081 143.712 110.873C143.34 111.665 143.139 112.517 143.121 113.38C143.103 114.243 143.547 117.112 143.886 117.915C146.605 115.289 150.765 112.442 156.685 111.164Z"
          fill="#323232"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.732 110.829C143.725 110.844 143.718 110.858 143.711 110.873C143.633 111.041 143.561 111.211 143.498 111.384L151.045 106.957C150.833 106.934 150.62 106.92 150.406 106.914L143.732 110.829ZM152.412 107.236L143.179 112.652C143.158 112.799 143.143 112.948 143.133 113.097L152.863 107.388C152.715 107.333 152.564 107.282 152.412 107.236ZM154.154 108.023L143.204 114.506C143.218 114.623 143.233 114.745 143.25 114.87L154.486 108.243C154.377 108.167 154.267 108.094 154.154 108.023ZM155.284 108.9L143.408 115.901C143.431 116.035 143.455 116.168 143.479 116.299L155.542 109.166C155.463 109.079 155.381 108.994 155.296 108.912C155.292 108.908 155.288 108.904 155.284 108.9Z"
        fill="white"
        fillOpacity="0.19"
      />

      <defs>
        <filter
          id="filter0_d_8170_60237"
          x="143.195"
          y="107.002"
          width="14.3615"
          height="11.7526"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.375062" />
          <feGaussianBlur stdDeviation="0.187531" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0336111 0 0 0 0 0.0811904 0 0 0 0 0.366667 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8170_60237" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8170_60237" result="shape" />
        </filter>
        <filter
          id="filter1_d_8170_60237"
          x="142.745"
          y="106.912"
          width="14.3146"
          height="11.7526"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.375062" />
          <feGaussianBlur stdDeviation="0.187531" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0336111 0 0 0 0 0.0811904 0 0 0 0 0.366667 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8170_60237" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8170_60237" result="shape" />
        </filter>
      </defs>
    </>
  );
};

export default HairStyle17;
