import React from 'react';

const ClothesStyle16 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59521)">
        <path
          d="M146.852 128.356C146.883 128.344 146.927 128.347 146.967 128.376C147.73 128.933 148.998 129.155 150.19 129.138C150.791 129.129 151.385 129.06 151.902 128.935C152.416 128.812 152.87 128.632 153.18 128.391C153.228 128.353 153.28 128.349 153.312 128.362L153.382 128.188L153.312 128.362C155.886 129.391 157.573 130.482 158.724 131.905C159.874 133.326 160.503 135.098 160.921 137.523C160.931 137.579 160.887 137.631 160.831 137.631H139.176C139.119 137.631 139.076 137.579 139.085 137.523C139.538 134.979 140.281 133.186 141.499 131.784C142.719 130.38 144.429 129.348 146.852 128.356Z"
          fill="white"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.707 126.941L150.003 128.495L153.44 126.941V128.495L150.709 129.73V137.818H149.296V129.716L146.707 128.495V126.941Z"
          fill="#323232"
        />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle16;
