import React, { MouseEventHandler } from 'react';
import styles from './PageHeader.module.scss';
import BackButton from './BackButton/BackButton';
import SkeletonItem from 'components/common/SkeletonComponents/SkeletonItem';
import classNames from 'classnames/bind';
import { classNamesInterface } from 'types/types';

interface pageHeaderInterface extends classNamesInterface {
  title?: string;
  children?: React.ReactNode;
  backLink?: string | MouseEventHandler;
  isLoading?: boolean;
  loadTranslations?: boolean;
}

const PageHeader = ({ title, children, backLink, isLoading, loadTranslations, className }: pageHeaderInterface) => {
  const cn = classNames.bind(styles);
  const headerClasses = cn({
    header: true,
    [className]: className,
  });
  const titleClasses = cn({
    title: true,
    page_header_title: className,
  });

  return isLoading ? (
    <header className={headerClasses}>
      <div className={styles.title}>
        <SkeletonItem width={200} height={50} />
      </div>
    </header>
  ) : (
    <header className={headerClasses}>
      <BackButton navigate={backLink} />
      {loadTranslations ? <SkeletonItem width={250} height={30} /> : <h1 className={titleClasses}>{title}</h1>}
      {children}
    </header>
  );
};

export default PageHeader;
