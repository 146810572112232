import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Modal.module.scss';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import routes from 'features/routes/routes';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(modalClose());
  };

  return (
    <div className={styles.forgot_password_wrap}>
      <div className={styles.modal_title}>Your educator will be notified and will provide you a new pass</div>
      <div className={styles.modal_btns}>
        <Link className={'button button_fill'} onClick={closeModal} to={routes.login}>
          <div className={styles.title}>Back</div>
        </Link>
        <Link className={'button button_border'} onClick={closeModal} to={routes.forgotPassword}>
          <div className={styles.title}>Continue</div>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
