import React from 'react';

const AccessoriesStyle9 = () => {
  return (
    <>
      <path
        d="M150.415 129.8C159.357 126.113 164.218 126.021 172.584 129.8"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.076 103.05L131.646 106.619L102.088 136.173L100.693 134.778C100.533 134.139 100.395 133.488 100.283 132.828C99.9505 130.874 99.8519 128.934 99.967 127.035L123.985 103.021C125.365 102.922 126.732 102.934 128.076 103.05Z"
        fill="white"
        fillOpacity="0.15"
      />
      <path
        d="M149.199 124.508C151.376 137.301 142.658 149.492 129.675 151.701C116.691 153.909 104.432 145.286 102.255 132.493C100.078 119.7 108.796 107.508 121.779 105.3C134.763 103.091 147.022 111.714 149.199 124.508Z"
        fill="url(#paint0_linear_4655_50043)"
        fillOpacity="0.3"
        stroke="#323232"
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.995 130.497C223.165 128.429 223.081 126.309 222.717 124.172C222.635 123.689 222.54 123.212 222.432 122.741L220.931 121.243L189.719 152.405L190.488 153.172C193.36 153.939 196.407 154.221 199.515 153.938L222.995 130.497Z"
        fill="white"
        fillOpacity="0.15"
      />
      <path
        d="M220.745 124.508C222.922 137.301 214.204 149.492 201.22 151.701C188.237 153.909 175.977 145.286 173.801 132.493C171.624 119.7 180.342 107.508 193.325 105.3C206.309 103.091 218.568 111.714 220.745 124.508Z"
        fill="url(#paint1_linear_4655_50043)"
        fillOpacity="0.3"
        stroke="#323232"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4655_50043"
          x1="121.444"
          y1="103.328"
          x2="145.329"
          y2="152.376"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8F6FF" />
          <stop offset="1" stopColor="#C0C8FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4655_50043"
          x1="192.99"
          y1="103.328"
          x2="216.875"
          y2="152.376"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8F6FF" />
          <stop offset="1" stopColor="#C0C8FE" />
        </linearGradient>
      </defs>
    </>
  );
};

export default AccessoriesStyle9;
