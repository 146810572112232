import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styles from './BackButton.module.scss';
import { ReactComponent as DesktopIcon } from 'assets/icons/back.svg';
import { ReactComponent as MobIcon } from 'assets/icons/back-mob.svg';
import { useViewportCheck } from 'app/hooks/useViewportCheck';
import useLocalization from 'app/hooks/useLocalization';

const BackButton = ({ navigate }: { navigate: string | MouseEventHandler }) => {
  const { toLocalize, isLoading } = useLocalization();
  const isMobileView = useViewportCheck(1099);
  const text = !isMobileView && !isLoading && toLocalize('back');
  const Icon = isMobileView ? <MobIcon className={styles.icon} /> : <DesktopIcon className={styles.icon} />;

  if (!navigate) return null;

  return typeof navigate === 'function' ? (
    <button onClick={navigate} className={styles.back}>
      {Icon}
      {text}
    </button>
  ) : (
    <Link to={navigate} className={styles.back}>
      {Icon}
      {text}
    </Link>
  );
};

export default BackButton;
