import React, { useEffect, useState } from 'react';
import styles from './RandomStory.module.scss';
import { ReactComponent as DefaultIcon } from 'assets/icons/RandomStory.svg';
import { ReactComponent as ModalIcon } from 'assets/icons/RandomStoryModal.svg';
import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import constants from 'features/constants';
import { modalOpen } from 'features/modal/modalSlice';
import { storiesApi } from 'features/moduleStory/moduleStory';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';
import { useViewportCheck } from 'app/hooks/useViewportCheck';
import classNames from 'classnames/bind';

export interface randomStoryInterface {
  nested?: boolean;
  getlocalizationInfo?: any;
  handleRandomStory?: any;
  closeUserInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  variant?: any;
}

const RandomStory = ({
  nested,
  getlocalizationInfo,
  handleRandomStory,
  closeUserInfo,
  variant,
}: randomStoryInterface) => {
  const dispatch = useAppDispatch();
  const setAnalyticsLogger = useAnalyticsLogger();
  // const isMobile = useViewportCheck('mobile');
  const isResponsiveViewport = useViewportCheck(1099);
  const { module_id: moduleId } = useAppSelector((state) => state.currentModule);
  const { data: moduleStoriesData }: any = storiesApi.useGetModuleStoriesQuery({ moduleId });

  const cn = classNames.bind(styles);
  const randomStoryClasses = cn({
    random_story_btn: true,
    mobile: isResponsiveViewport,
    small: variant === 'square',
  });

  const Icon = variant === 'square' ? <ModalIcon /> : <DefaultIcon />;

  const [randomStoryId, setRandomStoryId] = useState(
    moduleStoriesData?.stories[Math.floor(Math.random() * moduleStoriesData?.stories?.length)]?.id
  );

  const openStoryModal = () => {
    if (nested) {
      closeUserInfo(false);
    }
    dispatch(
      modalOpen({
        modalType: constants.SINGLE_STORY,
        modalData: randomStoryId,
        modalSize: 'xl',
      })
    );
    setRandomStoryId(moduleStoriesData?.stories[Math.floor(Math.random() * moduleStoriesData?.stories?.length)]?.id);
  };

  useEffect(() => {
    setRandomStoryId(moduleStoriesData?.stories[Math.floor(Math.random() * moduleStoriesData?.stories?.length)]?.id);
  }, [moduleStoriesData]);

  const handleAnalytics = () => setAnalyticsLogger('story_view', { referrer: 'random', story_ID: randomStoryId });

  return (
    <button
      type="button"
      className={randomStoryClasses}
      onClick={() => {
        handleRandomStory ? handleRandomStory() : openStoryModal();
        handleAnalytics();
      }}>
      {Icon}
      <span>{getlocalizationInfo?.random_story}</span>
    </button>
  );
};

export default RandomStory;
