import { entryApi } from 'app/entryApi';

interface getUserInfoInterface {
  [key: string]: any;
}

interface updateUserInfoDataInterface {
  userData: {
    account_customization_setting: string;
    first_name: string;
    last_name?: string;
    email?: string;
    age?: string;
    username?: string;
    gender: string;
    location: object;
  };
}

export const userInfo = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfoRequest: builder.query<getUserInfoInterface, string>({
      query() {
        return {
          url: '/me',
          method: 'GET',
        };
      },
      providesTags: () => ['GET_ME'],
    }),
    updateUserInfoData: builder.mutation<object, updateUserInfoDataInterface>({
      query(data) {
        return {
          url: '/me',
          method: 'POST',
          body: data.userData,
        };
      },
      invalidatesTags: ['GET_ME', 'GET_ALL_COURSES'],
    }),
  }),
});

export const { useUpdateUserInfoDataMutation } = userInfo;
