const constants = {
  MODAL_REGISTRATION: 'MODAL_REGISTRATION',
  MODAL_FORGOT_PASSWORD: 'MODAL_FORGOT_PASSWORD',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  CHANGING_PASSWORD_ON_ACCOUNT: 'CHANGING_PASSWORD_ON_ACCOUNT',
  SINGLE_STORY: 'SINGLE_STORY',
  STORIES_LIST: 'STORIES_LIST',
  FEEDBACK: 'FEEDBACK',
  FEEDBACK_EDUCATOR: 'FEEDBACK_EDUCATOR',
  MAP: 'MAP',
  STORY_MAP: 'STORY_MAP',
  COMPLETE_REGISTRATION: 'COMPLETE_REGISTRATION',
  AGE_VALIDATION: 'AGE_VALIDATION',
  SURVEY: 'SURVEY',
  AD_BLOCK_DETECTED: 'AD_BLOCK_DETECTED',
  EXPLORE_PARAGRAPH: 'EXPLORE_PARAGRAPH',
  CERTIFICATE_MODAL: 'CERTIFICATE_MODAL',
};

export default constants;
