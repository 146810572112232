import React from 'react';

const HairStyle16 = ({ hairColor }) => {
  return (
    <>
      <path
        d="M143.814 116.721V112.689C143.814 110.204 145.83 108.188 148.315 108.188H151.928C154.394 108.188 156.401 110.173 156.428 112.639L156.473 116.669C156.135 115.495 155.79 114.741 155.072 114.058C154.4 113.419 153.366 113.538 152.573 114.021C150.775 115.115 149.399 115.184 147.623 114.134C146.748 113.617 145.588 113.518 144.969 114.324C144.469 114.976 144.18 115.702 143.814 116.721Z"
        fill={hairColor}
      />
      <g filter="url(#filter0_f_8170_60222)">
        <rect x="143.813" y="113.064" width="12.5646" height="1.21895" fill="#1A1A1A" fillOpacity="0.55" />
      </g>
      <path
        d="M143.757 109.374C143.757 107.174 145.641 105.468 147.84 105.558C149.368 105.621 150.783 105.622 152.331 105.558C154.53 105.467 156.415 107.174 156.415 109.374V112.782H143.757V109.374Z"
        fill="#323232"
      />
      <g filter="url(#filter1_f_8170_60222)">
        <rect x="143.813" y="111.939" width="12.5646" height="1.03142" fill="#1A1A1A" fillOpacity="0.45" />
      </g>
      <g filter="url(#filter2_d_8170_60222)">
        <rect x="142.313" y="112.314" width="15.6588" height="1.78154" rx="0.375062" fill="#323232" />
      </g>

      <defs>
        <filter
          id="filter0_f_8170_60222"
          x="142.688"
          y="111.939"
          width="14.8148"
          height="3.46936"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.562593" result="effect1_foregroundBlur_8170_60222" />
        </filter>
        <filter
          id="filter1_f_8170_60222"
          x="142.688"
          y="110.814"
          width="14.8148"
          height="3.28186"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.562593" result="effect1_foregroundBlur_8170_60222" />
        </filter>
        <filter
          id="filter2_d_8170_60222"
          x="141.188"
          y="111.564"
          width="17.9095"
          height="4.03186"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.375062" />
          <feGaussianBlur stdDeviation="0.562593" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.101961 0 0 0 0 0.101961 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8170_60222" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8170_60222" result="shape" />
        </filter>
      </defs>
    </>
  );
};

export default HairStyle16;
