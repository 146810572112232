import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { userInfo } from 'features/userInfo/userInfoSlice';

export const useAnalyticsLogger = (isAuthorized: boolean = true) => {
  const { REACT_APP_FIREBASE_CONFIG } = process.env;

  try {
    const firebaseConfig = JSON.parse(REACT_APP_FIREBASE_CONFIG);
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    let userID = null;

    if (isAuthorized) {
      /* eslint-disable */
      ({ userID } = userInfo.useGetUserInfoRequestQuery(null, {
        selectFromResult: ({ data }) => ({ userID: data?.id }),
      }));
      /* eslint-enable */

      setUserId(analytics, userID);
    }

    return (eventName: string, eventParams?: { check_user_ID?: string; [key: string]: any }): void => {
      if (eventParams?.check_user_ID) {
        setUserId(analytics, eventParams.check_user_ID);
        delete eventParams.check_user_ID;
      }

      logEvent(analytics, eventName, eventParams);
    };
  } catch (error) {
    console.error('Error initializing Firebase: ', error.message);

    return () => {};
  }
};
