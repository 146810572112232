import pattern1 from 'assets/userSetting/customization/sidebar/pattern_1.svg';
import pattern2 from 'assets/userSetting/customization/sidebar/pattern_2.svg';
import pattern3 from 'assets/userSetting/customization/sidebar/pattern_3.svg';
import pattern4 from 'assets/userSetting/customization/sidebar/pattern_4.svg';
import pattern5 from 'assets/userSetting/customization/sidebar/pattern_5.svg';
import pattern6 from 'assets/userSetting/customization/sidebar/pattern_6.svg';

export const selectStylesOptions = [
  { value: 'customization', label: 'Customization' },
  { value: 'default', label: 'Default' },
];

export const optionGender = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'non_binary', label: 'Non-Binary' },
  { value: 'prefer_not_say', label: 'Prefer Not to Answer' },
  { value: 'other', label: 'Other' },
];
export const accountSettingsBodyColor = [
  { value: '#FFFBF2', classNameStyles: 'body_color_pattern_1' },
  { value: '#E2BC96', classNameStyles: 'body_color_pattern_2' },
  { value: '#D9A068', classNameStyles: 'body_color_pattern_3' },
  { value: '#B88A67', classNameStyles: 'body_color_pattern_4' },
  { value: '#A26840', classNameStyles: 'body_color_pattern_5' },
  { value: '#6D5046', classNameStyles: 'body_color_pattern_6' },
];
export const accountSettingsHairColor = [
  { value: '#323232' },
  { value: '#383434' },
  { value: '#45332A' },
  { value: '#614338' },
  { value: '#725611' },
];
export const accountSettingsBackgroundColor = [
  { value: '#C0E3FB', pattern: 'pattern_1' },
  { value: '#AFDEC2', pattern: 'pattern_2' },
  { value: '#E8C5DE', pattern: 'pattern_3' },
  { value: '#CFC2EC', pattern: 'pattern_4' },
  { value: '#F3E2A3', pattern: 'pattern_5' },
  { value: '#535A75', pattern: 'pattern_6' },
];
export const accountSettingsBackgroundSidebar = [
  { value: 'pattern_1', linkToPattern: pattern1 },
  { value: 'pattern_2', linkToPattern: pattern2 },
  { value: 'pattern_3', linkToPattern: pattern3 },
  { value: 'pattern_4', linkToPattern: pattern4 },
  { value: 'pattern_5', linkToPattern: pattern5 },
  { value: 'pattern_6', linkToPattern: pattern6 },
];
export const accountSettingsAvatarBackgroundColor = [
  { value: 'linear-gradient(208.15deg, #FBB9B5 19.49%, #FBF0D0 82.57%), #BC983E' },
  { value: 'linear-gradient(208.15deg, #C86E99 19.49%, #CBA2B5 82.57%), #CBA2B5' },
  { value: 'linear-gradient(208.15deg, #7675F4 19.49%, #87BFE7 82.57%), #614338' },
  { value: 'linear-gradient(208.15deg, #5BB46E 19.49%, #AFDEC2 82.57%), #45332A' },
  { value: 'linear-gradient(208.15deg, #C86E99 19.49%, #FFCC7F 82.57%), #383434' },
  { value: 'linear-gradient(208.15deg, #AC7FE7 19.49%, #95D0FA 82.57%), #AC7FE7' },
];
export const accountSettingsHairStyles = [
  { id: 1, title: 'Hair Style 1' },
  { id: 2, title: 'Hair Style 2' },
  { id: 3, title: 'Hair Style 3' },
  { id: 4, title: 'Hair Style 4' },
  { id: 5, title: 'Hair Style 5' },
  { id: 6, title: 'Hair Style 6' },
  { id: 7, title: 'Hair Style 7' },
  { id: 8, title: 'Hair Style 8' },
  { id: 9, title: 'Hair Style 9' },
  { id: 10, title: 'Hair Style 10' },
  { id: 11, title: 'Hair Style 11' },
  { id: 12, title: 'Hair Style 12' },
  { id: 13, title: 'Hair Style 13' },
  { id: 14, title: 'Hair Style 14' },
  { id: 15, title: 'Hair Style 15' },
  { id: 16, title: 'Hair Style 16' },
  { id: 17, title: 'Hair Style 17' },
  { id: 18, title: 'Hair Style 18' },
];
export const accountSettingsAccessoriesStyles = [
  { id: 1, title: 'Accessories Style 1' },
  { id: 2, title: 'Accessories Style 2' },
  { id: 3, title: 'Accessories Style 3' },
  { id: 4, title: 'Accessories Style 4' },
  { id: 5, title: 'Accessories Style 5' },
  { id: 6, title: 'Accessories Style 6' },
  { id: 7, title: 'Accessories Style 7' },
  { id: 8, title: 'Accessories Style 8' },
  { id: 9, title: 'Accessories Style 9' },
  { id: 10, title: 'Accessories Style 10' },
  { id: 11, title: 'Accessories Style 11' },
  { id: 12, title: 'Accessories Style 12' },
];
export const accountSettingsClothesStyles = [
  { id: 1, title: 'Clothes Style 1' },
  { id: 2, title: 'Clothes Style 2' },
  { id: 3, title: 'Clothes Style 3' },
  { id: 4, title: 'Clothes Style 4' },
  { id: 5, title: 'Clothes Style 5' },
  { id: 6, title: 'Clothes Style 6' },
  { id: 7, title: 'Clothes Style 7' },
  { id: 8, title: 'Clothes Style 8' },
  { id: 9, title: 'Clothes Style 9' },
  { id: 10, title: 'Clothes Style 10' },
  { id: 11, title: 'Clothes Style 11' },
  { id: 12, title: 'Clothes Style 12' },
  { id: 13, title: 'Clothes Style 13' },
  { id: 14, title: 'Clothes Style 14' },
  { id: 15, title: 'Clothes Style 15' },
  { id: 16, title: 'Clothes Style 16' },
];
