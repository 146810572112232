import React from 'react';
import styles from '../Modal.module.scss';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const AddBlockeDetected = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(modalClose());
    window.location.reload();
  };

  return (
    <div className={styles.add_detect_blocker}>
      <div className={styles.modal_title}>Content on this page is blocked by Ad Blocker.</div>
      <div className={styles.modal_subtitle}>
        Please, disable it to proceed with the platform and unleash your potential with Global Nomads Group.
      </div>
      <div className={'button button_fill'} onClick={closeModal}>
        <div className={styles.title}>Ok, got it</div>
      </div>
    </div>
  );
};

export default AddBlockeDetected;
