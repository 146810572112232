import classNames from 'classnames/bind';
import styles from './SquareRadioButton.module.scss';

interface propsInterface {
  handleChange: any;
  selected: string;
  name: string;
  label: string;
}

const SquareRadioButton = ({ selected, handleChange, label, name }: propsInterface) => {
  const cn = classNames.bind(styles);
  const radioClasses = cn({
    radio_container: true,
    active: name === selected,
  });

  return (
    <div className={radioClasses} onClick={() => handleChange(name)}>
      <input type="radio" name={name} defaultChecked={name === selected} />
      <span className={styles.radio_btn} />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default SquareRadioButton;
