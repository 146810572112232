import React from 'react';

const HairStyle6 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M162.192 122.2C162.192 129.424 155.471 131.161 150.542 131.161C145.613 131.161 138.188 129.424 138.188 122.2C138.188 114.976 142.228 105.052 150.542 105C158.857 104.949 162.192 114.976 162.192 122.2Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle6;
