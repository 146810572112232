import React, { useState } from 'react';
import styles from '../Modal.module.scss';
import stylesTextInput from 'components/common/FormEllements/TextInput.module.scss';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from 'components/common/Button/Button';
import PasswordTextInput from 'components/common/FormEllements/PasswordTextInput';
import { localizationSite } from 'features/localization/localizationSlice';
import { getAuth, updatePassword } from 'firebase/auth';
// import { passwordValidator } from 'helpers/passwordValidator';
import { PasswordChecker } from 'components/PasswordChecker/PasswordChecker';
import { getPasswordValidator } from 'helpers/getPasswordValidator';

const ChangingPasswordOnAccount = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(modalClose());
  };
  const [siteLoader, setSiteLoader] = useState(false);
  const [passwordCheckItems, setPasswordCheckList] = useState({});
  const lang = useAppSelector((state) => state.login.acceptLanguage);
  const { data: getlocalizationInfo } = localizationSite.useGetTranslationsQuery(lang);
  const auth = getAuth();
  const user = auth.currentUser;
  const validatePass = getPasswordValidator(setPasswordCheckList);

  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required(getlocalizationInfo.required)
          .max(30, 'Maximum length 30 characters')
          .matches(/^\S*$/, 'No spaces allowed')
          .test('password', 'Validation error', validatePass),
        confirm_password: Yup.string()
          .required(getlocalizationInfo.required)
          .oneOf([Yup.ref('password'), null], getlocalizationInfo.passwords_must_match),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        setSiteLoader(true);
        setSubmitting(false);

        updatePassword(user, values.password)
          .then(() => {
            closeModal();
            setSiteLoader(false);
          })
          .catch((error) => {
            setErrors({ password: error.message });
            setSiteLoader(false);
          });
      }}>
      {({ errors, touched }) => {
        return (
          <div className={styles.changing_password_wrap}>
            <div className={styles.modal_title}>{getlocalizationInfo.changing_password}</div>

            <Form className={styles.changing_password_form}>
              <PasswordTextInput
                requiredField={true}
                label={getlocalizationInfo.new_password}
                name="password"
                inputClassName={stylesTextInput.change_pass_input}
                placeholder={getlocalizationInfo.new_password}
                inputError={errors.password && touched.password}
              />
              <PasswordChecker checkedItems={passwordCheckItems} className={styles.passcheck} />
              <PasswordTextInput
                requiredField={true}
                label={getlocalizationInfo.repeat_new_password}
                name="confirm_password"
                inputClassName={stylesTextInput.change_pass_input}
                placeholder={getlocalizationInfo.password_input_value}
                inputError={errors.confirm_password && touched.confirm_password}
              />
              <div className={styles.button_wrap}>
                <button onClick={closeModal} type="button" className={styles.cancel}>
                  {getlocalizationInfo.cancel}
                </button>
                <Button isLoading={siteLoader} type="submit" className={styles.save}>
                  {getlocalizationInfo.save}
                </Button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ChangingPasswordOnAccount;
