import { useAppDispatch } from 'app/hooks/reduxHooks';
import React, { useState, useRef, useEffect } from 'react';
import styles from '../Map.module.scss';
import marker from 'assets/icons/map/marker-1.svg';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import classNames from 'classnames/bind';
import { modalClose } from 'features/modal/modalSlice';
import { userInfo } from 'features/userInfo/userInfoSlice';
import PageHeader from 'components/PageHeader/PageHeader';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';

const FullStoriesMap = ({ moduleStories = [] }) => {
  const { REACT_APP_MAPTILER } = process.env;
  const mapteiler = JSON.parse(REACT_APP_MAPTILER);
  const dispatch = useAppDispatch();
  const setAnalyticsLogger = useAnalyticsLogger();
  const cn = classNames.bind(styles);
  const mapClasses = cn({ story_map_wrap: true });
  const fullMapContainer = useRef(null);
  const map = useRef(null);
  const timerId = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoomI] = useState(6);

  useEffect(() => {
    if (moduleStories?.length > 0) {
      setLng(moduleStories[0]?.coordinates?.longitude);
      setLat(moduleStories[0]?.coordinates?.latitude);
    }
  }, [moduleStories]);

  useEffect(() => {
    map.current = new maplibregl.Map({
      container: fullMapContainer.current,
      style: `https://api.maptiler.com/maps/${mapteiler.mapSkin}/style.json?key=${mapteiler.apiKey}`,
      center: [lng, lat],
      zoom: zoomI,
      attributionControl: false,
    });

    let nav = new maplibregl.NavigationControl({ showCompass: false });
    map.current.addControl(nav, 'bottom-right');

    moduleStories?.forEach((story) => {
      let el = document.createElement('div');

      el.className = 'marker';
      el.style.width = '48px';
      el.style.height = '48px';
      el.style.backgroundRepeat = 'no-repeat';
      el.style.backgroundPosition = 'center';
      el.style.cursor = 'pointer';
      el.style.backgroundImage = `url(${marker})`;

      new maplibregl.Marker(el)
        .setLngLat([story?.coordinates?.longitude, story?.coordinates?.latitude])
        .addTo(map.current);

      const handleZoom = () => {
        clearTimeout(timerId.current);

        timerId.current = setTimeout(() => setAnalyticsLogger('map_enlarge', { page: 'account-settings' }), 1000);
      };
      map.current.on('zoomend', handleZoom);
      /* eslint-disable */
      map.current.once('mouseover', () => setAnalyticsLogger('map_view', { page: 'account-settings' }));
      map.current.once('touchstart', () => setAnalyticsLogger('map_view', { page: 'account-settings' }));
      /* eslint-enable */
    });
  }, [moduleStories, lng, lat]);

  const handleResizeMap = () => dispatch(modalClose());
  /* eslint-disable */
  const { availableLanguages } = userInfo.useGetUserInfoRequestQuery(null, {
    selectFromResult: ({ data }) => ({ availableLanguages: data.available_languages }),
  });
  /* eslint-enable */
  return (
    <>
      <PageHeader title={moduleStories[0]?.title || ''} backLink={handleResizeMap}>
        <div className={styles.actions}>
          {/*TODO lang switcher exists in design but no info what it should do. For now it's not working*/}
          {/*<LangSwitcher languagesList={availableLanguages} />*/}
        </div>
      </PageHeader>
      <div className={mapClasses}>
        <div ref={fullMapContainer} className={styles.map} />
      </div>
    </>
  );
};

export default FullStoriesMap;
