import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './Feedback.module.scss';
import { modalClose, modalOpen } from 'features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import { ReactComponent as DecorImg } from 'assets/images/asideCourseDecor.svg';
import { RatingContainer } from 'components/StarRating';
import routes from 'features/routes/routes';
import { useNavigate } from 'react-router-dom';
import { useSaveFeedbackMutation } from 'features/feedback/feedback';
import { coursesApi } from 'features/courses/coursesApi';
import { modulesApi } from 'features/modules/modulesApi';
import constants from 'features/constants';
import { localizationSite } from 'features/localization/localizationSlice';
import {
  setUserStoryDescription,
  setUserStoryGallery,
  setUserStoryGalleryToRemove,
  setUserStoryId,
  setUserStoryTitle,
} from 'features/moduleStory/moduleStory';

export interface FeedbackInterface {
  rate: number;
  comment: string;
  module_id: string;
  course_id: string;
}

const Feedback = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [finishedCourse, setFinishedCourse]: any = useState(false);
  const [currentModule, setCurrentModule]: any = useState();
  const [surveyIsRequired, setSurveyIsRequired] = useState(undefined);
  const [isSurvey, setDataSurvey] = useState(false);
  const [nextModule, setNextModule] = useState(null);

  const [rate, setRate] = useState(null);
  const [feedbackText, setFeedbackText] = useState(null);

  const { modalData } = useAppSelector((state) => state.modal);
  const { data, isLoading: isLoadingModule }: any = coursesApi.useGetSingleCourseQuery(modalData[0]);
  const { data: getDataSingleModule, isLoading } = modulesApi.useGetSingleModuleQuery(modalData[1]);

  const [saveFeedback] = useSaveFeedbackMutation();
  const lang = useAppSelector((state) => state.login.acceptLanguage);
  const { data: localizationSiteData } = localizationSite.useGetTranslationsQuery(lang);

  useEffect(() => {
    if (data?.modules) {
      for (let item = 0; item < data.modules?.length; item++) {
        if (data.modules[item].id === modalData[1]) {
          setCurrentModule(data.modules[item]);
        }
      }
    }
    setFinishedCourse(
      !(data?.modules.filter((obj) => obj.id !== getDataSingleModule?.id && !obj.ready_to_review)?.length > 0)
    );
    setNextModule({
      id: data?.modules.find((obj) => obj.id !== getDataSingleModule?.id && !obj.ready_to_review)?.id,
      title: data?.modules.find((obj) => obj.id !== getDataSingleModule?.id && !obj.ready_to_review)?.title,
    });
  }, [data]);

  const feedbackContent: FeedbackInterface = {
    rate: Number(rate),
    comment: feedbackText,
    module_id: `${modalData[1]}`,
    course_id: `${modalData[0]}`,
  };

  const closeModal = () => {
    if (!surveyIsRequired) dispatch(modalClose());
  };

  const sendFeedback = async () => {
    try {
      await saveFeedback(feedbackContent);
    } catch (error) {
      console.error(error);
    }
  };

  const openSurveyModal = () => {
    if (rate && feedbackText) sendFeedback();
    closeModal();
    dispatch(
      modalOpen({
        modalType: constants.SURVEY,
        modalData: getDataSingleModule,
        modalSize: '',
      })
    );
  };

  const handleSkipSurvey = () => {
    if (rate && feedbackText) sendFeedback();
    if (finishedCourse) {
      navigate(`${routes.courses}/${modalData[0]}/${modalData[1]}/getInvolved`);
    } else {
      navigate(`${routes.courses}/${modalData[0]}`);
    }
    closeModal();
  };

  const backToCourses = () => {
    if (rate && feedbackText) sendFeedback();
    navigate(`${routes.courses}`);
    closeModal();
  };

  const handleNextModule = () => {
    closeModal();
    dispatch(setUserStoryGallery([]));
    dispatch(setUserStoryTitle(''));
    dispatch(setUserStoryDescription(''));
    dispatch(setUserStoryId(''));
    dispatch(setUserStoryGalleryToRemove([]));

    if (nextModule?.id) navigate(`${routes.courses}/${modalData[0]}/${nextModule?.id}`);
    if (rate && feedbackText) sendFeedback();
  };

  const handleFinishCourse = () => {
    if (rate && feedbackText) sendFeedback();
    navigate(`${routes.courses}/${modalData[0]}/${modalData[1]}/getInvolved`);
    closeModal();
  };

  useEffect(() => {
    if (getDataSingleModule?.survey) {
      setDataSurvey(true);
      setSurveyIsRequired(getDataSingleModule.surveyMandatory);
    }
  }, [getDataSingleModule]);

  return (
    <div className={styles.feedback_wrap}>
      {!isLoadingModule && !isLoading && (
        <>
          {!surveyIsRequired && (
            <div
              className={styles.feedback_close}
              onClick={() => {
                closeModal();
                navigate(routes.courses);
              }}></div>
          )}
          <div className={styles.feedback_content}>
            <div className={styles.feedback_module_title}>
              {currentModule?.hashtag ? currentModule?.hashtag : currentModule?.title}
            </div>
            <div className={styles.feedback_status}>{localizationSiteData.complete}</div>
            <div className={styles.feedback_rate_label}>{localizationSiteData.module_question}</div>
            <div className={styles.feedback_rate}>
              <RatingContainer handleRate={setRate} />
            </div>
            <TextareaAutosize
              minRows={5}
              maxRows={5}
              placeholder={localizationSiteData.type_feedback}
              className={styles.input_form}
              onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                setFeedbackText(event.currentTarget.value);
              }}
            />
            {!isSurvey && nextModule?.id && !isLoadingModule && (
              <>
                <div className={styles.feedback_next_module_label}>{localizationSiteData?.ready_proceed}</div>
                <div className={styles.feedback_next_module_title}>
                  <span>{nextModule?.title}</span>
                </div>
              </>
            )}

            {isSurvey && (
              <>
                <div className={styles.feedback_next_module_label}>
                  {localizationSiteData.global_nomads_wants_to_know}
                </div>
                <div className={styles.feedback_next_module_label}>{localizationSiteData.a_short_survey_complete}</div>
              </>
            )}

            <div className={styles.feedback_actions}>
              {/* Case 1: has survey, required */}
              {isSurvey && surveyIsRequired && (
                <button className={styles.btn_next_mobule} onClick={openSurveyModal}>
                  {localizationSiteData.take_survey_now}
                </button>
              )}

              {/* Case 2: has survey, no required */}
              {isSurvey && !surveyIsRequired && (
                <>
                  <button className={styles.btn_back_to_courses} onClick={handleSkipSurvey}>
                    {localizationSiteData.skip_survey_this_time}
                  </button>
                  <button className={styles.btn_next_mobule} onClick={openSurveyModal}>
                    {localizationSiteData.take_survey_now}
                  </button>
                </>
              )}

              {/* Case 3: has no survey, has next module */}
              {!isSurvey && !finishedCourse && (
                <>
                  <button className={styles.btn_back_to_courses} onClick={backToCourses}>
                    {localizationSiteData.back_courses}
                  </button>
                  <button className={styles.btn_next_mobule} onClick={handleNextModule}>
                    {localizationSiteData.next_module}
                  </button>
                </>
              )}

              {/* Case 4: has no survey, last module */}
              {!isSurvey && finishedCourse && (
                <button className={styles.btn_back_to_courses} onClick={handleFinishCourse}>
                  {localizationSiteData.finish_course}
                </button>
              )}
            </div>
          </div>
          <div className={styles.feedback_illustration}>
            <DecorImg className={styles.decor_img} />
          </div>
        </>
      )}
    </div>
  );
};

export default Feedback;
