import React from 'react';

const ClothesStyle8 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59378)">
        <path
          d="M146.925 127.366C146.968 127.346 147.03 127.362 147.061 127.416C148.581 130.057 152.128 129.701 153.258 127.299C153.288 127.234 153.358 127.215 153.403 127.235C155.942 128.386 157.609 129.61 158.748 131.208C159.888 132.809 160.512 134.803 160.927 137.523C160.936 137.579 160.891 137.63 160.836 137.63H139.17C139.114 137.63 139.07 137.579 139.079 137.523C139.53 134.672 140.303 132.689 141.548 131.144C142.793 129.598 144.524 128.473 146.925 127.366Z"
          fill="white"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M159.01 131.714C159.24 131.993 159.379 132.353 159.379 132.746C159.379 133.631 158.676 134.348 157.809 134.348C157.383 134.348 156.997 134.175 156.714 133.894C156.431 134.175 156.045 134.348 155.619 134.348C155.193 134.348 154.806 134.175 154.524 133.894C154.241 134.175 153.854 134.348 153.428 134.348C153.002 134.348 152.616 134.175 152.333 133.894C152.05 134.175 151.664 134.348 151.238 134.348C150.812 134.348 150.426 134.175 150.143 133.894C149.86 134.175 149.474 134.348 149.048 134.348C148.622 134.348 148.236 134.175 147.953 133.894C147.67 134.175 147.284 134.348 146.858 134.348C146.432 134.348 146.046 134.175 145.763 133.894C145.48 134.175 145.094 134.348 144.668 134.348C144.242 134.348 143.855 134.175 143.573 133.894C143.29 134.175 142.903 134.348 142.477 134.348C141.61 134.348 140.907 133.631 140.907 132.746C140.907 132.333 141.06 131.957 141.311 131.673C142.822 129.814 144.453 128.353 146.858 127.597C148.996 130.316 151.796 130.269 153.553 127.597C156.566 128.755 157.961 130.107 159.01 131.714Z"
          fill="#91DDB0"
        />
        <path
          d="M158.562 130.776C158.779 131.055 158.91 131.415 158.91 131.809C158.91 132.693 158.246 133.411 157.428 133.411C157.025 133.411 156.661 133.238 156.394 132.957C156.127 133.238 155.762 133.411 155.36 133.411C154.958 133.411 154.593 133.238 154.326 132.957C154.059 133.238 153.694 133.411 153.292 133.411C152.89 133.411 152.525 133.238 152.258 132.957C151.991 133.238 151.626 133.411 151.224 133.411C150.822 133.411 150.457 133.238 150.19 132.957C149.923 133.238 149.558 133.411 149.156 133.411C148.754 133.411 148.389 133.238 148.122 132.957C147.855 133.238 147.49 133.411 147.088 133.411C146.686 133.411 146.321 133.238 146.054 132.957C145.787 133.238 145.422 133.411 145.02 133.411C144.618 133.411 144.253 133.238 143.986 132.957C143.719 133.238 143.354 133.411 142.952 133.411C142.133 133.411 141.47 132.693 141.47 131.809C141.47 131.396 141.614 131.02 141.851 130.736C143.278 128.876 144.896 128.024 147.166 127.269C149.234 129.941 151.472 129.894 153.132 127.222C155.976 128.38 157.571 129.17 158.562 130.776Z"
          fill="#323232"
        />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle8;
