import React from 'react';

const ClothesStyle13 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59454)">
        <path
          d="M146.772 127.226C146.815 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.943 128.387 157.61 129.611 158.749 131.209C159.888 132.809 160.512 134.804 160.928 137.523C160.936 137.58 160.892 137.631 160.836 137.631H139.17C139.115 137.631 139.07 137.58 139.079 137.523C139.528 134.667 140.265 132.649 141.474 131.07C142.683 129.492 144.376 128.334 146.772 127.226Z"
          fill="#FFCC7F"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M144.291 128.559L144.291 134.115" stroke="#323232" strokeWidth="0.375062" />
        <path d="M145.703 127.9L145.703 133.456" stroke="#323232" strokeWidth="0.375062" />
        <line x1="155.589" y1="128.371" x2="155.589" y2="134.115" stroke="#323232" strokeWidth="0.375062" />
        <line x1="154.177" y1="127.617" x2="154.177" y2="133.362" stroke="#323232" strokeWidth="0.375062" />
        <path
          d="M143.16 133.451C143.16 132.933 143.58 132.513 144.098 132.513H155.877C156.395 132.513 156.815 132.933 156.815 133.451V137.6H143.16V133.451Z"
          fill="#FBF0D0"
          stroke="#323232"
          strokeWidth="0.375062"
        />
        <ellipse cx="144.851" cy="133.971" rx="0.84745" ry="0.847542" fill="#323232" />
        <ellipse cx="154.833" cy="133.971" rx="0.84745" ry="0.847542" fill="#323232" />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle13;
