import { useField } from 'formik';
import React, { useState } from 'react';
import styles from './TextInput.module.scss';

import { ReactComponent as OpenEyeIcon } from 'assets/icons/openEye.svg';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/closeEye.svg';
import classNames from 'classnames/bind';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg';

type InputProps = {
  label?: string;
  name: string;

  value?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: {} | '';
  requiredField?: boolean;
  inputError?: any;
  maxLength?: number;
  readOnly?: boolean;
};

const PasswordTextInput = ({ label, requiredField, inputError, inputClassName, readOnly, ...props }: InputProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.

  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(true);

  const changeShowPasswordType = () => {
    setShowPassword(!showPassword);
  };

  const cn: Function = classNames.bind(styles);
  const passTextInput = cn({ row: true, input_error: inputError });

  return (
    <div className={passTextInput}>
      {label && (
        <label className={styles.label_text} htmlFor={props.name}>
          {label}
          {requiredField && <span className={styles.label_required}>*</span>}
        </label>
      )}

      <div className={styles.icon_password}>
        {showPassword ? (
          <CloseEyeIcon onClick={changeShowPasswordType} />
        ) : (
          <OpenEyeIcon onClick={changeShowPasswordType} />
        )}
      </div>

      <input
        readOnly={readOnly}
        className={`${styles.input_form} 
                ${inputClassName}`}
        type={showPassword ? 'password' : 'text'}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <div className={styles.error}>
          <ErrorIcon className={styles.error_icon} />
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default PasswordTextInput;
