import { entryApi } from 'app/entryApi';

interface FavoriteStoryPayload {
  id?: string;
}

export const favoriteStories = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    addToFavoriteStory: builder.mutation<any, FavoriteStoryPayload>({
      query(storyId) {
        return {
          url: '/favorite-story/add',
          method: 'POST',
          body: { story_id: storyId },
        };
      },
      invalidatesTags: ['GET_ALL_FAVORITE_STORIES', 'GET_SINGLE_MODULE_STORY', 'GET_MODULE_STORIES'],
    }),
    getAllFavoriteStories: builder.query({
      query: () => ({ url: '/favorite-stories' }),
      transformResponse: (data) => data,
      providesTags: () => ['GET_ALL_FAVORITE_STORIES'],
    }),
    removeFromFavoriteStory: builder.mutation<any, FavoriteStoryPayload>({
      query(storyId) {
        return {
          url: '/favorite-story/remove',
          method: 'POST',
          body: { story_id: storyId },
        };
      },
      invalidatesTags: ['GET_ALL_FAVORITE_STORIES', 'GET_SINGLE_MODULE_STORY', 'GET_MODULE_STORIES'],
    }),
  }),
});

export const { useAddToFavoriteStoryMutation, useRemoveFromFavoriteStoryMutation } = favoriteStories;
