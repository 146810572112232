import React from 'react';

const BangStyle15 = () => {
  return (
    <>
      <path
        d="M227 89C227 71.2305 220.204 54.1888 208.108 41.6238C196.012 29.0589 179.606 22 162.5 22C145.394 22 128.988 29.0589 116.892 41.6238C104.796 54.1888 98 71.2305 98 89L227 89Z"
        fill="#323232"
      />
      <g filter="url(#filter0_f_4638_52335)">
        <rect x="100" y="83" width="123" height="7" fill="#1A1A1A" fillOpacity="0.65" />
      </g>
      <rect x="89" y="87" width="147" height="23" rx="11.5" fill="#323232" />
      <defs>
        <filter
          id="filter0_f_4638_52335"
          x="88"
          y="71"
          width="147"
          height="31"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_4638_52335" />
        </filter>
      </defs>
    </>
  );
};

export default BangStyle15;
