import React from 'react';
import styles from '../Modal.module.scss';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const AgeValidation = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(modalClose());
  };

  return (
    <div className={styles.age_validation}>
      <div className={styles.modal_title}>
        Unfortunately, you cannot register for the platform due to age restrictions. Please contact your teacher for
        successful registration.
      </div>
      <div className={'button button_fill'} onClick={closeModal}>
        <div className={styles.title}>Accept</div>
      </div>
    </div>
  );
};

export default AgeValidation;
