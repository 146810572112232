import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface redstoneStateInterface {
  redstoneToken: string;
}

const initialState: redstoneStateInterface = { redstoneToken: null };

export const redstoneUserTokenSlice = createSlice({
  name: 'redstoneUserToken',
  initialState,
  reducers: {
    setRedstoneTokenAfterlogin: (state, action: PayloadAction<{ redstoneToken: string }>) => {
      state.redstoneToken = action.payload.redstoneToken;
    },
  },
});

export const { setRedstoneTokenAfterlogin } = redstoneUserTokenSlice.actions;
export default redstoneUserTokenSlice.reducer;

const { REACT_APP_FIREBASE_URL } = process.env;

export const redstoneToken = createApi({
  reducerPath: 'getRedstoneTokenReducer',
  baseQuery: fetchBaseQuery({ baseUrl: REACT_APP_FIREBASE_URL }),
  endpoints: (builder) => ({
    getRedstoneLoginToken: builder.mutation<any, any>({
      query(data) {
        return {
          url: '/auth/firebase/token',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});
