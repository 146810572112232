import React from 'react';

const PreviewDefaultUserAvatar = () => {
  return (
    <>
      <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip2_8086_55960)">
        <g clipPath="url(#clip3_8086_55960)">
          <g clipPath="url(#clip4_8086_55960)">
            <rect width="36" height="36" transform="translate(132.936 102)" fill="#C0E3FB" />
            <path
              d="M150.889 108.281C146.051 108.281 142.129 112.311 142.129 117.281C142.129 122.252 146.051 126.281 150.889 126.281C155.727 126.281 159.649 122.252 159.649 117.281C159.649 112.311 155.727 108.281 150.889 108.281ZM140.596 128.194C138.54 128.194 136.873 129.905 136.873 132.018L136.873 132.694C136.873 136.074 138.574 138.604 141.183 140.236C143.749 141.841 147.198 142.594 150.889 142.594C154.58 142.594 158.029 141.841 160.595 140.236C163.204 138.604 164.905 136.074 164.905 132.694L164.905 132.018C164.905 129.905 163.237 128.194 161.182 128.194H140.596Z"
              fill="white"
            />
          </g>
        </g>
        <rect x="133.436" y="102.5" width="35" height="35" rx="17.5" stroke="#E8F6FF" />
      </g>
      <clipPath id="clip2_8086_55960">
        <rect x="132.936" y="102" width="36" height="36" fill="white" />
      </clipPath>
    </>
  );
};

export default PreviewDefaultUserAvatar;
