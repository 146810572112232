import React from 'react';

const ClothesStyle15 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59500)">
        <path
          d="M146.772 127.226C146.815 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.943 128.387 157.61 129.611 158.749 131.209C159.888 132.809 160.512 134.804 160.928 137.523C160.936 137.58 160.892 137.631 160.836 137.631H139.17C139.115 137.631 139.07 137.58 139.079 137.523C139.528 134.667 140.265 132.649 141.474 131.07C142.683 129.492 144.376 128.334 146.772 127.226Z"
          fill="#C0E3FB"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.849 127.288C146.849 127.288 144.15 130.395 146.504 131.667C148.858 132.938 150.191 129.171 150.191 129.171"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M153.472 127.523C153.472 127.523 156.171 130.631 153.817 131.902C151.462 133.173 150.129 129.406 150.129 129.406"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle15;
