import React from 'react';

const ClothesStyle12 = () => {
  return (
    <>
      <path
        d="M125.543 237.032C126 236.821 126.666 236.989 126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1514 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032Z"
        fill="#BAE6FD"
        stroke="#0369A1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.7217 255.771L122.159 295.981C122.517 296.487 123.244 296.549 123.683 296.11L160.268 259.521C160.67 259.119 161.325 259.133 161.71 259.551L195.353 296.082C195.772 296.536 196.498 296.506 196.877 296.02L228.287 255.771"
        stroke="#0369A1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="162.008" cy="291.928" rx="9.54008" ry="9.54113" fill="#0369A1" />
      <ellipse cx="162.008" cy="319.045" rx="9.54008" ry="9.54113" fill="#0369A1" />
    </>
  );
};

export default ClothesStyle12;
