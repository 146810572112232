import React from 'react';
import style from '../../Customization/Customization.module.scss';
import RenderClothes from 'components/pages/Account/components/PreviewUserAvatar/RenderClothes';
import RenderHairStyle from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle';
import renderBangStyle from 'components/pages/Account/components/PreviewUserAvatar/RenderBangStyle';
import renderAccessoriesStyle from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle';
import classNames from 'classnames';

export interface previewUserAva {
  userValues: {
    select_style: string;
    body_color: string;
    hair_style: number;
    avatar_background_color: string;
    hair_color: string;
    clothes: number;
    accessories: number;
  };
  customStyles?: string;
}

const UserAvatar = ({ customStyles, userValues }: previewUserAva) => {
  return (
    <div className={classNames(style.preview_user_avatar, customStyles)}>
      <div className={style.user_avatar_background} style={{ background: userValues.avatar_background_color }}>
        <svg viewBox="0 0 324 350" fill="none" xmlns="http://www.w3.org/2000/svg">
          {RenderHairStyle(userValues.hair_style, userValues.hair_color)}
          <rect
            x="128"
            y="155"
            width="67.7159"
            height="128"
            rx="13.7769"
            fill={userValues.body_color}
            stroke="#323232"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            d="M129.87 237.55V196.635H198C192.869 217.616 162.272 238.683 129.87 237.55Z"
            fill="#7E7E7E"
            fillOpacity="0.07"
          />
          <path
            d="M118 144.5C118 156.189 107.45 166 94 166C80.5501 166 70 156.189 70 144.5C70 132.811 80.5501 123 94 123C107.45 123 118 132.811 118 144.5Z"
            fill={userValues.body_color}
            stroke="#323232"
            strokeWidth="4"
          />
          <path
            d="M205 144.5C205 156.189 215.55 166 229 166C242.45 166 253 156.189 253 144.5C253 132.811 242.45 123 229 123C215.55 123 205 132.811 205 144.5Z"
            fill={userValues.body_color}
            stroke="#323232"
            strokeWidth="4"
          />
          <path
            d="M228 134.261C228 156.713 222.042 173.834 210.986 185.337C199.941 196.828 183.532 203 162.03 203C140.526 203 123.842 196.827 112.529 185.321C101.211 173.81 95 156.69 95 134.261C95 111.804 101.232 88.379 112.779 70.6291C124.311 52.9028 141.009 41 162.03 41C183.043 41 199.472 52.8934 210.74 70.6117C222.025 88.3582 228 111.787 228 134.261Z"
            fill={userValues.body_color}
            stroke="#323232"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            d="M159.474 133C157.192 140.887 156 145.008 156 153.214C159.056 154.676 161.979 155.041 167 155"
            stroke="#323232"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126 144.178C130.252 144.178 133.5 139.8 133.5 134.678C133.5 129.555 130.252 125.178 126 125.178C121.748 125.178 118.5 129.555 118.5 134.678C118.5 139.8 121.748 144.178 126 144.178Z"
            fill="#323232"
            stroke="#323232"
            strokeLinecap="round"
          />
          <path
            d="M128.946 134.963C130.347 134.963 131.515 133.863 131.515 132.468C131.515 131.074 130.347 129.974 128.946 129.974C127.545 129.974 126.377 131.074 126.377 132.468C126.377 133.863 127.545 134.963 128.946 134.963Z"
            fill="#FFFBF2"
            stroke="#323232"
          />
          <path
            d="M197 143.5C201.252 143.5 204.5 139.122 204.5 134C204.5 128.878 201.252 124.5 197 124.5C192.748 124.5 189.5 128.878 189.5 134C189.5 139.122 192.748 143.5 197 143.5Z"
            fill="#323232"
            stroke="#323232"
            strokeLinecap="round"
          />
          <ellipse cx="199.946" cy="131.791" rx="2.06865" ry="1.99477" fill="#FFFBF2" />
          <path
            d="M153 167C155.833 170.018 163.2 174.244 170 167"
            stroke="#323232"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g filter="url(#filter0_f_4638_51668)">
            <ellipse cx="126.5" cy="162.5" rx="9.5" ry="5.5" fill="#FC9D80" />
          </g>
          <g filter="url(#filter1_f_4638_51668)">
            <ellipse cx="205.5" cy="162.5" rx="9.5" ry="5.5" fill="#FC9D80" />
          </g>
          {RenderClothes(userValues.clothes)}
          {renderBangStyle(userValues.hair_style, userValues.hair_color)}
          {renderAccessoriesStyle(userValues.accessories)}
          <defs>
            <filter
              id="filter0_f_4638_51668"
              x="105"
              y="145"
              width="43"
              height="35"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_4638_51668" />
            </filter>
            <filter
              id="filter1_f_4638_51668"
              x="184"
              y="145"
              width="43"
              height="35"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_4638_51668" />
            </filter>
            <linearGradient
              id="paint0_linear_4638_51668"
              x1="121.444"
              y1="103.328"
              x2="145.329"
              y2="152.376"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E8F6FF" />
              <stop offset="1" stopColor="#C0C8FE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4638_51668"
              x1="192.99"
              y1="103.328"
              x2="216.876"
              y2="152.376"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#E8F6FF" />
              <stop offset="1" stopColor="#C0C8FE" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default UserAvatar;
