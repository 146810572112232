import React from 'react';
import HairStyle2 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style2';
import HairStyle3 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style3';
import HairStyle4 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style4';
import HairStyle6 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style6';
import HairStyle7 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style7';
import HairStyle8 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style8';
import HairStyle9 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style9';
import HairStyle14 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style14';
import HairStyle18 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style18';

const RenderHairStyle = ({ hairStyle, hairColor }) => {
  switch (hairStyle) {
    case 2:
      return <HairStyle2 hairColor={hairColor} />;
    case 3:
      return <HairStyle3 hairColor={hairColor} />;
    case 4:
      return <HairStyle4 hairColor={hairColor} />;
    case 6:
      return <HairStyle6 hairColor={hairColor} />;
    case 7:
      return <HairStyle7 hairColor={hairColor} />;
    case 8:
      return <HairStyle8 hairColor={hairColor} />;
    case 9:
      return <HairStyle9 hairColor={hairColor} />;
    case 10:
      return <></>;
    case 14:
      return <HairStyle14 hairColor={hairColor} />;
    case 18:
      return <HairStyle18 />;
    default:
      return <></>;
  }
};

export default RenderHairStyle;
