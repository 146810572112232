import React from 'react';
import styles from '../Modal.module.scss';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const ExploreParagraph = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(modalClose());
  };

  return (
    <div className={styles.explore_paragraph_wrap}>
      <div className={styles.modal_title}>Explore Section</div>
      <div className={styles.modal_subtitle}>
        Now you get to explore stories written by other young people around the world! In this explore section you will
        get to read stories written by youth telling the story of their favorite local business. On this page, there is
        an interactive map of the world, with pins dropped in the countries where your global peer story writers live.
        You can click on these pins and a preview of the story will pop up automatically. Don’t like the pins? There’s
        another way to access the stories! Below the map, you can explore thumbnail links to the same stories written by
        your global peers. Just click on one of the boxes and your screen will fill up with the story of your choice.
        You can continue to explore by clicking any other stories that pop up when you’re done reading. Enjoy!
      </div>
      <div className={'button button_fill'} onClick={closeModal}>
        <div className={styles.title}>Okay, got it!</div>
      </div>
    </div>
  );
};

export default ExploreParagraph;
