import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { ReactComponent as ColorPikerIcon } from 'assets/userSetting/customization/colorPiker.svg';
import style from './ColorPiker.module.scss';

const ColorSelector = ({ defaultHairColorSelect, setDefaultHairColorSelect }) => {
  const [display, setDisplay] = useState(false);
  const onClickMethod = () => {
    setDisplay(!display);
  };

  const onCloseMethod = () => {
    setDisplay(false);
  };

  const onChangeMethod = (color) => {
    setDefaultHairColorSelect(color.hex);
  };

  return (
    <>
      <ColorPikerIcon onClick={onClickMethod} />
      {display ? (
        <div className={style.popover}>
          <div className={style.cover} onClick={onCloseMethod} />
          <SketchPicker color={defaultHairColorSelect} onChange={onChangeMethod} />
        </div>
      ) : null}
    </>
  );
};

export default ColorSelector;
