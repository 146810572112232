import React from 'react';

const RenderBackgroundColor = (userBackground) => {
  switch (userBackground) {
    case 'linear-gradient(208.15deg, #FBB9B5 19.49%, #FBF0D0 82.57%), #BC983E':
      return (
        <>
          <linearGradient
            id="paint0_linear_8134_58100"
            x1="164.5"
            y1="110.9"
            x2="150"
            y2="138"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FBB9B5" />
            <stop offset="1" stopColor="#FBF0D0" />
          </linearGradient>
        </>
      );

    case 'linear-gradient(208.15deg, #C86E99 19.49%, #CBA2B5 82.57%), #CBA2B5':
      return (
        <>
          <linearGradient
            id="paint0_linear_8134_58100"
            x1="164.5"
            y1="110.9"
            x2="150"
            y2="138"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#C86E99" />
            <stop offset="1" stopColor="#CBA2B5" />
          </linearGradient>
        </>
      );

    case 'linear-gradient(208.15deg, #7675F4 19.49%, #87BFE7 82.57%), #614338':
      return (
        <>
          <linearGradient
            id="paint0_linear_8134_58100"
            x1="164.5"
            y1="110.9"
            x2="150"
            y2="138"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#7675F4" />
            <stop offset="1" stopColor="#87BFE7" />
          </linearGradient>
        </>
      );

    case 'linear-gradient(208.15deg, #5BB46E 19.49%, #AFDEC2 82.57%), #45332A':
      return (
        <>
          <linearGradient
            id="paint0_linear_8134_58100"
            x1="164.5"
            y1="110.9"
            x2="150"
            y2="138"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5BB46E" />
            <stop offset="1" stopColor="#AFDEC2" />
          </linearGradient>
        </>
      );

    case 'linear-gradient(208.15deg, #C86E99 19.49%, #FFCC7F 82.57%), #383434':
      return (
        <>
          <linearGradient
            id="paint0_linear_8134_58100"
            x1="164.5"
            y1="110.9"
            x2="150"
            y2="138"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#C86E99" />
            <stop offset="1" stopColor="#FFCC7F" />
          </linearGradient>
        </>
      );

    case 'linear-gradient(208.15deg, #AC7FE7 19.49%, #95D0FA 82.57%), #AC7FE7':
      return (
        <>
          <linearGradient
            id="paint0_linear_8134_58100"
            x1="164.5"
            y1="110.9"
            x2="150"
            y2="138"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#AC7FE7" />
            <stop offset="1" stopColor="#95D0FA" />
          </linearGradient>
        </>
      );

    default:
      return (
        <>
          <linearGradient
            id="paint0_linear_8134_58100"
            x1="164.5"
            y1="110.9"
            x2="150"
            y2="138"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FBB9B5" />
            <stop offset="1" stopColor="#FBF0D0" />
          </linearGradient>
        </>
      );
  }
};

export default RenderBackgroundColor;
