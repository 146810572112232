import React from 'react';

const ClothesStyle11 = () => {
  return (
    <>
      <path
        d="M125.543 237.032C126 236.821 126.666 236.989 126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1514 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032Z"
        fill="#FEFCE8"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.35 237.516L125.792 281.264C125.819 282.078 126.756 282.521 127.401 282.024L158.494 258.104"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M196.654 237.516L195.212 281.264C195.185 282.078 194.248 282.521 193.603 282.024L162.51 258.104"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        y1="-2"
        x2="87.3823"
        y2="-2"
        transform="matrix(0.0116259 0.999932 -0.999932 0.0116284 158.494 258.606)"
        stroke="#323232"
        strokeWidth="4"
      />
      <ellipse cx="174.561" cy="293.757" rx="6.02532" ry="6.02597" fill="#323232" />
      <ellipse cx="174.561" cy="323.887" rx="6.02532" ry="6.02597" fill="#323232" />
    </>
  );
};

export default ClothesStyle11;
