import React from 'react';

const ClothesStyle16 = () => {
  return (
    <>
      <path
        d="M126.4 249.091C126.725 248.958 127.201 248.995 127.622 249.302C135.76 255.246 149.285 257.611 161.995 257.427C168.408 257.334 174.74 256.592 180.258 255.268C185.737 253.954 190.581 252.027 193.881 249.457C194.394 249.057 194.947 249.01 195.297 249.15C222.741 260.128 240.737 271.766 253.006 286.933C265.272 302.095 271.984 320.995 276.447 346.849C276.55 347.448 276.084 348 275.477 348H44.5325C43.9266 348 43.4613 347.445 43.5675 346.848C48.3905 319.72 56.3177 300.604 69.306 285.647C82.3137 270.669 100.561 259.671 126.4 249.091Z"
        fill="white"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.853 234L160 250.571L196.654 234V250.571L167.532 263.738V350H152.469V263.592L124.853 250.571V234Z"
        fill="#323232"
      />
    </>
  );
};

export default ClothesStyle16;
