import React, { useState, useEffect } from 'react';
import styles from './FavoriteStoryToggle.module.scss';
import { classNamesInterface } from 'types/types';
import { ReactComponent as PinnedIcon } from 'assets/images/heardWhite.svg';
import classNames from 'classnames/bind';
import {
  useAddToFavoriteStoryMutation,
  useRemoveFromFavoriteStoryMutation,
} from 'features/favoriteStory/favoriteStory';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';

interface propsInterface extends classNamesInterface {
  storyData?: any;
}

const FavoriteStoryToggle = ({ storyData }: propsInterface) => {
  const logEvent = useAnalyticsLogger();
  const [isFavorite, setIsFavorite] = useState(false);
  const [addToFavoriteStory] = useAddToFavoriteStoryMutation();
  const [removeFromFavoriteStory] = useRemoveFromFavoriteStoryMutation();

  const cn = classNames.bind(styles);
  const actionClasses = cn({
    story_action: true,
    favorite: isFavorite,
  });

  useEffect(() => {
    setIsFavorite(storyData?.is_favorite);
  }, [storyData]);

  const handleFavStory = async (pinned, id) => {
    try {
      if (pinned) {
        logEvent('story_favourite_unpinned', { story_favourite_ID: id });
      } else {
        logEvent('story_favourite_pinned', { story_favourite_ID: id });
      }

      const result = await (pinned ? removeFromFavoriteStory(id) : addToFavoriteStory(id));
      !('data' in result) && console.error('error');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <button
      className={actionClasses}
      onClick={() => {
        setIsFavorite(!isFavorite);
        handleFavStory(isFavorite, storyData?.id);
      }}>
      <PinnedIcon />
    </button>
  );
};

export default FavoriteStoryToggle;
