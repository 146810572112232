import { passwordValidator, PasswordValidatorType } from 'helpers/passwordValidator';

export const getPasswordValidator = (setPasswordCheckList) => (value: string) => {
  const validateFields: PasswordValidatorType = passwordValidator(value);
  const errorFields: string[] = [];

  setPasswordCheckList((prevState) => {
    const newState = { ...prevState };

    for (let key in validateFields) {
      if (validateFields[key] === true) {
        newState[key] = true;
      } else if (validateFields[key] === false) {
        newState[key] = false;
        errorFields.push(key);
      }
    }

    return newState;
  });

  return errorFields.length === 0;
};
