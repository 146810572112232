interface animationClassesInterface {
  appear: 'animate_appear';
  appearActive: 'animate_appear_active';
  appearDone: 'animate_appear_done';
  enter: 'animate_enter';
  enterActive: 'animate_enter_active';
  enterDone: 'animate_enter_done';
  exit: 'animate_exit';
  exitActive: 'animate_exit_active';
  exitDone: 'animate_exit_done';
}

const animationClasses: animationClassesInterface = {
  appear: 'animate_appear',
  appearActive: 'animate_appear_active',
  appearDone: 'animate_appear_done',
  enter: 'animate_enter',
  enterActive: 'animate_enter_active',
  enterDone: 'animate_enter_done',
  exit: 'animate_exit',
  exitActive: 'animate_exit_active',
  exitDone: 'animate_exit_done',
};

export default animationClasses;
