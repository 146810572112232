import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import { useAppSelector } from 'app/hooks/reduxHooks';
import posthog from 'posthog-js';
import 'react-toastify/dist/ReactToastify.css';

const AuthorizedPage = React.lazy(() => import('components/common/Authorized'));
const UnAuthorizedPage = React.lazy(() => import('components/common/UnAuthorized'));

function App() {
  const { userToken } = useAppSelector((state) => state.login);
  const location = useLocation();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  return (
    <Suspense
      fallback={<SkeletonTheme baseColor="rgba(215, 232, 244, 0.05)" highlightColor="rgba(215, 232, 244, 1)" />}>
      {userToken ? <AuthorizedPage /> : <UnAuthorizedPage />}
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Suspense>
  );
}

export default App;
