import React from 'react';
import UserAvatar from 'components/pages/Account/components/PreviewUserAvatar/UserAvatar';
import DefaultUserAvatar from 'components/pages/Account/components/PreviewUserAvatar/DefaultUserAvatar';

export interface previewUserAva {
  userValues: {
    select_style: string;
    body_color: string;
    hair_style: number;
    avatar_background_color: string;
    hair_color: string;
    clothes: number;
    accessories: number;
  };
  customStyles?: string;
}

const PreviewUserAvatar = ({ customStyles, userValues }: previewUserAva) => {
  if (userValues.select_style === 'default') {
    return <DefaultUserAvatar customStyles={customStyles} />;
  }
  return <UserAvatar customStyles={customStyles} userValues={userValues} />;
};

export default PreviewUserAvatar;
