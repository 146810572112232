import React from 'react';

const ClothesStyle3 = () => {
  return (
    <>
      <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_8134_58400)">
        <path
          d="M146.693 127.056C146.831 126.992 146.995 127.051 147.07 127.182C148.52 129.696 152.01 129.478 153.088 127.219C153.158 127.072 153.332 126.998 153.48 127.065C158.593 129.382 160.278 132.032 161.113 137.495C161.138 137.664 161.007 137.818 160.836 137.818H139.17C138.998 137.818 138.867 137.664 138.893 137.494C139.796 131.749 141.875 129.285 146.693 127.056Z"
          fill="#ECFCCB"
        />
        <path
          d="M146.693 127.056C146.831 126.992 146.995 127.051 147.07 127.182C148.52 129.696 152.01 129.478 153.088 127.219C153.158 127.072 153.332 126.998 153.48 127.065C158.593 129.382 160.278 132.032 161.113 137.495C161.138 137.664 161.007 137.818 160.836 137.818H139.17C138.998 137.818 138.867 137.664 138.893 137.494C139.796 131.749 141.875 129.285 146.693 127.056Z"
          fill="#ECFCCB"
        />
        <path
          d="M154.763 127.692C153.303 131.129 147.178 131.368 145.718 127.648M146.693 127.056C146.831 126.992 146.995 127.051 147.07 127.182C148.52 129.696 152.01 129.478 153.088 127.219C153.158 127.072 153.332 126.998 153.48 127.065C158.593 129.382 160.278 132.032 161.113 137.495C161.138 137.664 161.007 137.818 160.836 137.818H139.17C138.998 137.818 138.867 137.664 138.893 137.494C139.796 131.749 141.875 129.285 146.693 127.056Z"
          stroke="#4D7C0F"
          strokeWidth="0.375062"
        />
        <path
          d="M152.969 131.68H157.394V134.195C157.394 135.417 156.403 136.408 155.181 136.408C153.959 136.408 152.969 135.417 152.969 134.195V131.68Z"
          fill="#ECFCCB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.019 132.055H153.344V134.195C153.344 135.21 154.166 136.033 155.181 136.033C156.196 136.033 157.019 135.21 157.019 134.195V132.055ZM152.969 131.68V134.195C152.969 135.417 153.959 136.408 155.181 136.408C156.403 136.408 157.394 135.417 157.394 134.195V131.68H152.969Z"
          fill="#4D7C0F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.193 133.189H153.17V132.814H157.193V133.189Z"
          fill="#4D7C0F"
        />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle3;
