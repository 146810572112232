export type PasswordValidatorType = {
  minChars: boolean;
  caseFormat: boolean;
  numberRequired: boolean;
  specialSymbol: boolean;
};

export function passwordValidator(password: string = ''): PasswordValidatorType {
  const minChars: 8 = 8;
  const caseFormat: RegExp = /^(?=.*[A-Z])(?=.*[a-z])[A-Za-z0-9#@$!%*?&^]+$/;
  const numberRequired: RegExp = /[0-9]/g;
  const specialSymbol: RegExp = /[!@#$%^&]/g;

  return {
    minChars: password.length >= minChars,
    caseFormat: caseFormat.test(password),
    numberRequired: numberRequired.test(password),
    specialSymbol: specialSymbol.test(password),
  };
}
