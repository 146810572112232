import React from 'react';

const HairStyle4 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.63 120.509C159.384 119.036 159.786 117.25 159.786 115.157C159.786 109.313 156.185 105 150.592 105C145 105 140.467 109.737 140.467 115.581C140.467 117.43 141.044 119.183 141.997 120.685C140.838 121.192 140.088 122.413 140.231 123.745C140.234 123.771 140.237 123.797 140.24 123.823C139.106 124.24 138.355 125.393 138.491 126.656C138.52 126.933 138.59 127.195 138.695 127.438L136.619 128.285C135.956 128.556 135.829 129.447 136.391 129.894L138.66 131.703C139.222 132.15 140.054 131.822 140.164 131.109L140.491 128.987C140.796 129.067 141.121 129.093 141.453 129.057C142.844 128.906 143.872 127.714 143.854 126.334C144.868 126.098 145.662 125.349 145.988 124.398C147.688 125.306 149.599 125.703 151.442 125.377C152.531 125.184 153.528 124.881 154.425 124.47C154.833 125.207 155.544 125.759 156.405 125.941C156.414 127.321 157.466 128.493 158.859 128.616C158.964 128.625 159.068 128.628 159.17 128.625L159.511 130.582C159.635 131.292 160.474 131.604 161.026 131.145L163.26 129.292C163.813 128.833 163.668 127.945 162.999 127.688L161.508 127.114C161.651 126.822 161.743 126.499 161.773 126.156C161.884 124.891 161.111 123.753 159.968 123.359C159.971 123.333 159.974 123.306 159.976 123.28C160.076 122.139 159.523 121.091 158.63 120.509Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle4;
