import React from 'react';

const HairStyle13 = ({ hairColor }) => {
  return (
    <path
      d="M143.814 116.721V112.689C143.814 110.204 145.829 108.188 148.315 108.188H151.927C154.394 108.188 156.4 110.173 156.428 112.639L156.472 116.669C156.134 115.495 155.789 114.741 155.071 114.058C154.399 113.419 153.365 113.538 152.573 114.021C150.774 115.115 149.399 115.184 147.622 114.134C146.748 113.617 145.587 113.518 144.969 114.324C144.468 114.976 144.18 115.702 143.814 116.721Z"
      fill={hairColor}
    />
  );
};

export default HairStyle13;
