import React, { useMemo, useRef } from 'react';
import styles from './LangSwitcherMenu.module.scss';
import { CSSTransition } from 'react-transition-group';
import animationClasses from 'features/animation';
import classNames from 'classnames/bind';

const LangSwitcherMenu = ({ langList = [], isVisible = false, changeLanguage, currentLang }) => {
  const nodeRef = useRef(null);
  const cn: Function = classNames.bind(styles);
  const getItemClasses = (lang) =>
    cn({
      item: true,
      is_active: lang === currentLang,
    });

  const menuItems = useMemo(() => {
    return langList.map((lang) => (
      <li className={getItemClasses(lang.value)} key={lang.value} onClick={() => changeLanguage(lang.value)}>
        <span className={styles.checkbox} /> {lang.label}
      </li>
    ));
  }, [langList, changeLanguage, currentLang]);

  return (
    <CSSTransition in={isVisible} timeout={100} classNames={animationClasses} unmountOnExit nodeRef={nodeRef}>
      <ul className={styles.menu} ref={nodeRef}>
        {menuItems}
      </ul>
    </CSSTransition>
  );
};

export default LangSwitcherMenu;
