import { useState, useEffect } from 'react';

type viewport = number | 'mobile' | 'tablet' | 'laptop' | 'desktop';
export const useViewportCheck = (min: viewport, max?: viewport): boolean => {
  const defaultViewports = {
    mobile: { start: 320, end: 767 },
    tablet: { start: 768, end: 1023 },
    laptop: { start: 1024, end: 1439 },
    desktop: { start: 1440, end: 1920 },
  };

  function handleWindowSizeChange() {
    setViewportMatch(checkViewportMatch());
  }

  function checkViewportMatch() {
    const width = window.innerWidth;

    if (typeof min === 'number' && !max) return width <= min;

    if (typeof min === 'string' && !max) {
      return width >= defaultViewports[min]?.start && width <= defaultViewports[min]?.end;
    }

    if (typeof min === 'number' && typeof max === 'number') {
      return width >= min && width <= max;
    }

    if (typeof min === 'number' && typeof max === 'string') {
      return width >= min && width < defaultViewports[max]?.start;
    }

    if (typeof min === 'string' && typeof max === 'string') {
      return width >= defaultViewports[min]?.start && width < defaultViewports[max]?.start;
    }

    if (typeof min === 'string' && typeof max === 'number') {
      return width >= defaultViewports[min]?.start && width <= max;
    }

    return false;
  }

  const [isViewportMatch, setViewportMatch] = useState<boolean>(checkViewportMatch());

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isViewportMatch;
};
