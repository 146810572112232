import React from 'react';

const BangStyle2_3_4_5_6_7_8_9 = ({ userColor }) => {
  return (
    <>
      <path
        d="M180.36 68C158.909 110.401 141.226 119.762 94.9532 124C86.8256 67.7882 97.0852 50.1624 145.887 39.4097C161.639 35.939 178.335 37.0566 192.836 44.1203C227.093 60.8075 228.301 80.6788 229.444 124C195.51 114.149 192.478 99.8026 180.36 68Z"
        fill={userColor}
      />
    </>
  );
};

export default BangStyle2_3_4_5_6_7_8_9;
