import React from 'react';
import AccessoriesStyle1 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style1';
import AccessoriesStyle2 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style2';
import AccessoriesStyle3 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style3';
import AccessoriesStyle4 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style4';
import AccessoriesStyle5 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style5';
import AccessoriesStyle6 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style6';
import AccessoriesStyle7 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style7';
import AccessoriesStyle8 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style8';
import AccessoriesStyle9 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderAccessoriesStyle/style9';

const RenderAccessoriesStyleAvatar = ({ accessories }) => {
  switch (accessories) {
    case 1:
      return <></>;
    case 2:
      return <AccessoriesStyle1 />;
    case 3:
      return <AccessoriesStyle2 />;
    case 4:
      return <AccessoriesStyle3 />;
    case 5:
      return <AccessoriesStyle4 />;
    case 6:
      return <AccessoriesStyle5 />;
    case 7:
      return <AccessoriesStyle6 />;
    case 8:
      return <AccessoriesStyle7 />;
    case 9:
      return <AccessoriesStyle8 />;
    case 10:
      return <AccessoriesStyle9 />;

    default:
      return <></>;
  }
};

export default RenderAccessoriesStyleAvatar;
