import React from 'react';
import styles from '../Modal.module.scss';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';

const AccessPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(modalClose());
    navigate('/');
  };

  return (
    <div className={styles.access_password}>
      <div className={styles.modal_title}>
        To complete your account registration, please check your email and click the link in the email.
      </div>
      <div className={'button button_fill'} onClick={closeModal}>
        <div className={styles.title}>Check Your Email</div>
      </div>
    </div>
  );
};

export default AccessPassword;
