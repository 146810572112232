import React from 'react';

const HairStyle1 = ({ hairColor }) => {
  return (
    <>
      <path
        d="M142.923 113.795C142.769 111.725 143.345 110.653 145.08 110.231C145.08 110.231 150.237 105.121 154.785 108.497C159.332 111.872 156.519 117.611 156.519 117.611C156.332 114.611 154.969 115.866 155.066 114.029C151.142 115.208 149.507 114.859 147.33 112.435C147.047 114 145.596 113.72 144.47 114.376C144.672 115.531 144.236 117.002 143.908 117.33C142.861 116.182 143.038 115.717 142.923 113.795Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle1;
