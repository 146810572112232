import React from 'react';
import './Button.scss';
import Spinner from 'components/common/Spinner/Spinner';

const Button = ({ children, isLoading = false, ...props }) => {
  return (
    <button {...props}>
      <span style={{ opacity: isLoading ? 0 : 1, position: isLoading ? 'absolute' : 'relative' }}>{children}</span>
      {isLoading && <Spinner />}
    </button>
  );
};
export default Button;
