import React from 'react';

const ClothesStyle6 = () => {
  return (
    <>
      <path
        d="M125.543 237.032C126 236.821 126.666 236.989 126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1514 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032Z"
        fill="white"
        stroke="#5B21B6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.6076 265.84H238.5L240.5 267.5L245.5 273L249.5 277.5L252.5 281.909H67.6076L74.1076 273.5L81.6076 265.84Z"
        fill="#C084FC"
      />
      <path d="M61.5 293.5L259 293L263 301L265.945 309.026H54.7937L57.5 301L61.5 293.5Z" fill="#C084FC" />
      <path d="M51 321.078H269.5L273 337.147H47L51 321.078Z" fill="#C084FC" />
    </>
  );
};

export default ClothesStyle6;
