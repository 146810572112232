import React from 'react';

const AccessoriesStyle11 = () => {
  return (
    <>
      <circle cx="164" cy="147" r="1.95" fill="url(#paint0_linear_4669_50226)" stroke="#C4C4C4" strokeWidth="0.1" />
      <defs>
        <linearGradient
          id="paint0_linear_4669_50226"
          x1="164"
          y1="145"
          x2="164"
          y2="149"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E5E6E9" />
          <stop offset="1" stopColor="#C4C4C4" />
        </linearGradient>
      </defs>
    </>
  );
};

export default AccessoriesStyle11;
