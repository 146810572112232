import React from 'react';

const AccessoriesStyle8 = () => {
  return (
    <>
      <path d="M156.938 120.71L157.125 120.669V122.156H156.938V120.71Z" fill="#CBD5E1" />
      <path
        d="M156.517 123.704C156.354 123.61 156.298 123.402 156.393 123.24L156.443 123.153H156.341C156.152 123.153 156 123.001 156 122.813C156 122.625 156.152 122.473 156.341 122.473H156.443L156.393 122.385C156.298 122.223 156.354 122.015 156.517 121.921C156.68 121.827 156.888 121.882 156.982 122.045L157.033 122.132L157.083 122.045C157.177 121.882 157.386 121.827 157.548 121.921C157.711 122.015 157.767 122.223 157.673 122.385L157.623 122.473H157.722C157.91 122.473 158.063 122.625 158.063 122.813C158.063 123.001 157.91 123.153 157.722 123.153H157.623L157.673 123.24C157.767 123.402 157.711 123.61 157.548 123.704C157.386 123.798 157.177 123.743 157.083 123.58L157.033 123.493L156.982 123.58C156.888 123.743 156.68 123.798 156.517 123.704Z"
        fill="#CBD5E1"
      />
      <circle cx="157.031" cy="119.812" r="0.28125" fill="#CBD5E1" />
      <path d="M143.344 120.71L143.156 120.669V122.156H143.344V120.71Z" fill="#CBD5E1" />
      <path
        d="M143.764 123.704C143.927 123.61 143.983 123.402 143.889 123.24L143.838 123.153H143.941C144.129 123.153 144.281 123.001 144.281 122.813C144.281 122.625 144.129 122.473 143.941 122.473H143.838L143.889 122.385C143.983 122.223 143.927 122.015 143.764 121.921C143.601 121.827 143.393 121.882 143.299 122.045L143.248 122.132L143.198 122.045C143.104 121.882 142.896 121.827 142.733 121.921C142.57 122.015 142.514 122.223 142.608 122.385L142.659 122.473H142.559C142.371 122.473 142.219 122.625 142.219 122.813C142.219 123.001 142.371 123.153 142.559 123.153H142.658L142.608 123.24C142.514 123.402 142.57 123.61 142.733 123.704C142.896 123.798 143.104 123.743 143.198 123.58L143.248 123.493L143.299 123.58C143.393 123.743 143.601 123.798 143.764 123.704Z"
        fill="#CBD5E1"
      />
      <circle cx="0.28125" cy="0.28125" r="0.28125" transform="matrix(-1 0 0 1 143.531 119.531)" fill="#CBD5E1" />
    </>
  );
};

export default AccessoriesStyle8;
