import React from 'react';

const BangStyle18 = () => {
  return (
    <>
      <path
        d="M105.901 102.174C98.465 179.514 68.5 164.5 61 247C52.8841 245.041 21.4008 183.514 58.4008 119.514C95.4008 55.514 93.9008 11.0141 163.401 10.0141C232.901 9.01405 244.133 61.514 270.517 119.514C296.901 177.514 275.13 243.123 266.5 246C266.5 175 221.901 176.514 223.401 102.174C175.527 79.6956 149.444 79.5459 105.901 102.174Z"
        fill="#323232"
      />
      <path
        d="M71.4004 70.2732C71.4004 65.1709 75.1575 60.8306 80.2106 60.1236C144.524 51.1251 184.203 51.486 248.491 60.1593C253.593 60.8476 257.4 65.2182 257.4 70.366C257.4 76.7343 251.681 81.5792 245.385 80.6208C183.14 71.1451 143.972 70.7393 83.4827 80.4838C77.1739 81.5001 71.4004 76.6633 71.4004 70.2732Z"
        fill="#1A1A1A"
      />
    </>
  );
};

export default BangStyle18;
