import React from 'react';

const ClothesStyle1 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8134_58100)">
        <path
          d="M146.693 127.056C146.831 126.992 146.995 127.051 147.071 127.182C148.52 129.696 152.01 129.478 153.088 127.219C153.158 127.072 153.332 126.998 153.481 127.065C158.593 129.382 160.279 132.032 161.113 137.495C161.139 137.664 161.007 137.818 160.836 137.818H139.17C138.998 137.818 138.867 137.664 138.894 137.494C139.796 131.749 141.875 129.285 146.693 127.056Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.78 127.429C144.436 128.521 142.794 129.655 141.623 131.185C140.455 132.709 139.729 134.662 139.282 137.443H160.725C160.312 134.791 159.696 132.862 158.596 131.318C157.494 129.771 155.879 128.573 153.398 127.439C152.159 129.916 148.379 130.102 146.78 127.429ZM147.071 127.182C146.995 127.051 146.831 126.992 146.693 127.056C141.875 129.285 139.796 131.749 138.894 137.494C138.867 137.664 138.998 137.818 139.17 137.818H160.836C161.007 137.818 161.139 137.664 161.113 137.495C160.279 132.032 158.593 129.382 153.481 127.065C153.332 126.998 153.158 127.072 153.088 127.219C152.01 129.478 148.52 129.696 147.071 127.182Z"
          fill="#323232"
        />
      </g>
      <rect x="132.375" y="102.375" width="35.25" height="35.25" rx="17.625" stroke="#E8F6FF" strokeWidth="0.75" />
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle1;
