import React from 'react';

const BangStyle12 = ({ userColor }) => {
  return (
    <>
      <path
        d="M180.36 69C158.909 111.401 141.226 120.762 94.9532 125C86.8256 68.7882 97.0852 51.1624 145.887 40.4097C161.639 36.939 178.335 38.0566 192.836 45.1203C227.093 61.8075 228.301 81.6788 229.444 125C195.51 115.149 192.478 100.803 180.36 69Z"
        fill={userColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.376 245C221.261 245 267.376 188.14 267.376 118C267.376 47.8598 221.261 0 164.376 0C107.491 0 61.376 47.8598 61.376 118C61.376 188.14 107.491 245 164.376 245ZM162.376 189C199.379 189 229.376 156.988 229.376 117.5C229.376 78.0116 199.379 46 162.376 46C125.373 46 95.376 78.0116 95.376 117.5C95.376 156.988 125.373 189 162.376 189Z"
        fill="#323232"
      />
      <g filter="url(#filter0_d_4723_50805)">
        <path
          d="M97.9309 196C83.6298 196 72.0365 210.327 72.0365 228.001C72.0365 230.868 72.3439 233.657 72.9223 236.315C68.2307 242.769 60.0841 246.5 61.0842 261.5C62.0842 276.5 93.5842 329.554 117.584 333C175.982 341.386 174.56 301.958 223.084 294C237.605 291.619 264.084 279.102 264.084 261C264.084 250.612 257.908 241.396 252.324 234.683C252.645 232.654 252.813 230.562 252.813 228.429C252.813 210.519 241.065 196 226.572 196H97.9309Z"
          fill="#323232"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4723_50805"
          x="49"
          y="186"
          width="227.084"
          height="162.163"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.137255 0 0 0 0 0.490196 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4723_50805" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4723_50805" result="shape" />
        </filter>
      </defs>
    </>
  );
};

export default BangStyle12;
