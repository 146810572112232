import React from 'react';

const ClothesStyle8 = () => {
  return (
    <>
      <path
        d="M127.178 238.531C127.637 238.32 128.302 238.489 128.634 239.065C144.838 267.229 182.671 263.433 194.721 237.814C195.044 237.129 195.786 236.921 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8785 348 43.4065 347.456 43.5016 346.855C48.3088 316.449 56.5623 295.298 69.8324 278.826C83.1152 262.338 101.577 250.345 127.178 238.531Z"
        fill="white"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M256.063 284.906C258.52 287.879 260 291.72 260 295.913C260 305.35 252.503 313 243.254 313C238.712 313 234.592 311.155 231.575 308.158C228.558 311.155 224.438 313 219.896 313C215.353 313 211.233 311.155 208.217 308.158C205.2 311.155 201.08 313 196.537 313C191.995 313 187.875 311.155 184.858 308.158C181.841 311.155 177.721 313 173.179 313C168.637 313 164.517 311.155 161.5 308.158C158.483 311.155 154.363 313 149.821 313C145.278 313 141.158 311.155 138.142 308.158C135.125 311.155 131.005 313 126.462 313C121.92 313 117.8 311.155 114.783 308.158C111.767 311.155 107.647 313 103.104 313C98.5617 313 94.4417 311.155 91.425 308.158C88.4083 311.155 84.2883 313 79.7458 313C70.4974 313 63 305.35 63 295.913C63 291.511 64.631 287.499 67.3101 284.469C83.4208 264.64 100.818 249.056 126.462 241C149.263 270 179.122 269.5 197.869 241C229.997 253.351 244.879 267.77 256.063 284.906Z"
        fill="#91DDB0"
      />
      <path
        d="M251.283 274.906C253.602 277.879 255 281.72 255 285.913C255 295.35 247.921 303 239.189 303C234.9 303 231.01 301.155 228.162 298.158C225.314 301.155 221.424 303 217.135 303C212.846 303 208.956 301.155 206.108 298.158C203.26 301.155 199.37 303 195.081 303C190.792 303 186.902 301.155 184.054 298.158C181.206 301.155 177.316 303 173.027 303C168.738 303 164.848 301.155 162 298.158C159.152 301.155 155.262 303 150.973 303C146.684 303 142.794 301.155 139.946 298.158C137.098 301.155 133.208 303 128.919 303C124.63 303 120.74 301.155 117.892 298.158C115.044 301.155 111.154 303 106.865 303C102.576 303 98.6861 301.155 95.8378 298.158C92.9895 301.155 89.0996 303 84.8108 303C76.0787 303 69 295.35 69 285.913C69 281.512 70.54 277.499 73.0694 274.47C88.2805 254.64 105.541 245.556 129.754 237.5C151.808 266 175.678 265.5 193.378 237C223.712 249.351 240.723 257.77 251.283 274.906Z"
        fill="#323232"
      />
    </>
  );
};

export default ClothesStyle8;
