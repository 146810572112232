import React, { useState, useEffect } from 'react';
import { ReactComponent as Star } from 'assets/images/starGray.svg';
import { ReactComponent as StarSelected } from 'assets/images/starYellow.svg';

export interface starRatingInterface {
  handleRate: React.Dispatch<React.SetStateAction<number>>;
}

const StarRating = (props) => {
  return (
    <div>
      {Array(5)
        .fill(0)
        .map((_, idx) => (
          <label key={idx}>
            <input
              hidden
              type="radio"
              name="rating"
              onChange={() => props.setRating(idx)}
              value={props.ratingValue}
              checked={idx === props.ratingValue}
            />
            {idx <= props.ratingValue ? <StarSelected /> : <Star />}
          </label>
        ))}
    </div>
  );
};

export const RatingContainer = ({ handleRate }: starRatingInterface) => {
  const [rate, setRate] = useState(-1);

  useEffect(() => {
    handleRate(rate + 1);
  }, [rate]);

  return (
    <div>
      <StarRating setRating={(val) => setRate(val)} ratingValue={rate} />
    </div>
  );
};
