import React from 'react';

const SidebarPattern3 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8055_37070)">
        <g clipPath="url(#clip1_8055_37070)">
          <path d="M-2.46443 -44L614.001 572.463V575.01L-5.01172 -44H-2.46443Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M49.0082 -44L614 520.99V523.537L46.4609 -44H49.0082Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M100.483 -44L614 469.517V472.064L97.9357 -44H100.483Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-53.9379 -44L614 623.938V626.485L-56.4852 -44H-53.9379Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-105.411 -44L614 675.411V677.958L-107.96 -44H-105.411Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-156.884 -44L614 726.884V729.431L-159.433 -44H-156.884Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-208.359 -44L614 778.357V780.906L-210.906 -44H-208.359Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-259.834 -44L614 829.832V832.379L-262.381 -44H-259.834Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-311.306 -44L614 881.305V883.852L-313.854 -44H-311.306Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-362.78 -44L614 932.778V935.325L-365.327 -44H-362.78Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-414.253 -44L614 984.252V986.8L-416.8 -44H-414.253Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M614 1037H612.725L-467 -42.7254V-44H-465.725L614 1035.73V1037Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M561.252 1037L-467 8.7465V6.19922L563.8 1037H561.252Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M509.78 1037L-467 60.2211V57.6738L512.327 1037H509.78Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M458.305 1037L-467 111.694V109.146L460.852 1037H458.305Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M406.832 1037L-467 163.168V160.621L409.379 1037H406.832Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M355.357 1037L-467 214.641V212.094L357.906 1037H355.357Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M303.884 1037L-467 266.116V263.566L306.431 1037H303.884Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M252.411 1037L-467 317.588V315.041L254.958 1037H252.411Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M200.938 1037L-467 369.063V366.516L203.485 1037H200.938Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M149.463 1037L-467 420.536V417.988L152.012 1037H149.463Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M97.9902 1037L-467 472.01V469.461L100.537 1037H97.9902Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M46.5171 1037L-467 523.483V520.936L49.0644 1037H46.5171Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M23.6596 -44L614 546.339V548.886L21.1123 -44H23.6596Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M75.1332 -44L613.999 494.866V497.413L72.584 -44H75.1332Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M126.607 -44L614 443.393V445.94L124.06 -44H126.607Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-27.814 -44L614 597.814V600.361L-30.3613 -44H-27.814Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-79.2868 -44L614 649.287V651.834L-81.8359 -44H-79.2868Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-130.762 -44L614 700.76V703.307L-133.309 -44H-130.762Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-182.235 -44L614 752.233V754.782L-184.782 -44H-182.235Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-233.707 -44L614.001 803.706V806.255L-236.256 -44H-233.707Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-285.18 -44L614 855.181V857.728L-287.73 -44H-285.18Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-336.656 -44L614 906.655V909.203L-339.203 -44H-336.656Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-388.129 -44L614 958.128V960.676L-390.676 -44H-388.129Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M-439.601 -44L614 1009.6V1012.15L-442.15 -44H-439.601Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M587.377 1037L-467 -17.3766V-19.9238L589.924 1037H587.377Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M535.904 1037L-467 34.0961V31.5488L538.451 1037H535.904Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M484.429 1037L-467 85.5688V83.0215L486.976 1037H484.429Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M432.956 1037L-467 137.043V134.496L435.503 1037H432.956Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M381.483 1037L-467 188.516V185.969L384.03 1037H381.483Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M330.008 1037L-467 239.991V237.443L332.555 1037H330.008Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M278.535 1037L-467 291.465V288.918L281.082 1037H278.535Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M227.062 1037L-467 342.938V340.391L229.609 1037H227.062Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M175.587 1037L-467 394.411V391.863L178.136 1037H175.587Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M124.114 1037L-467 445.885V443.338L126.661 1037H124.114Z" fill="#4F88B3" fillOpacity="0.1" />
          <path d="M72.6412 1037L-467 497.358V494.811L75.1885 1037H72.6412Z" fill="#4F88B3" fillOpacity="0.1" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8055_37070">
          <rect width="248" height="1080" fill="white" transform="translate(0 -38)" />
        </clipPath>
        <clipPath id="clip1_8055_37070">
          <rect width="257" height="1081" fill="white" transform="translate(-5 -44)" />
        </clipPath>
      </defs>
    </>
  );
};

export default SidebarPattern3;
