import React from 'react';

const HairStyle11 = ({ hairColor }) => {
  return (
    <>
      <path
        d="M143.72 114.859C143.566 112.789 145.549 108.419 147.283 107.997C147.283 107.997 152.44 105.14 155.043 109.966C157.645 114.793 156.238 119.394 156.238 119.394C156.238 118.226 156.613 117.003 155.394 115.127C152.206 115.127 149.159 115.877 147.377 114.19C146.861 114.859 145.009 115.784 144.658 115.784C143.769 116.815 144.47 118.972 143.955 119.253C143.415 117.94 143.835 116.781 143.72 114.859Z"
        fill={hairColor}
      />
      <g filter="url(#filter0_d_8170_60153)">
        <path
          d="M143.824 111.709C143.87 109.257 145.871 107.294 148.324 107.294H151.897C154.338 107.294 156.334 109.24 156.396 111.681L156.472 114.701H143.767L143.824 111.709Z"
          fill="white"
        />
        <path
          d="M144.011 111.713C144.056 109.363 145.974 107.481 148.324 107.481H151.897C154.236 107.481 156.149 109.347 156.208 111.686L156.28 114.514H143.958L144.011 111.713Z"
          stroke="#323232"
          strokeWidth="0.375062"
        />
      </g>
      <path
        d="M147.377 110.388C147.377 108.835 148.636 107.575 150.19 107.575C151.744 107.575 153.003 108.835 153.003 110.388V114.514H147.377V110.388Z"
        fill="#323232"
        fillOpacity="0.38"
        stroke="#323232"
        strokeWidth="0.375062"
      />
      <path d="M140.72 114.732C140.72 114.524 140.888 114.356 141.095 114.356H156.379V114.732H140.72Z" fill="#323232" />

      <defs>
        <filter
          id="filter0_d_8170_60153"
          x="143.392"
          y="107.294"
          width="13.4552"
          height="8.15759"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.375062" />
          <feGaussianBlur stdDeviation="0.187531" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0916667 0 0 0 0 0.0916667 0 0 0 0 0.0916667 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8170_60153" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8170_60153" result="shape" />
        </filter>
      </defs>
    </>
  );
};

export default HairStyle11;
