import React from 'react';

const ClothesStyle5 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59334)">
        <path
          d="M146.772 127.225C146.815 127.205 146.877 127.221 146.908 127.275C148.428 129.911 152.11 129.704 153.257 127.299C153.288 127.234 153.358 127.215 153.403 127.235C155.942 128.386 157.61 129.61 158.748 131.208C159.888 132.809 160.512 134.803 160.927 137.523C160.936 137.579 160.892 137.63 160.836 137.63H139.17C139.114 137.63 139.07 137.579 139.079 137.523C139.527 134.667 140.265 132.649 141.474 131.07C142.683 129.491 144.376 128.334 146.772 127.225Z"
          fill="white"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M142.517 129.971H157.583" stroke="#1E293B" strokeWidth="0.375062" />
        <path d="M140.916 131.949H159.183" stroke="#1E293B" strokeWidth="0.375062" />
        <path d="M139.974 133.926H160.031" stroke="#1E293B" strokeWidth="0.375062" />
        <path d="M139.221 135.904H160.596" stroke="#1E293B" strokeWidth="0.375062" />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle5;
