import { languagesList } from 'additionalInfo/languageList';

export const customGoogleTranslations = async (textToTranslate: string, language: string, setupHook: Function) => {
  const { REACT_APP_TRANSLATION_API_KEY } = process.env;
  try {
    const response = await fetch(
      `https://translation.googleapis.com/language/translate/v2?q=${encodeURIComponent(
        textToTranslate
      )}&target=${language}&key=${REACT_APP_TRANSLATION_API_KEY}`
    );
    const json = await response.json();
    if (!json.data || !json.data.translations || !json.data.translations.length) return;
    const translation = json.data.translations[0].translatedText;
    setupHook(translation);
  } catch (err) {
    console.error(err);
  }
};

export const getLanguage = () => {
  // https://cloud.google.com/translate/docs/languages
  /* eslint-disable */
  let navigatorLanguage =
    navigator.language === 'zh-CN' || navigator.language === 'zh-TW' || navigator.language.indexOf('-') === -1
      ? navigator.language
      : navigator.language.substr(0, navigator.language.indexOf('-'));
  /* eslint-enable */
  return languagesList.find((language) => language.value === navigatorLanguage);
};
