import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { entryApi } from 'app/entryApi';
import { RootState } from 'app/store';

export interface UserStoryState {
  title?: string;
  description?: string;
  country?: string;
  gallery?: Array<any>;
  gallery_to_remove?: Array<any>;
  story_id?: string;
  storyViewedFromExplore: boolean;
}

const initialState: UserStoryState = {
  title: '',
  description: '',
  country: '',
  gallery: [],
  gallery_to_remove: [],
  story_id: '',
  storyViewedFromExplore: false,
};

export const userModuleStorySlice = createSlice({
  name: 'userModuleStory',
  initialState,
  reducers: {
    setUserStoryTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setUserStoryDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setUserStoryGallery: (state, action: PayloadAction<Array<any>>) => {
      state.gallery = action.payload;
    },
    setUserStoryGalleryToRemove: (state, action: PayloadAction<Array<any>>) => {
      state.gallery_to_remove = action.payload;
    },
    setUserStoryId: (state, action: PayloadAction<string>) => {
      state.story_id = action.payload;
    },
    setIsStoryViewedFromExplore: (state, action: PayloadAction<boolean>) => {
      state.storyViewedFromExplore = action.payload;
    },
    setUserStoryCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
  },
});

export const {
  setUserStoryId,
  setUserStoryTitle,
  setUserStoryDescription,
  setUserStoryGallery,
  setUserStoryGalleryToRemove,
  setIsStoryViewedFromExplore,
  setUserStoryCountry,
} = userModuleStorySlice.actions;

export const getUserStoryDataState = (state: RootState) => state.userModuleStory;

export default userModuleStorySlice.reducer;

interface ModuleStoryPayload {
  moduleId?: string;
  title?: string;
  country?: string;
  description?: string;
  ready_to_review?: boolean;
}

interface ModuleStory {
  coordinates: any;
  country: string;
  course: any;
  description: string;
  documentInStages: any;
  fullName: string;
  id: string;
  module: any;
  publishedAt: any;
  readyToReview: boolean;
  stage: string;
  storyMediaStack: any;
  storyStatus: string;
  tags: any;
  title: string;
  userId: string;
}
interface ModuleStoryResponse {
  stories?: Array<ModuleStory>;
}

export const storiesApi = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    saveStory: builder.mutation<any, ModuleStoryPayload>({
      query(data) {
        const { moduleId, ...updatedData } = data;
        return {
          url: `/module/${moduleId}/story`,
          method: 'POST',
          body: updatedData,
        };
      },
      invalidatesTags: ['GET_SINGLE_MODULE_STORY'],
    }),
    getUserModuleStory: builder.query({
      query: (moduleId) => ({ url: `/module/${moduleId}/story` }),
      transformResponse: (data: ModuleStoryResponse) => {
        if (data?.stories[0]?.storyStatus === 'rejected') {
          data.stories[0].readyToReview = false;
        }
        return data;
      },
      providesTags: () => ['GET_SINGLE_MODULE_STORY'],
    }),
    getModuleStories: builder.query({
      query: ({ moduleId, first, skip }) => {
        let baseUrl = `/module/${moduleId}/stories?`;

        if (typeof first === 'number') {
          baseUrl = `${baseUrl}first=${first}&`;
          return { url: baseUrl };
        }

        if (typeof skip === 'number') {
          return { url: `${baseUrl}skip=${skip}` };
        }

        return { url: `/module/${moduleId}/stories` };
      },
      transformResponse: (data) => data,
      providesTags: () => ['GET_MODULE_STORIES'],
    }),
    loadMoreStories: builder.query({
      query: ({ moduleId, first, skip }) => {
        const pagination: string = `first=${first}&skip=${skip}`;

        return { url: `/module/${moduleId}/stories?${pagination}` };
      },
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const originalParams = { ...params };
          delete originalParams.skip;

          dispatch(
            storiesApi.util.updateQueryData('getModuleStories', originalParams, (storeStories) => {
              // @ts-ignore
              storeStories.stories.push(...data.stories);
            })
          );
        } catch {
          console.error('Error loading more stories');
        }
      },
    }),
    getModuleStoryById: builder.mutation<any, ModuleStoryPayload>({
      query(id) {
        return {
          url: `/story/${id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['GET_SINGLE_MODULE_STORY'],
    }),
  }),
});

export const { useSaveStoryMutation, useGetModuleStoryByIdMutation } = storiesApi;
