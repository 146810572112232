import React, { MouseEventHandler, MutableRefObject } from 'react';
import { StoryCard } from 'components/pages/SingleCourse/StoryMap/StoryCard/StoryCard';
import StoryPreview from 'components/pages/SingleCourse/StoryMap/StoryPreview';
import styles from './StoryList.module.scss';

interface propsInterface {
  latLng: string;
  data: any;
  resizedMap?: boolean;
  refStoryList: MutableRefObject<any>;
  setCurrentOpenedStory: Function;
  type?: 'mobile' | 'full_map' | 'default';
  // setIsSingleStoryModalOpen: Function;
  handleClose?: MouseEventHandler<HTMLElement | SVGElement>;
}

interface story {
  id: string;
  title: string;
}

const StoryList = ({ latLng, data, resizedMap, refStoryList, type, handleClose }: propsInterface) => {
  const storiesPerCountry = data[latLng];
  const storiesList = storiesPerCountry ? [...storiesPerCountry.ids] : [];

  return (
    <StoryPreview resizedMap={resizedMap} refPr={refStoryList} type={type} handleClose={handleClose}>
      {storiesList.map((story: story) => (
        <StoryCard id={story.id} type={'list'} className={styles.storycard} key={story.id} />
      ))}
    </StoryPreview>
  );
};

export default StoryList;
