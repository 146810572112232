import React from 'react';
import styles from '../Customize.module.scss';
import { selectStylesOptions } from 'additionalInfo/index';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames/bind';
import { setUserAccountData } from 'features/userAccount/userAccountSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const SelectStyleAvatar = ({ initialValues, handleChange }) => {
  const dispatch = useAppDispatch();
  const handleChangeBodyColor = (event, handleChange) => {
    handleChange(event);
    let spredValues = {
      ...initialValues,
      select_style: event.target.value,
    };
    dispatch(setUserAccountData({ userSiteSettings: spredValues }));
  };
  const cn: Function = classNames.bind(styles);
  const selectStylesColorStyle = (compare: boolean) =>
    cn({
      select_style_color_item: true,
      active: compare,
    });

  return (
    <>
      <div className={`${styles.select_style_box_wrap}`} role="group" aria-labelledby="select-style-avatar">
        {selectStylesOptions.map((singleItem) => (
          <label className={selectStylesColorStyle(initialValues.select_style === singleItem.value)} key={nanoid()}>
            <input
              value={singleItem.value}
              name="select_style"
              type="radio"
              className={`${styles.select_style_input}`}
              checked={initialValues.select_style === singleItem.value}
              onChange={(event) => handleChangeBodyColor(event, handleChange)}
            />
            <div className={'select_style_option'}>{singleItem.label}</div>
          </label>
        ))}
      </div>
    </>
  );
};

export default SelectStyleAvatar;
