import React from 'react';

const HairStyle18 = () => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M144.929 114.581C144.232 121.832 141.423 120.425 140.719 128.16C139.958 127.976 137.006 122.207 140.476 116.206C143.945 110.205 143.804 106.033 150.321 105.939C156.838 105.845 157.891 110.768 160.365 116.206C162.839 121.645 160.797 127.797 159.988 128.066C159.988 121.409 155.806 121.551 155.947 114.581C151.458 112.473 149.012 112.459 144.929 114.581Z"
        fill="#323232"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M161.181 122.018C161.275 127.222 161.181 130.879 151.148 131.442C144.552 131.811 139.005 131.535 139.99 122.018C139.99 116.762 144.734 112.501 150.585 112.501C156.437 112.501 161.087 116.814 161.181 122.018Z"
        fill="#1A1A1A"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M141.694 111.589C141.694 111.111 142.047 110.704 142.52 110.638C148.551 109.794 152.271 109.828 158.299 110.641C158.778 110.705 159.135 111.115 159.135 111.598C159.135 112.195 158.598 112.649 158.008 112.56C152.172 111.671 148.499 111.633 142.827 112.547C142.236 112.642 141.694 112.188 141.694 111.589Z"
        fill="#1A1A1A"
      />
    </>
  );
};

export default HairStyle18;
