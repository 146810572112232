import React from 'react';

const BangStyle14 = ({ userColor }) => {
  return (
    <>
      <path
        d="M109.621 99.4886C106.652 104.865 102.501 107.83 96 112.006L103.5 49.0056C103.5 49.0056 116.5 30.5056 155 30.0056C193.5 29.5056 229.5 62.5056 229.5 62.5056V113.006C215.304 110.473 204.246 107.926 198.23 97.9942C194.279 91.4729 185.671 87.1819 179.498 91.6573C175.947 94.2313 171.121 94.1409 167.669 91.4357L162.505 87.3878C158.111 83.9438 151.922 83.9851 147.574 87.4874L143.292 90.9367C139.488 94.001 134.207 94.4538 129.937 92.0816L129.169 91.6553C121.976 87.6592 113.598 92.2854 109.621 99.4886Z"
        fill={userColor}
      />
    </>
  );
};

export default BangStyle14;
