import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import StoryFilter from 'components/pages/SingleCourse/StoryFilter';
import StoryPreview from 'components/pages/SingleCourse/StoryMap/StoryPreview';
import React, { useState, useRef, useEffect } from 'react';
import styles from '../Map.module.scss';
import marker from 'assets/icons/map/marker-1.svg';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import classNames from 'classnames/bind';
import { localizationSite } from 'features/localization/localizationSlice';
import { storiesApi } from 'features/moduleStory/moduleStory';
import { modalClose, modalOpen } from 'features/modal/modalSlice';
import { userInfo } from 'features/userInfo/userInfoSlice';
import PageHeader from 'components/PageHeader/PageHeader';
import RandomStory from 'components/common/Navbar/NavHeader/RandomStory';
// import LangSwitcher from 'components/LangSwitcher/LangSwitcher';
import { modulesApi } from 'features/modules/modulesApi';
import constants from 'features/constants';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';
import { StoryCard } from 'components/pages/SingleCourse/StoryMap/StoryCard/StoryCard';
import StoryList from 'components/pages/SingleCourse/StoryMap/StoryList';

const FullModuleMap = () => {
  const { REACT_APP_MAPTILER } = process.env;
  const mapteiler = JSON.parse(REACT_APP_MAPTILER);
  const dispatch = useAppDispatch();
  const setAnalyticsLogger = useAnalyticsLogger();
  const cn = classNames.bind(styles);
  const mapClasses = cn({ story_map_wrap: true });
  const userToken = localStorage.getItem('userToken');
  const getUserAllInfo = userInfo.useGetUserInfoRequestQuery(userToken);
  const { module_id: moduleId } = useAppSelector((state) => state.currentModule);
  const lang = useAppSelector((state) => state.login.acceptLanguage);

  const { data: getlocalizationInfo, isLoading: loadTranslations } = localizationSite.useGetTranslationsQuery(lang);
  const { data: moduleStoriesData }: any = storiesApi.useGetModuleStoriesQuery({ moduleId });
  const { data: moduleInfo } = modulesApi.useGetSingleModuleQuery(moduleId);

  const [openStory, setOpenStory] = useState(false);
  const [openStoryList, setOpenStoryList] = useState(null);
  const [currentOpenedStory, setCurrentOpenedStory] = useState('');
  const [currentFilterOption, setCurrentFilterOption] = useState('students');
  const [markersOnMap, setMarkersOnMap] = useState({});

  let mapLng = 1.28;
  let mapLat = 53.1;

  const locationParse = JSON.parse(getUserAllInfo?.data?.location);

  if (locationParse.city) {
    mapLng = locationParse.city.value.longitude;
    mapLat = locationParse.city.value.latitude;
  } else if (locationParse.country) {
    mapLng = locationParse.country.value.longitude;
    mapLat = locationParse.country.value.latitude;
  }

  const fullMapContainer = useRef(null);
  const map = useRef(null);
  const timerId = useRef(null);
  const refStoryList: any = useRef();
  const [lng, setLng] = useState(mapLng);
  const [lat, setLat] = useState(mapLat);
  const [zoomI] = useState(0);
  const existingMarkerOnMap = {};

  useEffect(() => {
    setLng(mapLng);
    setLat(mapLat);
  }, [getUserAllInfo]);

  useEffect(() => {
    if (currentOpenedStory) {
      setOpenStory(true);
    }
  }, [currentOpenedStory]);

  useEffect(() => {
    map.current = new maplibregl.Map({
      container: fullMapContainer.current,
      style: `https://api.maptiler.com/maps/${mapteiler.mapSkin}/style.json?key=${mapteiler.apiKey}`,
      center: [lng, lat],
      zoom: zoomI,
      attributionControl: false,
    });

    moduleStoriesData?.stories
      ?.filter((it) => (currentFilterOption === 'favourite' ? it.is_favorite : it))
      ?.forEach((story) => {
        let el = document.createElement('div');
        let lngLat: string = `${story.coordinates.latitude}, ${story.coordinates.longitude}`;

        if (existingMarkerOnMap[lngLat]) {
          existingMarkerOnMap[lngLat]?.ids.add({
            id: story.id,
            title: story.title,
          });
          return;
        }

        const ids = new Set();
        existingMarkerOnMap[lngLat] = {
          ids: ids.add({ id: story.id, title: story.title }),
          latitude: story.coordinates.latitude,
          longitude: story.coordinates.longitude,
          country: story.country,
        };

        el.className = 'marker';
        el.style.width = '48px';
        el.style.height = '48px';
        el.style.backgroundRepeat = 'no-repeat';
        el.style.backgroundPosition = 'center';
        el.style.cursor = 'pointer';
        el.style.backgroundImage = `url(${marker})`;

        el.addEventListener('click', () => {
          if (existingMarkerOnMap[lngLat].ids.size > 1) {
            setOpenStory(false);
            setOpenStoryList(lngLat);
          } else {
            setCurrentOpenedStory(story.id);
            setOpenStoryList(null);
          }
        });
        new maplibregl.Marker(el)
          .setLngLat([story?.coordinates?.longitude, story?.coordinates?.latitude])
          .addTo(map.current);

        const handleZoom = () => {
          clearTimeout(timerId.current);

          timerId.current = setTimeout(() => setAnalyticsLogger('map_enlarge', { page: 'account-settings' }), 1000);
        };
        map.current.on('zoomend', handleZoom);
        /* eslint-disable */
        map.current.once('mouseover', () => setAnalyticsLogger('map_view', { page: 'account-settings' }));
        map.current.once('touchstart', () => setAnalyticsLogger('map_view', { page: 'account-settings' }));
        /* eslint-enable */
      });
    setMarkersOnMap(existingMarkerOnMap);
  }, [moduleStoriesData, currentFilterOption, lng, lat]);

  const [randomStoryId, setRandomStoryId] = useState(
    moduleStoriesData?.stories[Math.floor(Math.random() * moduleStoriesData?.stories?.length)]?.id
  );

  const handleRandomStory = () => {
    dispatch(
      modalOpen({
        modalType: constants.SINGLE_STORY,
        modalData: randomStoryId,
        modalSize: 'xl',
      })
    );
    setRandomStoryId(moduleStoriesData?.stories[Math.floor(Math.random() * moduleStoriesData?.stories?.length)]?.id);
  };

  const handleResizeMap = () => dispatch(modalClose());
  /* eslint-disable */
  const { availableLanguages } = userInfo.useGetUserInfoRequestQuery(null, {
    selectFromResult: ({ data }) => ({ availableLanguages: data.available_languages }),
  });
  /* eslint-enable */
  return (
    <>
      <PageHeader title={moduleInfo?.title} backLink={handleResizeMap}>
        <div className={styles.actions}>
          <RandomStory getlocalizationInfo={getlocalizationInfo} handleRandomStory={handleRandomStory} />
          {/*TODO lang switcher exists in design but no info what it should do. For now it's not working*/}
          {/*<LangSwitcher languagesList={availableLanguages} />*/}
        </div>
      </PageHeader>
      <div className={mapClasses}>
        <div ref={fullMapContainer} className={styles.map} />
        {openStory && (
          <StoryPreview>
            <StoryCard id={currentOpenedStory} />
          </StoryPreview>
        )}
        {openStoryList && (
          <StoryList
            latLng={openStoryList}
            data={markersOnMap}
            refStoryList={refStoryList}
            setCurrentOpenedStory={setCurrentOpenedStory}
          />
        )}
        <div className={styles.bottom_action}>
          <StoryFilter
            getlocalizationInfo={!loadTranslations && getlocalizationInfo}
            handleFilter={setCurrentFilterOption}
            handleResizeMap={handleResizeMap}
          />
        </div>
      </div>
    </>
  );
};

export default FullModuleMap;
