import classNames from 'classnames/bind';

import styles from './PasswordChecker.module.scss';
import { Levels } from 'components/PasswordChecker/Levels/Levels';
import { CheckList } from 'components/PasswordChecker/CheckList/CheckList';
import { useEffect, useState } from 'react';
import { PasswordValidatorType } from 'helpers/passwordValidator';

type PasswordCheckerProps = {
  className?: string;
  checkedItems: PasswordValidatorType | {};
};

export type CheckItemType = {
  text: string;
  checked: boolean;
};

export function PasswordChecker({ className, checkedItems = {} }: PasswordCheckerProps) {
  const cn = classNames.bind(styles);
  const [passwordCheckList, setPasswordCheckList] = useState({
    minChars: { text: 'At least 8 characters', checked: false },
    caseFormat: { text: 'Uppercase and lowercase Latin letters', checked: false },
    specialSymbol: { text: 'A symbol !@#$%^&', checked: false },
    numberRequired: { text: 'A number', checked: false },
  });
  const checkItems = Object.values(passwordCheckList);
  const level: number = checkItems.filter((item: CheckItemType) => item.checked).length;

  const mainCls: string = cn({
    checker: true,
    [String(className)]: className,
  });

  useEffect(() => {
    if (Object.values(checkedItems).length) {
      setPasswordCheckList((prevState) => {
        const newState = { ...prevState };

        for (let key in checkedItems) {
          newState[key].checked = checkedItems[key];
        }

        return newState;
      });
    }
  }, [checkedItems]);

  return (
    <div className={mainCls}>
      <Levels level={level} className={styles.levels} />
      <CheckList checkItems={checkItems} className={styles.checkList} />
    </div>
  );
}
