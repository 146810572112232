import React from 'react';
import ClothesStyle1 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style1';
import ClothesStyle2 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style2';
import ClothesStyle3 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style3';
import ClothesStyle4 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style4';
import ClothesStyle5 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style5';
import ClothesStyle6 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style6';
import ClothesStyle7 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style7';
import ClothesStyle8 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style8';
import ClothesStyle9 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style9';
import ClothesStyle10 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style10';
import ClothesStyle11 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style11';
import ClothesStyle12 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style12';
import ClothesStyle13 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style13';
import ClothesStyle14 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style14';
import ClothesStyle15 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style15';
import ClothesStyle16 from 'components/pages/Account/components/PreviewUserAvatar/RenderClothesStyle/style16';

const renderClothes = (userValues) => {
  switch (userValues) {
    case 1:
      return <ClothesStyle1 />;
    case 2:
      return <ClothesStyle2 />;
    case 3:
      return <ClothesStyle3 />;
    case 4:
      return <ClothesStyle4 />;
    case 5:
      return <ClothesStyle5 />;
    case 6:
      return <ClothesStyle6 />;
    case 7:
      return <ClothesStyle7 />;
    case 8:
      return <ClothesStyle8 />;
    case 9:
      return <ClothesStyle9 />;
    case 10:
      return <ClothesStyle10 />;
    case 11:
      return <ClothesStyle11 />;
    case 12:
      return <ClothesStyle12 />;
    case 13:
      return <ClothesStyle13 />;
    case 14:
      return <ClothesStyle14 />;
    case 15:
      return <ClothesStyle15 />;
    case 16:
      return <ClothesStyle16 />;
    default:
      return <ClothesStyle1 />;
  }
};

export default renderClothes;
