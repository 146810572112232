import React from 'react';

const HairStyle18 = () => {
  return (
    <>
      <path
        d="M279.213 181.5C280.213 237 279.213 276 172.213 282C101.868 285.945 42.7134 283 53.2134 181.5C53.2134 125.443 103.805 80 166.213 80C228.622 80 278.213 126 279.213 181.5Z"
        fill="#1A1A1A"
      />
    </>
  );
};

export default HairStyle18;
