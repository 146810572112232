import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { entryApi } from 'app/entryApi';

export interface RegistrationState {
  firstName: string;
  lastName: string;
  email: string;
  gender: any; //todo need change type any
  country: string;
  state?: object | string;
  city: string;
}

const initialState: RegistrationState = {
  firstName: '',
  lastName: '',
  email: '',
  gender: null,
  country: '',
  state: '',
  city: '',
};

export const educatorRegistrationSlice = createSlice({
  name: 'educatorRegistration',
  initialState,
  reducers: {
    stepOneRegistrationEducator: (state, action: PayloadAction<RegistrationState>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.gender = action.payload.gender;
      state.country = action.payload.country;
      state.state = action.payload.state;
      state.city = action.payload.city;
    },
  },
});

export const { stepOneRegistrationEducator } = educatorRegistrationSlice.actions;
export const getEducatorDataState = (state: RootState) => state.educator;
export default educatorRegistrationSlice.reducer;

type RegisterPayload = {
  first_name: string;
  last_name: string;
  email: string;
  gender: any; //todo need change type any
  location: object;
};

export const educatorRegistrationLogin = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    registrationEducator: builder.mutation<any, RegisterPayload>({
      query(data) {
        return {
          url: '/register/educator',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});
