import React from 'react';
import HairStyleFront14 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style14_front';
import HairStyle1 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style1';
import HairStyleFront2 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style2_front';
import HairStyleFront3 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style3_front';
import HairStyleFront4 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style4_front';
import HairStyleFront6 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style6_front';
import HairStyle5 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style5';
import HairStyleFront7 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style7_front';
import HairStyleFront8 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style8_front';
import HairStyleFront9 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style9_front';
import HairStyle11 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style11';
import HairStyle12 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style12';
import HairStyle13 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style13';
import HairStyle15 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style15';
import HairStyle16 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style16';
import HairStyle17 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style17';
import HairStyleFront18 from 'components/CustomizePlatform/components/SiteUserAvatar/RenderHairStyle/style18_front';

const RenderHairStyle = ({ hairStyle, hairColor }) => {
  switch (hairStyle) {
    case 1:
      return <HairStyle1 hairColor={hairColor} />;
    case 2:
      return <HairStyleFront2 hairColor={hairColor} />;
    case 3:
      return <HairStyleFront3 hairColor={hairColor} />;
    case 4:
      return <HairStyleFront4 hairColor={hairColor} />;
    case 5:
      return <HairStyle5 hairColor={hairColor} />;
    case 6:
      return <HairStyleFront6 hairColor={hairColor} />;
    case 7:
      return <HairStyleFront7 hairColor={hairColor} />;
    case 8:
      return <HairStyleFront8 hairColor={hairColor} />;
    case 9:
      return <HairStyleFront9 hairColor={hairColor} />;
    case 11:
      return <HairStyle11 hairColor={hairColor} />;
    case 12:
      return <HairStyle12 hairColor={hairColor} />;
    case 13:
      return <HairStyle13 hairColor={hairColor} />;
    case 14:
      return <HairStyleFront14 hairColor={hairColor} />;
    case 15:
      return <HairStyle15 />;
    case 16:
      return <HairStyle16 hairColor={hairColor} />;
    case 17:
      return <HairStyle17 hairColor={hairColor} />;
    case 18:
      return <HairStyleFront18 />;
    default:
      return <></>;
  }
};

export default RenderHairStyle;
