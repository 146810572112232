import React, { useState } from 'react';
import styles from './Customize.module.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import classNames from 'classnames/bind';
import { checkCustomizeProfileModalOpen, customizeProfileModalClose } from 'features/customizeProfile/customizeProfile';
import {
  getUserSiteSettingsState,
  setUserAccountData,
  useSaveAccountSettingsMutation,
} from 'features/userAccount/userAccountSlice';
import { Form, Formik } from 'formik';
import AvatarSetting from 'components/CustomizePlatform/components/AvatarSetting';
import PreviewUserAvatar from 'components/CustomizePlatform/components/PreviewUserAvatar/PreviewUserAvatar';
import SiteSetting from 'components/CustomizePlatform/components/SiteSetting';
import { ReactComponent as IconAbstraction } from 'assets/icons/customize-abstraction.svg';
import UserSitePreview from 'components/CustomizePlatform/components/PreviewUserSite/UserSite';
import Logo from 'components/common/Logo/Logo';
import SimpleBar from 'simplebar-react';

const CustomizePlatform = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(checkCustomizeProfileModalOpen);
  const [saveAccountSettings] = useSaveAccountSettingsMutation();
  const closeModal = () => {
    dispatch(customizeProfileModalClose());
  };
  const skipCustomization = () => {
    saveAccountSettings({ account_customization_setting: '' });
    closeModal();
  };
  const cn: Function = classNames.bind(styles);
  const contentWrapStyle = cn({
    customize_platform_wrapper: true,
    active: isModalOpen,
  });
  const getUserSettings = useAppSelector(getUserSiteSettingsState);

  const [siteValues, setSiteValues] = useState(getUserSettings);
  return (
    <div className={contentWrapStyle}>
      <Logo type="small" className={styles.modal_logo} />
      <SimpleBar className={styles.left_block}>
        <div className={styles.form_wrap}>
          <Formik
            initialValues={getUserSettings}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false);
              const userSiteSettingsValues = {
                select_style: values.select_style,
                body_color: values.body_color,
                hair_style: values.hair_style,
                hair_color: values.hair_color,
                accessories: values.accessories,
                clothes: values.clothes,
                avatar_background_color: values.avatar_background_color,
                background_color: values.background_color,
                sidebar_pattern: values.sidebar_pattern,
                background_color_sidebar_pattern: values.background_color_sidebar_pattern,
              };
              dispatch(setUserAccountData({ userSiteSettings: userSiteSettingsValues }));
              try {
                await saveAccountSettings({
                  account_customization_setting: JSON.stringify({
                    select_style: values.select_style,
                    body_color: values.body_color,
                    hair_style: values.hair_style,
                    hair_color: values.hair_color,
                    accessories: values.accessories,
                    clothes: values.clothes,
                    avatar_background_color: values.avatar_background_color,
                    background_color: values.background_color,
                    sidebar_pattern: values.sidebar_pattern,
                    background_color_sidebar_pattern: values.background_color_sidebar_pattern,
                  }),
                }).unwrap();
                closeModal();
              } catch (err) {
                console.error(err);
              }
            }}>
            {({ values, handleChange, setFieldValue }) => {
              setSiteValues(values);
              return (
                <>
                  <Form className={styles.customization_form}>
                    <p className={styles.info}>Customize platform design</p>
                    <h3 className={styles.personal_avatar}>Build your own personal avatar:</h3>

                    <PreviewUserAvatar customStyles={'personal_avatar'} userValues={values} />

                    <div className={styles.avatar_wrap}>
                      <AvatarSetting initialValues={values} handleChange={handleChange} setFieldValue={setFieldValue} />
                    </div>

                    <SiteSetting initialValues={values} handleChange={handleChange} setFieldValue={setFieldValue} />
                    <div className={styles.button_wrap}>
                      <button type="button" onClick={skipCustomization} className={styles.cancel}>
                        Skip customization
                      </button>
                      <button type="submit" className={styles.save}>
                        Customize profile
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </SimpleBar>
      <div className={styles.right_block}>
        <IconAbstraction className={styles.icon_abstraction} />
        <UserSitePreview siteValues={siteValues} />
      </div>
    </div>
  );
};

export default CustomizePlatform;
