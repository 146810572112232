import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface loginStateInterface {
  userToken: string;
  acceptLanguage: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: loginStateInterface = {
  userToken: '',
  acceptLanguage: 'en',
  status: 'idle',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setlogOut: (state) => {
      state.userToken = '';
    },
    setUserTokenAfterRegistration: (state, action: PayloadAction<{ userToken: string; acceptLanguage: string }>) => {
      state.userToken = action.payload.userToken;
      state.acceptLanguage = action.payload.acceptLanguage;
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
  },
});

export const { setUserTokenAfterRegistration, setlogOut, updateToken } = loginSlice.actions;
export default loginSlice.reducer;
