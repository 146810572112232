import React from 'react';
import styles from '../Customize.module.scss';
import { accountSettingsAvatarBackgroundColor } from 'additionalInfo/index';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames/bind';
import { setUserAccountData } from 'features/userAccount/userAccountSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const AvatarBackgroundColorSettings = ({ initialValues, handleChange }) => {
  const dispatch = useAppDispatch();
  const handleChangeBodyColor = (event, handleChange) => {
    handleChange(event);
    let spredValues = {
      ...initialValues,
      avatar_background_color: event.target.value,
    };
    dispatch(setUserAccountData({ userSiteSettings: spredValues }));
  };
  const cn: Function = classNames.bind(styles);
  const avatarBackgroundColorStyle = (compare: boolean) =>
    cn({
      avatar_background_color_box_item: true,
      active: compare,
    });
  const avatarBackgroundBoxStyles = cn({ avatar_background_color_box: true });

  return (
    <>
      <div className={styles.section_title} id="avatar-background-color">
        Avatar background
      </div>
      <div
        className={`${styles.avatar_background_color_box_wrap}`}
        role="group"
        aria-labelledby="avatar-background-color">
        {accountSettingsAvatarBackgroundColor.map((singleItem) => (
          <label
            className={avatarBackgroundColorStyle(initialValues.avatar_background_color === singleItem.value)}
            key={nanoid()}>
            <input
              value={singleItem.value}
              name="avatar_background_color"
              type="radio"
              checked={initialValues.avatar_background_color === singleItem.value}
              onChange={(event) => handleChangeBodyColor(event, handleChange)}
            />
            <div style={{ background: singleItem.value }} className={avatarBackgroundBoxStyles}></div>
          </label>
        ))}
      </div>
    </>
  );
};

export default AvatarBackgroundColorSettings;
