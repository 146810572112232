import React from 'react';

const HairStyle8 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M161.743 126.003C162.363 126.003 162.867 125.5 162.847 124.878C162.77 122.505 162.451 120.167 161.901 117.966C161.264 115.418 160.33 113.102 159.153 111.152C157.976 109.201 156.578 107.654 155.04 106.599C153.503 105.543 151.854 105 150.19 105C148.525 105 146.877 105.543 145.339 106.599C143.801 107.654 142.403 109.201 141.226 111.152C140.049 113.102 139.116 115.418 138.479 117.966C137.928 120.167 137.609 122.505 137.532 124.878C137.512 125.5 138.016 126.003 138.636 126.003L161.743 126.003Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle8;
