import React from 'react';

const HairStyle7 = ({ userColor }) => {
  return (
    <>
      <path d="M55 102C55 46.7715 99.7715 2 155 2H168C223.228 2 268 46.7715 268 102V304H55V102Z" fill={userColor} />
    </>
  );
};

export default HairStyle7;
