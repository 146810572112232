import React from 'react';

const HairStyle3 = ({ userColor }) => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.823 0C200.656 0 230.57 22.5662 244.96 57.5186C275.455 65.3393 299.96 90.1532 299.96 122.336C299.96 133.758 296.661 144.974 291.013 155.132C311.314 171.748 323.671 195.071 322.972 220.579C321.642 269.096 288.319 311.542 230.359 309.957C205.678 309.282 180.51 300.732 159.847 287.39C138.867 301.081 113.054 309.584 87.9835 309.584C31.3819 309.584 0 266.243 0 217.707C0 196.622 8.6597 177.271 23.0957 162.127C16.7484 151.45 12.998 139.506 12.998 127.329C12.998 94.9158 37.856 69.9772 68.6548 62.3463C85.6269 25.3667 120.526 0 160.823 0Z"
        fill={userColor}
      />
    </>
  );
};

export default HairStyle3;
