import React from 'react';

const HairStyle15 = () => {
  return (
    <>
      <path
        d="M156.285 113.345C156.285 111.679 155.648 110.081 154.514 108.903C153.38 107.724 151.841 107.063 150.237 107.063C148.633 107.062 147.095 107.724 145.961 108.903C144.827 110.081 144.189 111.679 144.189 113.345L156.285 113.345Z"
        fill="#323232"
      />
      <g filter="url(#filter0_f_8170_60209)">
        <rect x="144.377" y="112.782" width="11.5331" height="0.656358" fill="#1A1A1A" fillOpacity="0.65" />
      </g>
      <rect x="143.346" y="113.157" width="13.7835" height="2.15661" rx="1.0783" fill="#323232" />

      <defs>
        <filter
          id="filter0_f_8170_60209"
          x="143.252"
          y="111.657"
          width="13.7836"
          height="2.90662"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.562593" result="effect1_foregroundBlur_8170_60209" />
        </filter>
      </defs>
    </>
  );
};

export default HairStyle15;
