import React from 'react';

const ClothesStyle7 = () => {
  return (
    <>
      <path
        d="M125.543 237.032C126 236.821 126.666 236.989 126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1514 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032Z"
        fill="white"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.359 292.078L162.043 294.536L163.68 292.046L198.385 239.249C215.3 247.078 223.884 252.854 238.84 265.75V344.186H82.1646V265.751C97.1248 252.886 107.079 247.099 124.155 239.234L160.359 292.078Z"
        fill="#323232"
        stroke="#323232"
        strokeWidth="4"
      />
    </>
  );
};

export default ClothesStyle7;
