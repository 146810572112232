import { fetchBaseQuery } from '@reduxjs/toolkit/query';
// eslint-disable-next-line no-duplicate-imports
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { setlogOut, updateToken } from 'features/login/loginSlice';
import { RootState } from 'app/store';
import { BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

const { REACT_APP_API_BASE_URL, REACT_APP_FIREBASE_API_KEY } = process.env;
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { userToken, acceptLanguage } = (getState() as RootState).login;

    if (userToken) headers.set('authorization', `Bearer ${userToken}`);
    if (acceptLanguage && !headers.has('accept-Language')) headers.set('accept-Language', acceptLanguage);

    return headers;
  },
});

const refreshQuery = fetchBaseQuery({
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult: BaseQueryResult<any> = await refreshQuery(
          {
            url: `https://securetoken.googleapis.com/v1/token?key=${REACT_APP_FIREBASE_API_KEY}`,
            method: 'POST',
            body: `grant_type=refresh_token&refresh_token=${localStorage.getItem('refreshToken')}`,
          },
          api,
          extraOptions
        );

        if (refreshResult?.data?.id_token && refreshResult?.data?.refresh_token) {
          localStorage.setItem('userToken', refreshResult.data.id_token);
          localStorage.setItem('refreshToken', refreshResult.data.refresh_token);

          api.dispatch(updateToken(refreshResult.data?.id_token));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          localStorage.clear();
          api.dispatch(setlogOut());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default baseQueryWithReauth;
