import classNames from 'classnames/bind';

import styles from './CheckList.module.scss';
import { CheckItemType } from 'components/PasswordChecker/PasswordChecker';

type CheckListProps = {
  className?: string;
  checkItems?: CheckItemType[];
};

export function CheckList({ className, checkItems = [] }: CheckListProps) {
  const cn = classNames.bind(styles);

  const mainCls: string = cn({
    list: true,
    [String(className)]: className,
  });

  return (
    <ul className={mainCls}>
      {checkItems.map((item: CheckItemType, index: number) => (
        <li key={index} className={cn(styles.item, { checked: item.checked })}>
          <span className={styles.text}>{item.text}</span>
        </li>
      ))}
    </ul>
  );
}
