import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { entryApi } from 'app/entryApi';

export interface StudentRegistrationStateStepOne {
  firstName: string;
  lastName: string;
  email: string;
  gender: any; //todo need change type any
  age: number;
  parentEmail: string;
  country: string;
  state: string;
  city: string;
  termCondition: boolean;
}

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  gender: null,
  age: null,
  parentEmail: '',
  country: '',
  state: '',
  city: '',
  termCondition: false,
};

export const studentRegistrationSlice = createSlice({
  name: 'studentRegistration',
  initialState,
  reducers: {
    stepOneRegistration: (state, action: PayloadAction<StudentRegistrationStateStepOne>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.gender = action.payload.gender;
      state.age = action.payload.age;
      state.parentEmail = action.payload.parentEmail;
      state.country = action.payload.country;
      state.state = action.payload.state;
      state.city = action.payload.city;
      state.termCondition = action.payload.termCondition;
    },
  },
});

export const getStudentDataState = (state: RootState) => state.student;
export default studentRegistrationSlice.reducer;

type RegisterPayload = {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  gender: any; //todo need change type any
  age: number;
  parent_email?: string;
  country?: object | string;
  state?: object | string;
  city?: object | string;
  termCondition: boolean;
};

export const studentRegistrationLogin = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    registrationStudent: builder.mutation<any, RegisterPayload>({
      query(data) {
        return {
          url: '/register/student',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});
