import React from 'react';

const HairStyle4 = ({ userColor }) => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.009 165.406C260.057 149.694 264.335 130.644 264.335 108.322C264.335 45.9989 225.932 0 166.292 0C106.651 0 58.3032 50.5226 58.3032 112.845C58.3032 132.569 64.4537 151.258 74.6253 167.281C62.2634 172.688 54.2657 185.712 55.7863 199.911C55.8164 200.191 55.85 200.471 55.8871 200.749C43.7889 205.193 35.7843 217.492 37.2265 230.958C37.5427 233.911 38.291 236.712 39.4011 239.304L17.2702 248.336C10.191 251.225 8.84036 260.725 14.8304 265.497L39.0355 284.781C45.0256 289.553 53.9036 286.049 55.0728 278.452L58.5559 255.818C61.813 256.667 65.2749 256.95 68.818 256.565C83.6498 254.956 94.6167 242.239 94.4272 227.525C105.235 225.008 113.706 217.019 117.186 206.873C135.321 216.56 155.697 220.799 175.352 217.318C186.961 215.262 197.6 212.032 207.167 207.641C211.516 215.503 219.101 221.391 228.278 223.335C228.379 238.05 239.595 250.545 254.455 251.858C255.572 251.956 256.68 251.989 257.775 251.959L261.409 272.828C262.728 280.401 271.673 283.727 277.568 278.836L301.388 259.072C307.283 254.181 305.745 244.71 298.61 241.963L282.71 235.84C284.231 232.729 285.216 229.286 285.535 225.624C286.712 212.132 278.466 199.995 266.282 195.794C266.314 195.515 266.342 195.236 266.366 194.955C267.427 182.783 261.539 171.611 252.009 165.406Z"
        fill={userColor}
      />
    </>
  );
};

export default HairStyle4;
