import React from 'react';
import AccessoriesStyle2 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style1';
import AccessoriesStyle3 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style2';
import AccessoriesStyle4 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style3';
import AccessoriesStyle5 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style4';
import AccessoriesStyle6 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style5';
import AccessoriesStyle7 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style6';
import AccessoriesStyle8 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style7';
import AccessoriesStyle9 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style8';
import AccessoriesStyle10 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style9';
import AccessoriesStyle11 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style10';
import AccessoriesStyle12 from 'components/pages/Account/components/PreviewUserAvatar/RenderAccessoriesStyle/style11';

const RenderAccessoriesStyle = (userValues) => {
  switch (userValues) {
    case 1:
      return <></>;
    case 2:
      return <AccessoriesStyle2 />;
    case 3:
      return <AccessoriesStyle3 />;
    case 4:
      return <AccessoriesStyle4 />;
    case 5:
      return <AccessoriesStyle5 />;
    case 6:
      return <AccessoriesStyle6 />;
    case 7:
      return <AccessoriesStyle7 />;
    case 8:
      return <AccessoriesStyle8 />;
    case 9:
      return <AccessoriesStyle9 />;
    case 10:
      return <AccessoriesStyle10 />;
    case 11:
      return <AccessoriesStyle11 />;
    case 12:
      return <AccessoriesStyle12 />;
    default:
      return <></>;
  }
};

export default RenderAccessoriesStyle;
