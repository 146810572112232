import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { entryApi } from 'app/entryApi';

interface JoinClassState {
  code_1: string;
}

interface LoginDataClassState {
  username: string;
  password: string;
  class_name: string;
}

interface initialStateInterface {
  code_1: string;
  username: string;
  password: string;
  class_name: string;
}

const initialState: initialStateInterface = {
  code_1: '',
  username: '',
  password: '',
  class_name: '',
};

export const joinClassSlice = createSlice({
  name: 'joinClass',
  initialState,
  reducers: {
    setUserCodeJoinClass: (state, action: PayloadAction<JoinClassState>) => {
      state.code_1 = action.payload.code_1;
    },
    setUserLoginDataJoinClass: (state, action: PayloadAction<LoginDataClassState>) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.class_name = action.payload.class_name;
    },
  },
});

export const { setUserCodeJoinClass, setUserLoginDataJoinClass } = joinClassSlice.actions;
export const getJoinClassState = (state: RootState) => state.joinClass;
export default joinClassSlice.reducer;

interface joinToClassPayload {
  // email: string;
  first_name: string;
  // last_name: string;
  age: string;
  // location: string;
  gender: any;
  class_code: string;
}

interface userCheckCodeInterface {
  class_code: string;
}

export const checkUserCodeJoinToClass = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    userCheckCodeRequest: builder.mutation<any, userCheckCodeInterface>({
      query(data) {
        return {
          url: '/check-class-code',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const userJoinToClass = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    userJoinToClassRequest: builder.mutation<any, joinToClassPayload>({
      query(data) {
        return {
          url: '/join-to-class',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useUserJoinToClassRequestMutation } = userJoinToClass;
export const { useUserCheckCodeRequestMutation } = checkUserCodeJoinToClass;
