import React from 'react';

const HairStyle2 = ({ userColor }) => {
  return (
    <>
      <path
        d="M74 89C74 40.3989 113.399 1 162 1C210.601 1 250 40.3989 250 89V186C250 201.464 237.464 214 222 214H102C86.536 214 74 201.464 74 186V89Z"
        fill={userColor}
      />
      <path
        d="M180.36 69C158.909 111.401 141.226 120.762 94.9532 125C86.8256 68.7882 97.0852 51.1624 145.887 40.4097C161.639 36.939 178.335 38.0566 192.836 45.1203C227.093 61.8075 228.301 81.6788 229.444 125C195.51 115.149 192.478 100.803 180.36 69Z"
        fill={userColor}
      />
      <path
        d="M180.36 69C158.909 111.401 141.226 120.762 94.9532 125C86.8256 68.7882 97.0852 51.1624 145.887 40.4097C161.639 36.939 178.335 38.0566 192.836 45.1203C227.093 61.8075 228.301 81.6788 229.444 125C195.51 115.149 192.478 100.803 180.36 69Z"
        fill={userColor}
      />
    </>
  );
};

export default HairStyle2;
