import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface customizeProfileModalState {
  open: boolean;
}

const initialState: customizeProfileModalState = { open: false };

export const customizeProfileModalSlice = createSlice({
  name: 'customizeProfileModal',
  initialState,
  reducers: {
    customizeProfileModalOpen: (state) => {
      state.open = true;
    },
    customizeProfileModalClose: (state) => {
      state.open = false;
    },
  },
});

export const { customizeProfileModalOpen, customizeProfileModalClose } = customizeProfileModalSlice.actions;

export const checkCustomizeProfileModalOpen = (state: RootState) => state.customizeProfileModal.open;

export default customizeProfileModalSlice.reducer;
