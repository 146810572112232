import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface ModalState {
  userEmail: string;
  userPass: string;
  userToken: string;
}

const initialState: ModalState = {
  userEmail: '',
  userPass: '',
  userToken: '',
};

export const setDataForgotPassSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setUserForgotPassEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    setUserForgotPassPassword: (state, action: PayloadAction<string>) => {
      state.userPass = action.payload;
    },
    setUserForgotPassToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
  },
});

//prettier-ignore
export const { setUserForgotPassEmail } = setDataForgotPassSlice.actions;

export const checkUserForgotPassEmail = (state: RootState) => state.forgotPassword.userEmail;
export default setDataForgotPassSlice.reducer;
