import { entryApi } from 'app/entryApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export const userNotifications = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsRequest: builder.query<any, string>({
      query() {
        return {
          url: '/notifications',
          method: 'GET',
        };
      },
      providesTags: () => ['READ_NOTIFICATIONS'],
    }),
    readNotificationsRequest: builder.mutation<any, string>({
      query() {
        return {
          url: '/read-notifications',
          method: 'POST',
        };
      },
      invalidatesTags: ['READ_NOTIFICATIONS'],
    }),
    hideModalNotificationsRequest: builder.mutation<any, string>({
      query(notificationID) {
        return {
          url: `/change-status-view/${notificationID}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetNotificationsRequestQuery,
  useReadNotificationsRequestMutation,
  useHideModalNotificationsRequestMutation,
} = userNotifications;

export interface NotificationModalState {
  open: boolean;
  modalData: any;
}

export interface notificationModalOpenInterface {
  modalData: any;
}

const initialState: NotificationModalState = {
  open: false,
  modalData: {},
};

export const notificationModalSlice = createSlice({
  name: 'notificationModal',
  initialState,
  reducers: {
    notificationModalOpen: (state, action: PayloadAction<notificationModalOpenInterface>) => {
      state.open = true;
      state.modalData = action.payload.modalData;
    },
    notificationModalClose: (state) => {
      state.open = false;
      state.modalData = '';
    },
  },
});

export const { notificationModalOpen, notificationModalClose } = notificationModalSlice.actions;

export const checkNotificationModalOpen = (state: RootState) => state.notificationModal.open;
export const checkNotificationModalData = (state: RootState) => state.notificationModal.modalData;

export default notificationModalSlice.reducer;
