import React from 'react';

const ClothesStyle14 = () => {
  return (
    <>
      <path
        d="M146.719 126.991C148.238 129.626 151.92 129.419 153.066 127.014C153.098 126.949 153.168 126.93 153.213 126.951C155.751 128.101 157.418 129.325 158.557 130.923C159.696 132.523 160.32 134.517 160.736 137.236C160.744 137.293 160.7 137.344 160.644 137.344H138.982C138.926 137.344 138.882 137.293 138.891 137.236C139.339 134.381 140.077 132.363 141.285 130.785C142.494 129.206 144.187 128.049 146.582 126.941C146.625 126.921 146.687 126.936 146.719 126.991Z"
        fill="white"
        stroke="#323232"
        strokeWidth="0.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.928 128.609C144.633 128.138 145.048 127.915 145.811 127.52V132.4H154.19V127.627C154.971 128.022 155.382 128.291 156.073 128.756V132.4H156.076C156.491 132.4 156.826 132.736 156.826 133.15V137.155H142.893V133.15C142.893 132.736 143.229 132.4 143.643 132.4H143.928V128.609Z"
        fill="#0E7490"
      />
      <path
        d="M156.073 137.155V133.809C156.073 133.395 155.737 133.059 155.323 133.059H144.396C143.982 133.059 143.646 133.395 143.646 133.809V137.155"
        stroke="white"
        strokeWidth="0.046875"
        strokeLinecap="round"
        strokeDasharray="0.38 0.38"
      />
      <line
        x1="144.541"
        y1="132.353"
        x2="144.541"
        y2="128.586"
        stroke="white"
        strokeWidth="0.09375"
        strokeLinecap="round"
        strokeDasharray="0.38 0.38"
      />
      <line
        x1="155.461"
        y1="132.353"
        x2="155.461"
        y2="128.586"
        stroke="white"
        strokeWidth="0.09375"
        strokeLinecap="round"
        strokeDasharray="0.38 0.38"
      />
      <ellipse cx="144.587" cy="132.965" rx="0.564874" ry="0.564935" fill="white" />
      <ellipse cx="155.32" cy="132.965" rx="0.564874" ry="0.564935" fill="white" />
      <path
        d="M146.282 135.408C146.282 134.839 146.744 134.377 147.313 134.377H152.312C152.881 134.377 153.343 134.839 153.343 135.408V137.202H146.282V135.408Z"
        fill="#164E63"
      />
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle14;
