import React from 'react';

const HairStyle5 = ({ hairColor }) => {
  return (
    <>
      <path
        d="M157.082 108.938C157.082 111.113 155.319 112.876 153.144 112.876C150.969 112.876 149.206 111.113 149.206 108.938C149.206 106.763 150.969 105 153.144 105C155.319 105 157.082 106.763 157.082 108.938Z"
        fill={hairColor}
      />
      <path
        d="M151.912 111.376C149.9 115.352 148.242 116.23 143.903 116.627C143.141 111.357 144.103 109.704 148.679 108.696C150.156 108.37 151.722 108.475 153.081 109.137C156.294 110.702 156.407 112.565 156.514 116.627C153.332 115.704 153.048 114.358 151.912 111.376Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle5;
