import React from 'react';

const BangStyle17 = ({ userColor }) => {
  return (
    <>
      <svg width="324" height="350" viewBox="0 0 324 350" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M94 125V82C94 55.4903 115.49 34 142 34H180.528C206.83 34 228.234 55.1683 228.525 81.4693L229 124.445C225.396 111.923 221.715 103.88 214.059 96.5988C206.893 89.7832 195.862 91.0575 187.413 96.1983C168.229 107.871 153.562 108.611 134.613 97.4095C125.288 91.897 112.91 90.8432 106.313 99.4354C100.977 106.385 97.8994 114.132 94 125Z"
          fill={userColor}
        />
        <g filter="url(#filter0_d_4638_52308)">
          <path
            d="M91.3939 66.1915C95.0085 57.632 99.8284 49.2891 106.7 42.6773C113.571 36.0654 121.782 30.7797 130.863 27.1221C139.945 23.4644 149.72 21.5064 159.629 21.3598C169.539 21.2132 179.389 22.881 188.618 26.2678C197.847 29.6546 206.273 34.6941 213.416 41.0987C220.559 47.5033 226.279 55.1475 230.249 63.5948C234.219 72.0422 236.361 81.1272 236.553 90.3312C236.745 99.5353 232.009 130.132 228.394 138.691C199.394 110.691 154.532 79.8288 91.3939 66.1915Z"
            fill="#323232"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.499 63.5552C229.83 64.2607 230.149 64.9717 230.456 65.6878C231.025 67.015 231.549 68.356 232.029 69.7088L155.112 21.1565C157.18 21.0385 159.257 20.9995 161.336 21.0406C161.618 21.0461 161.899 21.0532 162.18 21.0616L229.499 63.5552ZM140.068 23.4533L235.328 83.5843C235.53 85.1869 235.672 86.7964 235.755 88.4099L135.104 24.8762C136.742 24.3483 138.398 23.8738 140.068 23.4533ZM120.879 31.086L234.69 103.547C234.504 104.799 234.298 106.102 234.077 107.44L117.204 33.2943C118.405 32.525 119.631 31.7886 120.879 31.086ZM108.328 39.9632L232.042 118.422C231.752 119.843 231.453 121.257 231.148 122.647L105.425 42.6918C106.16 41.9468 106.919 41.2217 107.702 40.5185C107.91 40.3323 108.118 40.1472 108.328 39.9632Z"
          fill="white"
          fillOpacity="0.19"
        />
        <g filter="url(#filter1_d_4638_52308)">
          <path
            d="M231.268 65.7325C227.654 57.173 223.334 48.3302 216.462 41.7183C209.591 35.1064 201.38 29.8207 192.299 26.1631C183.217 22.5054 173.442 20.5474 163.533 20.4008C153.623 20.2543 143.773 21.922 134.544 25.3088C125.315 28.6956 116.889 33.7352 109.746 40.1397C102.603 46.5443 96.8832 54.1885 92.9134 62.6358C88.9435 71.0832 86.8013 80.1682 86.6091 89.3722C86.4169 98.5763 91.1536 129.173 94.7682 137.732C123.768 109.732 168.13 79.3698 231.268 65.7325Z"
            fill="#323232"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.1352 62.1678C93.0605 62.3235 92.9865 62.4796 92.913 62.6359C92.0719 64.4257 91.3128 66.2442 90.6369 68.0863L171.125 20.8696C168.865 20.6232 166.59 20.4716 164.307 20.416L93.1352 62.1678ZM185.704 23.8419L87.2366 81.6062C87.0142 83.1801 86.8492 84.762 86.7422 86.3492L190.518 25.471C188.932 24.8767 187.326 24.3335 185.704 23.8419ZM204.278 32.2431L87.4968 101.377C87.6458 102.633 87.8106 103.933 87.9893 105.263L207.819 34.5912C206.663 33.7769 205.483 32.9938 204.278 32.2431ZM216.33 41.5912L89.6739 116.261C89.9196 117.692 90.1741 119.111 90.4349 120.502L219.085 44.4319C218.246 43.4994 217.372 42.5937 216.462 41.7183C216.418 41.6759 216.374 41.6335 216.33 41.5912Z"
          fill="white"
          fillOpacity="0.19"
        />
        <defs>
          <filter
            id="filter0_d_4638_52308"
            x="87.3936"
            y="21.3506"
            width="153.165"
            height="125.341"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0336111 0 0 0 0 0.0811904 0 0 0 0 0.366667 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4638_52308" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4638_52308" result="shape" />
          </filter>
          <filter
            id="filter1_d_4638_52308"
            x="82.6035"
            y="20.3916"
            width="152.665"
            height="125.341"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0336111 0 0 0 0 0.0811904 0 0 0 0 0.366667 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4638_52308" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4638_52308" result="shape" />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default BangStyle17;
