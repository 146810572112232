import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks/reduxHooks';
import { ReactComponent as CloseIcon } from 'assets/icons/closeModal.svg';
import styles from './NotificationsModal.module.scss';
import {
  notificationModalClose,
  checkNotificationModalOpen,
  checkNotificationModalData,
  useHideModalNotificationsRequestMutation,
} from 'features/notifications/notifications';
import classNames from 'classnames/bind';
import { ReactComponent as IconVersion1 } from 'assets/icons/notification/icon_1.svg';
import { ReactComponent as IconVersion2 } from 'assets/icons/notification/icon_2.svg';
import { ReactComponent as IconVersion3 } from 'assets/icons/notification/icon_3.svg';
import { ReactComponent as IconVersion4 } from 'assets/icons/notification/icon_4.svg';
import { ReactComponent as IconVersion5 } from 'assets/icons/notification/icon_5.svg';
import { ReactComponent as IconVersion6 } from 'assets/icons/notification/icon_6.svg';
import { ReactComponent as IconVersion7 } from 'assets/icons/notification/icon_7.svg';
import { ReactComponent as IconVersion8 } from 'assets/icons/notification/icon_8.svg';
import { ReactComponent as IconVersion9 } from 'assets/icons/notification/icon_9.svg';

const NotificationModal = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(checkNotificationModalOpen);
  const modalData = useAppSelector(checkNotificationModalData);
  const [hideModalNotificationsRequest] = useHideModalNotificationsRequestMutation();
  const closeModal = (messageID) => {
    dispatch(notificationModalClose());
    hideModalNotificationsRequest(messageID);
  };

  useEffect(() => {
    if (modalData.messageId) {
      setTimeout(() => {
        closeModal(modalData.messageId);
      }, 5000);
    }
  }, [modalData.messageId]);

  useEffect(() => {
    if (modalData?.notificationData?.autoClose) {
      setTimeout(() => {
        closeModal(modalData.messageId);
      }, 5000);
    }
  }, [modalData]);

  const renderIconVersion = (versionType) => {
    switch (versionType) {
      case 'type_1':
        return <IconVersion1 />;
      case 'type_2':
        return <IconVersion2 />;
      case 'type_3':
        return <IconVersion3 />;
      case 'type_4':
        return <IconVersion4 />;
      case 'type_5':
        return <IconVersion5 />;
      case 'type_6':
        return <IconVersion6 />;
      case 'type_7':
        return <IconVersion7 />;
      case 'type_8':
        return <IconVersion8 />;
      case 'type_9':
        return <IconVersion9 />;
      default:
        return <IconVersion1 />;
    }
  };
  const cn: Function = classNames.bind(styles);
  const notificationsModalClasses = (type: string) =>
    cn({
      active: isModalOpen,
      notifications_modal: true,
      [type]: type,
    });
  const iconWrapClasses = (type: string) =>
    cn({
      notification_icon: true,
      [type]: type,
    });

  const contentWrapStyle = cn({
    notifications_modal_content: true,
    active: isModalOpen,
  });

  return (
    isModalOpen && (
      <div className={notificationsModalClasses(modalData.notificationData.type)}>
        <div className={contentWrapStyle}>
          <div className={iconWrapClasses(modalData.notificationData.type)}>
            {renderIconVersion(modalData.notificationData.type)}
          </div>

          <div
            className={styles.notification_message}
            dangerouslySetInnerHTML={{ __html: modalData.notificationData.body }}></div>

          <div className={styles.close_icon} onClick={() => closeModal(modalData.messageId)}>
            <CloseIcon />
          </div>
        </div>
      </div>
    )
  );
};

export default NotificationModal;
