import { entryApi } from 'app/entryApi';

interface courseInterface {
  title: string;
  id: string;
  description: string;
  certificate_name: string | null;
  course_progress: number;
  banner: {
    url: string;
  };
  modules: [];
}

export const coursesApi = entryApi.injectEndpoints({
  endpoints: (build) => ({
    getAllCourses: build.query({
      query: (params) => ({ url: '/courses', params }),
      providesTags: () => ['GET_ALL_COURSES'],
    }),
    loadMoreCourses: build.query({
      query: (params) => ({ url: '/courses', params }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data }: { data: { courses: [] } } = await queryFulfilled;

          dispatch(
            coursesApi.util.updateQueryData(
              'getAllCourses',
              { search_string: params.search_string },
              (storeCourses) => {
                storeCourses.courses.push(...data.courses);
              }
            )
          );
        } catch {}
      },
    }),
    getSingleCourse: build.query({
      query: (courseId) => {
        return { url: `/courses/${courseId}` };
      },
      transformResponse: (data: { course: courseInterface }) => data.course,
      providesTags: ['GET_SINGLE_COURSE', 'GET_SINGLE_MODULE_STORY'],
      keepUnusedDataFor: 1,
    }),
    getCourseCertificate: build.mutation({
      query: ({ courseId, name }) => {
        return {
          url: '/generate-certificate',
          method: 'POST',
          body: { course_id: courseId, name },
          responseHandler: async (response: Response) => {
            if (response.ok) {
              return await response.blob();
            }

            return await response.json();
          },
          cache: 'no-cache',
        };
      },
    }),
    getCourseCompletedText: build.query({
      query: (id) => ({ url: `/course-completed/${id}` }),
      transformResponse: (data) => data,
    }),
    getCompletedCourses: build.query({ query: (params) => ({ url: '/completed', params }) }),
    loadMoreCompletedCourses: build.query({
      query: (params) => ({ url: '/completed', params }),

      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data }: { data: { courses: [] } } = await queryFulfilled;

          dispatch(
            coursesApi.util.updateQueryData(
              'getCompletedCourses',
              { search_string: params.search_string },
              (storeCourses) => {
                storeCourses.courses.push(...data.courses);
              }
            )
          );
        } catch {}
      },
    }),
  }),
});
