import React from 'react';

const BangStyle11 = ({ userColor }) => {
  return (
    <>
      <path
        d="M92.9998 105.135C91.3595 83.062 112.5 36.4572 131 31.9572C131 31.9572 186 1.48185 213.754 52.9573C241.508 104.433 226.5 153.5 226.5 153.5C226.5 141.043 230.5 128 217.5 108C183.5 108 151 116 132 98C126.5 105.135 106.746 115 103 115C93.5212 126 101 149 95.4997 152C89.7455 138 94.2228 125.64 92.9998 105.135Z"
        fill={userColor}
      />
      <g filter="url(#filter0_d_4638_52273)">
        <path
          d="M94.1059 71.5458C94.6024 45.396 115.943 24.457 142.097 24.457H180.2C206.236 24.457 227.525 45.2141 228.184 71.2422L229 103.457H93.5L94.1059 71.5458Z"
          fill="white"
        />
        <path
          d="M96.1055 71.5838C96.5814 46.5236 117.033 26.457 142.097 26.457H180.2C205.151 26.457 225.554 46.3493 226.185 71.2929L226.949 101.457H95.5383L96.1055 71.5838Z"
          stroke="#323232"
          strokeWidth="4"
        />
      </g>
      <path
        d="M132 57.457C132 40.8885 145.431 27.457 162 27.457C178.569 27.457 192 40.8885 192 57.457V101.457H132V57.457Z"
        fill="#323232"
        fillOpacity="0.38"
        stroke="#323232"
        strokeWidth="4"
      />
      <path d="M61 103.779C61 101.57 62.7909 99.7793 65 99.7793H228V103.779H61Z" fill="#323232" />
      <defs>
        <filter
          id="filter0_d_4638_52273"
          x="89.5"
          y="24.457"
          width="143.5"
          height="87"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0916667 0 0 0 0 0.0916667 0 0 0 0 0.0916667 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4638_52273" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4638_52273" result="shape" />
        </filter>
      </defs>
    </>
  );
};

export default BangStyle11;
