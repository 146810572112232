import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Modal.module.scss';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import { ReactComponent as IconArrow } from 'assets/icons/registration/arrow.svg';
import { ReactComponent as IconAbstraction } from 'assets/icons/registration/abstraction.svg';
import routes from 'features/routes/routes';

const UserRegistration = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(modalClose());
  };

  return (
    <div className={styles.registration_wrap}>
      <div className={`${styles.block_make_choice}`}>
        <div className={styles.white_border}>
          <Link className={`${styles.user_wrap}`} onClick={closeModal} to={routes.studentRegistration}>
            <div className={styles.title}>Student</div>

            <div className={styles.continue_wrap}>
              <div className={styles.continue}>Continue</div>
              <div className={styles.icon}>
                <IconArrow />
              </div>
            </div>

            <div className={styles.abstraction_img}>
              <IconAbstraction />
            </div>
          </Link>
        </div>
      </div>

      <div className={`${styles.block_make_choice} ${styles.block_educator} `}>
        <div className={styles.white_border}>
          <Link className={`${styles.user_wrap}`} onClick={closeModal} to={routes.educatorRegistration}>
            <div className={styles.title}>Educator</div>

            <div className={styles.continue_wrap}>
              <div className={styles.continue}>Continue</div>
              <div className={styles.icon}>
                <IconArrow />
              </div>
            </div>

            <div className={styles.abstraction_img}>
              <IconAbstraction />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserRegistration;
