const surveyQ = {
  1: 'How does the Student to World program compare to other programs that you are participating in for school?',
  2: 'Is this the first Student to World course that you"ve taken?',
  3: 'Did you attend a live event with us?',
  4: 'Which Student to World course are you enrolled in?',
  5: 'What language were you learning through Student to World?',
  6: {
    A: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Speaking English',
    B: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Understanding English',
    C: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Reading English',
    D: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Taking risks (in writing or reading or speaking) in English',
  },
  7: {
    A: 'How much did Student to World help you: Learn new English vocabulary?',
    B: 'How much did Student to World help you: Learn new English grammar rules?',
  },
  8: 'Now that you have completed Student to World, how motivated are you to continue to learn English?',
  9: {
    A: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Speaking English',
    B: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Understanding English',
    C: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Reading English',
    D: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Taking risks (in writing or reading or speaking) in English',
  },
  10: 'BEFORE YOU STARTED Student to World, how motivated were you to continue to learn English?',
  11: {
    A: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Speaking Spanish',
    B: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Understanding Spanish',
    C: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Reading Spanish',
    D: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high) Taking risks (in writing or reading or speaking) in Spanish',
  },
  12: {
    A: 'How much did Student to World help you: Learn new Spanish vocabulary?',
    B: 'How much did Student to World help you: Learn new Spanish grammar rules?',
  },
  13: 'Now that you have completed Student to World, how motivated are you to continue to learn Spanish?',
  14: {
    A: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Speaking Spanish',
    B: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Understanding Spanish',
    C: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Reading Spanish',
    D: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Taking risks (in writing or reading or speaking) in Spanish',
  },
  15: 'BEFORE YOU STARTED Student to World, how motivated were you to continue to learn Spanish?',
  16: {
    A: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high): Speaking Arabic',
    B: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high): Understanding Arabic',
    C: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high): Reading Arabic',
    D: 'Now that you have completed Student to World, how comfortable do you feel: (0 = low, and 10 = high): Taking risks (in writing or reading or speaking) in Arabic',
  },
  17: {
    A: 'How much did Student to World help you: Learn new Arabic vocabulary?',
    B: 'How much did Student to World help you: Learn new Arabic grammar rules?',
  },
  18: 'Now that you have completed Student to World, how motivated are you to continue to learn Arabic?',
  19: {
    A: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Speaking Arabic',
    B: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Speaking Arabic Understanding Arabic',
    C: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Reading Arabic',
    D: 'BEFORE YOU STARTED Student to World, how comfortable DID you feel: (0 = low, and 10 = high) Taking risks (in writing or reading or speaking) in Arabic',
  },
  20: 'BEFORE YOU STARTED Student to World, how motivated were you to continue to learn Arabic?',
  21: {
    A: 'Please answer the following questions about Student to World. I can really be myself in this program',
    B: 'Please answer the following questions about Student to World. I feel that I am accepted by other people in this program',
    C: 'Please answer the following questions about Student to World. I feel myself to be part of this program',
    D: 'Please answer the following questions about Student to World. I think that people care about me in this program',
  },
  22: {
    A: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I try to understand people better by imagining how a situation is perceived by them.',
    B: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I think about why other people might have different beliefs than me.',
    C: 'How much do you agree or disagree with the following statements now that you have completed Student to World? When communicating with people who are different from me, I try to notice things from their perspective.',
    D: 'How much do you agree or disagree with the following statements now that you have completed Student to World? When I disagree with someone, I think about how I would feel if I were them.',
  },
  23: {
    A: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I understand the emotions of people who are different from me.',
    B: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I notice similarities between me and my global peers.',
    C: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I respect the differences (in beliefs, behaviors, languages, practices, or customs) between my global peers and me.',
    D: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I try to make a difference on local issues.',
    E: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I try to make other people more globally-aware.',
    F: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I try to make myself more globally-aware.',
    G: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I believe I will use what I learned during the program to contribute positively to my local and/or global community. ',
  },
  24: {
    A: 'How much do you agree or disagree with the following statements now that you have completed Student to World? It is interesting to spend time communicating with people from other cultures.',
    B: 'How much do you agree or disagree with the following statements now that you have completed Student to World? In addition to being a citizen of my own country, I think of myself as a global citizen.',
    C: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I like trying to understand people’s behaviors in the context of their own culture.',
    D: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I feel a strong connection with humanity worldwide.',
    E: 'How much do you agree or disagree with the following statements now that you have completed Student to World? People in my home country can learn from people in other parts of the world.',
    F: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I identify as a member of the worldwide community.',
  },
  25: {
    A: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I find it is easy to communicate with people from different cultures.',
    B: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I know how to initiate a conversation when communicating with people from different cultures.',
    C: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I find it is easy to identify with my culturally different counterparts during our communication.',
    D: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I find it is easy to get along with people from different cultures.',
    E: 'How much do you agree or disagree with the following statements now that you have completed Student to World? I feel relaxed when communicating with people from different cultures.',
  },
  26: {
    A: 'How much did you agree with the following statements before you started Student to World? I try to understand people better by imagining how a situation is perceived by them.',
    B: 'How much did you agree with the following statements before you started Student to World? I think about why other people might have different beliefs than me.',
    C: 'How much did you agree with the following statements before you started Student to World? When communicating with people who are different from me, I try to notice things from their perspective.',
    D: 'How much did you agree with the following statements before you started Student to World? When I disagree with someone, I think about how I would feel if I were them.',
  },
  27: {
    A: 'How much did you agree with the following statements before you started Student to World? I understood the emotions of people who are different from me.',
    B: 'How much did you agree with the following statements before you started Student to World? I could notice similarities between me and my global peers.',
    C: 'How much did you agree with the following statements before you started Student to World? I respected the differences (in beliefs, behaviors, languages, practices, or customs) between me and my global peers.',
    D: 'How much did you agree with the following statements before you started Student to World? I tried to make a difference on local issues.',
    E: 'How much did you agree with the following statements before you started Student to World? I tried to make other people more globally-aware.',
    F: 'How much did you agree with the following statements before you started Student to World? I tried to make myself more globally-aware.',
  },
  28: {
    A: 'How much did you agree with the following statements before you started Student to World? It was interesting to spend time communicating with people from other cultures.',
    B: 'How much did you agree with the following statements before you started Student to World? In addition to being a citizen of my own country, I thought of myself as a global citizen.',
    C: 'How much did you agree with the following statements before you started Student to World? I liked trying to understand people’s behaviors in the context of their own culture.',
    D: 'How much did you agree with the following statements before you started Student to World? I felt a strong connection with humanity worldwide.',
    E: 'How much did you agree with the following statements before you started Student to World? I believed people in my home country could learn from people in other parts of the world.',
    F: 'How much did you agree with the following statements before you started Student to World? I identified as a member of the worldwide community.',
  },
  29: {
    A: 'How much did you agree with the following statements before you started Student to World? I found it is easy to communicate with people from different cultures.',
    B: 'How much did you agree with the following statements before you started Student to World? I knew how to initiate a conversation when communicating with people from different cultures.',
    C: 'How much did you agree with the following statements before you started Student to World? I found it is easy to identify with my culturally different counterparts during our interaction.',
    D: 'How much did you agree with the following statements before you started Student to World? I found it is easy to get along with people from different cultures.',
    E: 'How much did you agree with the following statements before you started Student to World? I felt relaxed when communicating with people from different cultures.',
  },
  30: 'How do you think Student to World changed you? It might have changed your opinion about something, the way you feel about something, or your understanding of something.',
  31: 'How much do you agree with this statement: I would recommend Student to World to other students.',
};

export const formatSurveyData = (survey) => {
  const builder = {};
  const matrix = [
    '6',
    '7',
    '9',
    '11',
    '12',
    '14',
    '16',
    '17',
    '19',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
  ];
  Object.keys(survey).forEach((k: any) => {
    if (matrix.includes(k)) {
      let subQuestionCount = 1;
      Object.keys(survey[k]).forEach((kk) => {
        builder[`question${k}-${subQuestionCount}`] = {
          title: surveyQ[k][kk],
          answer: survey[k][kk],
        };
        subQuestionCount += 1;
      });
    } else {
      builder[`question${k}`] = {
        title: surveyQ[k],
        answer: survey[k],
      };
    }
  });
  return builder;
};
