import React from 'react';
import styles from '../Customize.module.scss';
import { accountSettingsBackgroundColor } from 'additionalInfo/index';
import { nanoid } from '@reduxjs/toolkit';
import { ReactComponent as CheckedIcon } from 'assets/icons/checked.svg';
import classNames from 'classnames/bind';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import { setUserAccountData } from 'features/userAccount/userAccountSlice';

const BackgroundColorPattern = ({ setBackgroundColorPattern, initialValues, handleChange, setFieldValue }) => {
  const dispatch = useAppDispatch();
  const handleChangeBackgroundColor = (event, handleChange, colorSidebar) => {
    handleChange(event);
    let spredValues = {
      ...initialValues,
      background_color: event.target.value,
      background_color_sidebar_pattern: colorSidebar,
    };
    setFieldValue('background_color_sidebar_pattern', colorSidebar);
    setBackgroundColorPattern(colorSidebar);
    dispatch(setUserAccountData({ userSiteSettings: spredValues }));
  };
  const cn: Function = classNames.bind(styles);
  const backgroundColorStyle = (compare: boolean) =>
    cn({
      background_color_pattern_item: true,
      active: compare,
    });
  const backgroundColorBoxStyles = cn({ single_color_pattern: true });

  return (
    <>
      <div className={styles.section_title} id="background-color-pattern">
        Select background
      </div>
      <div className={styles.background_color_pattern} role="group" aria-labelledby="background-color-pattern">
        {accountSettingsBackgroundColor.map((singleItem) => (
          <label className={backgroundColorStyle(initialValues.background_color === singleItem.pattern)} key={nanoid()}>
            <input
              value={singleItem.pattern}
              name="background_color"
              type="radio"
              checked={initialValues.background_color === singleItem.pattern}
              onChange={(event) => handleChangeBackgroundColor(event, handleChange, singleItem.value)}
            />
            <div style={{ background: singleItem.value }} className={backgroundColorBoxStyles}>
              <CheckedIcon className={styles.checked_icon} />
            </div>
          </label>
        ))}
      </div>
    </>
  );
};
export default BackgroundColorPattern;
