import { entryApi } from 'app/entryApi';

interface FeedbackPayload {
  rate?: number;
  comment: string;
  module_id: string;
  course_id: string;
}

export const feedback = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    saveFeedback: builder.mutation<any, FeedbackPayload>({
      query(data) {
        return {
          url: '/module/feedback',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useSaveFeedbackMutation } = feedback;
