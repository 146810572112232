import React from 'react';

const HairStyle7 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M140.157 114.564C140.157 109.386 144.355 105.188 149.533 105.188H150.752C155.931 105.188 160.129 109.386 160.129 114.564V133.505H140.157V114.564Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle7;
