import React from 'react';

const ClothesStyle9 = () => {
  return (
    <>
      <path
        d="M125.543 237.032C126 236.821 126.666 236.989 126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1514 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032Z"
        fill="white"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5 346.854C48.2344 316.708 55.9904 295.315 68.6459 278.557C81.3002 261.801 98.9975 249.486 123.979 237.761C124.616 237.462 125.363 237.924 125.363 238.65V347C125.363 347.552 124.915 348 124.363 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854Z"
        fill="#323232"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M277.504 346.854C272.77 316.708 265.014 295.315 252.358 278.557C239.704 261.801 222.007 249.486 197.025 237.761C196.389 237.462 195.642 237.924 195.642 238.65V347C195.642 347.552 196.089 348 196.642 348H276.533C277.126 348 277.599 347.456 277.504 346.854Z"
        fill="#323232"
        stroke="#323232"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export default ClothesStyle9;
