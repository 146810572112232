import React from 'react';
import PreviewDefaultUserAvatar from './PreviewDefaultUserAvatar';
import PreviewUserAvatar from './PreviewSiteUserAvatar';

const SiteUserAvatar = ({ userValues }) => {
  if (userValues.select_style === 'default') {
    return <PreviewDefaultUserAvatar />;
  }
  return <PreviewUserAvatar userValues={userValues} />;
};

export default SiteUserAvatar;
