import React from 'react';

const ClothesStyle10 = () => {
  return (
    <>
      <path
        d="M125.543 237.032C126 236.821 126.666 236.989 126.999 237.566C143.207 265.672 182.476 263.469 194.709 237.821C195.04 237.127 195.788 236.922 196.267 237.139C223.347 249.412 241.129 262.467 253.272 279.512C265.429 296.577 272.084 317.849 276.512 346.856C276.604 347.458 276.132 348 275.538 348H44.4711C43.8783 348 43.4053 347.456 43.5 346.854C48.2834 316.396 56.1515 294.872 69.0418 278.036C81.933 261.199 99.9956 248.856 125.543 237.032Z"
        fill="#FDF2F8"
        stroke="url(#paint0_linear_4638_52061)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.861 238.018C131.272 280.56 132.331 305.668 127.363 346.987"
        stroke="url(#paint1_linear_4638_52061)"
        strokeWidth="4"
      />
      <path
        d="M196.43 238.52C192.018 281.062 190.96 306.17 195.927 347.489"
        stroke="url(#paint2_linear_4638_52061)"
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.776 290.646C232.048 285.421 224.525 285.421 222.798 290.646L222.132 292.66C221.359 294.997 219.143 296.579 216.642 296.579H214.487C208.896 296.579 206.571 303.609 211.095 306.839L212.838 308.083C214.861 309.528 215.708 312.087 214.935 314.424L214.269 316.438C212.541 321.663 218.627 326.008 223.151 322.779L224.894 321.534C226.917 320.09 229.656 320.09 231.679 321.534L233.423 322.779C237.946 326.008 244.032 321.663 242.305 316.438L241.639 314.424C240.866 312.087 241.712 309.528 243.735 308.083L245.479 306.839C250.002 303.609 247.678 296.579 242.086 296.579H239.931C237.431 296.579 235.215 294.997 234.442 292.66L233.776 290.646ZM228.287 312.328C231.474 312.328 234.059 309.788 234.059 306.656C234.059 303.524 231.474 300.985 228.287 300.985C225.099 300.985 222.515 303.524 222.515 306.656C222.515 309.788 225.099 312.328 228.287 312.328Z"
        fill="url(#paint3_linear_4638_52061)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4638_52061"
          x1="160"
          y1="234"
          x2="160"
          y2="350"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4638_52061"
          x1="130.653"
          y1="292.502"
          x2="126.861"
          y2="292.502"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4638_52061"
          x1="192.637"
          y1="293.004"
          x2="196.43"
          y2="293.004"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4638_52061"
          x1="228.287"
          y1="286.728"
          x2="228.287"
          y2="323.888"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
      </defs>
    </>
  );
};

export default ClothesStyle10;
