import { entryApi } from 'app/entryApi';

export const authorsInfo = entryApi.injectEndpoints({
  // eslint-disable-next-line prettier/prettier
  endpoints: (builder) => ({
    getAuthorsInfo: builder.query({ query: (params) => ({ url: '/user-stories', params }) }),
    loadMoreAuthorsStories: builder.query({
      query: (params) => ({ url: '/user-stories', params }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            authorsInfo.util.updateQueryData('getAuthorsInfo', { user_id: params.user_id }, (storeStories) => {
              storeStories.stories.push(...data.stories);
            })
          );
        } catch {}
      },
    }),
  }),
});
