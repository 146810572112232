import classNames from 'classnames/bind';

import { ReactComponent as PinnedStory } from 'assets/images/pinnedStoryIcon.svg';
import { ReactComponent as UnpinnedStory } from 'assets/images/unpinnedStoryIcon.svg';
import { ReactComponent as StoryPlaceholder } from 'assets/placeholders/story_placeholder.svg';

import styles from './StoryCard.module.scss';
import React, { useEffect, useState } from 'react';
import { userInfo } from 'features/userInfo/userInfoSlice';
import { modalOpen } from 'features/modal/modalSlice';
import constants from 'features/constants';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import { useGetModuleStoryByIdMutation } from 'features/moduleStory/moduleStory';
import {
  useAddToFavoriteStoryMutation,
  useRemoveFromFavoriteStoryMutation,
} from 'features/favoriteStory/favoriteStory';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';

type StoryCardProps = {
  className?: string;
  id?: string;
  type?: 'list';
};

export function StoryCard({ className, id, type }: StoryCardProps) {
  const getUserAllInfo = userInfo.useGetUserInfoRequestQuery(null);
  const dispatch = useAppDispatch();
  const setAnalyticsLogger = useAnalyticsLogger();
  const cn = classNames.bind(styles);
  const [storyData, setStoryData] = useState<any>();
  const [getModuleStoryById] = useGetModuleStoryByIdMutation();
  const [addToFavoriteStory] = useAddToFavoriteStoryMutation();
  const [removeFromFavoriteStory] = useRemoveFromFavoriteStoryMutation();

  const mainCls: string = cn({
    story_card: true,
    list: type === 'list',
    [String(className)]: className,
  });

  const handleFavStory = async (pinned, id) => {
    try {
      if (pinned) {
        setAnalyticsLogger('story_favourite_unpinned', { story_favourite_ID: id });
      } else {
        setAnalyticsLogger('story_favourite_pinned', { story_favourite_ID: id });
      }

      const result = await (pinned ? removeFromFavoriteStory(id) : addToFavoriteStory(id));

      if ('data' in result) {
        getStoryById(id);
      } else {
        console.error('error');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStoryById = async (id) => {
    try {
      const result = await getModuleStoryById(id);

      if ('data' in result) {
        setStoryData(result.data.story);
      } else {
        console.error('error');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getStoryById(id);
  }, [id]);

  const openStoryModal = (id) => {
    dispatch(
      modalOpen({
        modalType: constants.SINGLE_STORY,
        modalData: id,
        modalSize: 'xl',
      })
    );
  };

  if (!storyData || !Object.keys(storyData).length) return null;

  return (
    <div className={mainCls} id={id}>
      <div className={styles.story_preview_img}>
        {storyData?.storyMediaStack?.filter((it) => it.cover)[0]?.media?.url?.length > 0 ? (
          <img
            className={styles.story_preview_img}
            src={storyData?.storyMediaStack?.filter((it) => it.cover)[0]?.media?.url}
            alt="StoryImage"
          />
        ) : (
          <StoryPlaceholder className={styles.placeholder_img} />
        )}
        {getUserAllInfo?.currentData?.id !== storyData?.userId && (
          <button className={styles.action} onClick={() => handleFavStory(storyData.is_favorite, id)}>
            {storyData.is_favorite ? <PinnedStory /> : <UnpinnedStory />}
          </button>
        )}
      </div>
      <div
        className={styles.info}
        onClick={() => {
          openStoryModal(storyData.id);
          setAnalyticsLogger('story_view', { referrer: 'map', story_ID: storyData.id });
        }}>
        <p className={styles.author}>{storyData.fullName}</p>
        <h3 className={styles.story_preview_title}>{storyData.title}</h3>
        <p className={styles.story_preview_text}>{storyData.description}</p>
      </div>
    </div>
  );
}
