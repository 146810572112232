import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface getUserAccountInterface {
  onSettingPage: boolean;
}

const userAccountChoice = { onSettingPage: false };

const initialState: getUserAccountInterface = userAccountChoice;

export const userAccountSettingSlice = createSlice({
  name: 'userPageSetting',
  initialState,
  reducers: {
    setUserAccountChoiceData: (state, action: PayloadAction<getUserAccountInterface>) => {
      state.onSettingPage = action.payload.onSettingPage;
    },
  },
});
export const { setUserAccountChoiceData } = userAccountSettingSlice.actions;
export default userAccountSettingSlice.reducer;
export const getOnSettingPageState = (state: RootState) => state.userPageSetting.onSettingPage;
