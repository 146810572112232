export const languagesList = [
  {
    label: 'Afrikaans',
    native: 'Afrikaans',
    value: 'af',
    translateToText: 'Vertaal na',
    seeOriginalText: 'Sien oorspronklike',
  },
  {
    label: 'Albanian',
    native: 'gjuha shqipe',
    value: 'sq',
    translateToText: 'Përkthej',
    seeOriginalText: 'Shih origjinalin',
  },
  {
    label: 'Amharic',
    native: 'አማርኛ',
    value: 'am',
    translateToText: 'ወደ',
    seeOriginalText: 'ኦሪጅናል ይመልከቱ',
  },
  {
    label: 'Arabic',
    native: 'العربية',
    value: 'ar',
    translateToText: 'ترجمة الى',
    seeOriginalText: 'انظر الأصل',
  },
  {
    label: 'Armenian',
    native: 'Հայերեն',
    value: 'hy',
    translateToText: 'Թարգմանեք',
    seeOriginalText: 'Տես բնօրինակը',
  },
  {
    label: 'Azerbaijani',
    native: 'azərbaycan dili',
    value: 'az',
    translateToText: 'Tərcümə etmək',
    seeOriginalText: 'Orijinal baxın',
  },
  {
    label: 'Basque',
    native: 'euskara, euskera',
    value: 'eu',
    translateToText: 'Itzuli',
    seeOriginalText: 'Ikusi originala',
  },
  {
    label: 'Belarusian',
    native: 'беларуская мова',
    value: 'be',
    translateToText: 'Перавесці',
    seeOriginalText: 'См арыгінал',
  },
  {
    label: 'Bengali',
    native: 'বাংলা',
    value: 'bn',
    translateToText: 'অনুবাদ করুন',
    seeOriginalText: 'মূল দেখুন',
  },
  {
    label: 'Bosnian',
    native: 'bosanski jezik',
    value: 'bs',
    translateToText: 'Prevesti na',
    seeOriginalText: 'Pogledajte original',
  },
  {
    label: 'Bulgarian',
    native: 'български език',
    value: 'bg',
    translateToText: 'Превод',
    seeOriginalText: 'Вижте оригинала',
  },
  {
    label: 'Catalan',
    native: 'català',
    value: 'ca',
    translateToText: 'Traduir al',
    seeOriginalText: 'Veure original',
  },
  {
    label: 'Cebuano',
    native: 'Cebuano',
    value: 'ceb',
    translateToText: 'Paghubad sa',
    seeOriginalText: 'Tan-awa ang Orihinal',
  },
  {
    label: 'Chinese (Simplified)',
    native: '简体中文',
    value: 'zh-CN',
    translateToText: '翻译成',
    seeOriginalText: '看原创',
  },
  {
    label: 'Chinese (Simplified)',
    native: '简体中文',
    value: 'zh',
    translateToText: '翻译成',
    seeOriginalText: '看原创',
  },
  {
    label: 'Chinese (Traditional)',
    native: '中國傳統的',
    value: 'zh-TW',
    translateToText: '翻譯成',
    seeOriginalText: '看原創',
  },
  {
    label: 'Corsican',
    native: 'corsu, lingua corsa',
    value: 'co',
    translateToText: 'Traduce à',
    seeOriginalText: 'Vede uriginale',
  },
  {
    label: 'Croatian',
    native: 'hrvatski jezik',
    value: 'hr',
    translateToText: 'Prevedi na',
    seeOriginalText: 'Vidjeti izvornik',
  },
  {
    label: 'Czech',
    native: 'čeština, český jazyk',
    value: 'cs',
    translateToText: 'Přeložit do',
    seeOriginalText: 'Viz originál',
  },
  {
    label: 'Danish',
    native: 'dansk',
    value: 'da',
    translateToText: 'Oversæt til',
    seeOriginalText: 'Se original',
  },
  {
    label: 'Dutch',
    native: 'Nederlands, Vlaams',
    value: 'nl',
    translateToText: 'Vertalen naar',
    seeOriginalText: 'Zie origineel',
  },
  {
    label: 'English',
    native: 'English',
    value: 'en',
    translateToText: 'Translate to',
    seeOriginalText: 'See original',
  },
  {
    label: 'Esperanto',
    native: 'Esperanto',
    value: 'eo',
    translateToText: 'Traduki al',
    seeOriginalText: 'Vidu originalon',
  },
  {
    label: 'Estonian',
    native: 'eesti, eesti keel',
    value: 'et',
    translateToText: 'Tõlkima',
    seeOriginalText: 'Originaal',
  },
  {
    label: 'Finnish',
    native: 'suomi, suomen kieli',
    value: 'fi',
    translateToText: 'Käännä',
    seeOriginalText: 'Katso alkuperäinen',
  },
  {
    label: 'French',
    native: 'français, langue française',
    value: 'fr',
    translateToText: 'Traduire en',
    seeOriginalText: 'Voir',
  },
  {
    label: 'Frisian',
    native: 'Frysk',
    value: 'fy',
    translateToText: 'Oersette nei',
    seeOriginalText: 'Sjoch orizjineel',
  },
  {
    label: 'Galician',
    native: 'galego',
    value: 'gl',
    translateToText: 'Traducir a.',
    seeOriginalText: 'Ver orixinal',
  },
  {
    label: 'Georgian',
    native: 'ქართული',
    value: 'ka',
    translateToText: 'თარგმნა',
    seeOriginalText: 'იხილეთ ორიგინალი',
  },
  {
    label: 'German',
    native: 'Deutsch',
    value: 'de',
    translateToText: 'Übersetzen ins',
    seeOriginalText: 'Siehe original.',
  },
  {
    label: 'Greek',
    native: 'Ελληνικά',
    value: 'el',
    translateToText: 'Μεταφράζομαι σε',
    seeOriginalText: 'Βλέπε πρωτότυπο',
  },
  {
    label: 'Gujarati',
    native: 'ગુજરાતી',
    value: 'gu',
    translateToText: 'ભાષાંતર કરવું',
    seeOriginalText: 'મૂળ જુઓ',
  },
  {
    label: 'Haitian Creole',
    native: 'Kreyòl Ayisyen',
    value: 'ht',
    translateToText: 'Tradui a',
    seeOriginalText: 'Wè orijinal la',
  },
  {
    label: 'Hausa',
    native: 'Hausa, هَوُسَ',
    value: 'ha',
    translateToText: 'Fassara zuwa',
    seeOriginalText: 'Duba na asali',
  },
  {
    label: 'Hawaiian',
    native: 'Ōlelo Hawaiʻi',
    value: 'haw',
    translateToText: 'Unuhi i',
    seeOriginalText: 'See lative',
  },
  {
    label: 'Hebrew',
    native: 'עברית',
    value: 'he',
    translateToText: 'לתרגם ל',
    seeOriginalText: 'ראה המקורי',
  },
  {
    label: 'Hindi',
    native: 'हिन्दी, हिंदी',
    value: 'hi',
    translateToText: 'अनुवाद करने के लिए',
    seeOriginalText: 'मूल देखें',
  },
  {
    label: 'Hmong',
    native: 'Hmoob',
    value: 'hmn',
    translateToText: 'Txhais Rau',
    seeOriginalText: 'Saib daim tseem',
  },
  {
    label: 'Hungarian',
    native: 'magyar',
    value: 'hu',
    translateToText: 'Lefordít',
    seeOriginalText: 'Lásd az eredetit',
  },
  {
    label: 'Icelandic',
    native: 'Íslenska',
    value: 'is',
    translateToText: 'Þýða til',
    seeOriginalText: 'Sjá upprunalega.',
  },
  {
    label: 'Igbo',
    native: 'Asụsụ Igbo',
    value: 'ig',
    translateToText: 'Tughari nye',
    seeOriginalText: 'Lee ụzọ mbụ',
  },
  {
    label: 'Indonesian',
    native: 'Bahasa Indonesia',
    value: 'id',
    translateToText: 'Terjemahkan ke',
    seeOriginalText: 'Lihat asli',
  },
  {
    label: 'Irish',
    native: 'Gaeilge',
    value: 'ga',
    translateToText: 'Aistriú go',
    seeOriginalText: 'Féach Bunaidh',
  },
  {
    label: 'Italian',
    native: 'italiano',
    value: 'it',
    translateToText: 'Traduci in',
    seeOriginalText: 'Vedi originale.',
  },
  {
    label: 'Japanese',
    native: '日本語 (にほんご)',
    value: 'ja',
    translateToText: '翻訳',
    seeOriginalText: 'オリジナルを参照してください',
  },
  {
    label: 'Javanese',
    native: 'basa Jawa',
    value: 'jv',
    translateToText: 'Translate menyang',
    seeOriginalText: 'Ndeleng asli',
  },
  {
    label: 'Kannada',
    native: 'ಕನ್ನಡ',
    value: 'kn',
    translateToText: 'ಗೆ ಭಾಷಾಂತರಿಸಿ',
    seeOriginalText: 'ಮೂಲವನ್ನು ನೋಡಿ',
  },
  {
    label: 'Kazakh',
    native: 'қазақ тілі',
    value: 'kk',
    translateToText: 'Аударыңыз',
    seeOriginalText: 'Түпнұсқасын қараңыз',
  },
  {
    label: 'Khmer',
    native: 'ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ',
    value: 'km',
    translateToText: 'បកប្រែទៅ',
    seeOriginalText: 'សូមមើលដើម',
  },
  {
    label: 'Kinyarwanda',
    native: 'Ikinyarwanda',
    value: 'rw',
    translateToText: 'Sobanura',
    seeOriginalText: 'Reba umwimerere',
  },
  {
    label: 'Korean',
    native: '한국어 (韓國語), 조선어 (朝鮮語)',
    value: 'ko',
    translateToText: '번역하다',
    seeOriginalText: '원본을 참조하십시오',
  },
  {
    label: 'Kurdish',
    native: 'Kurdî, كوردی‎',
    value: 'ku',
    translateToText: 'Wergerandin',
    seeOriginalText: 'Orjînal binêrin',
  },
  {
    label: 'Kyrgyz',
    native: 'Кыргызча, Кыргыз тили',
    value: 'ky',
    translateToText: 'Которууга',
    seeOriginalText: 'Оригиналдуу караңыз',
  },
  {
    label: 'Lao',
    native: 'ພາສາລາວ',
    value: 'lo',
    translateToText: 'ເວົ້າເຖິງ',
    seeOriginalText: 'ເບິ່ງຕົ້ນສະບັບ',
  },
  {
    label: 'Latvian',
    native: 'latviešu valoda',
    value: 'lv',
    translateToText: 'Tulkot uz',
    seeOriginalText: 'Skatīt oriģinālu',
  },
  {
    label: 'Lithuanian',
    native: 'lietuvių kalba',
    value: 'lt',
    translateToText: 'Versti į',
    seeOriginalText: 'Žr. Originalą',
  },
  {
    label: 'Luxembourgish',
    native: 'Lëtzebuergesch',
    value: 'lb',
    translateToText: 'Iwwersetzen do',
    seeOriginalText: 'Kuckt original',
  },
  {
    label: 'Macedonian',
    native: 'македонски јазик',
    value: 'mk',
    translateToText: 'Преведете да',
    seeOriginalText: 'Погледнете го оригиналот',
  },
  {
    label: 'Malagasy',
    native: 'fiteny malagasy',
    value: 'mg',
    translateToText: 'Handika to',
    seeOriginalText: 'Jereo Original',
  },
  {
    label: 'Malay',
    native: 'bahasa Melayu, بهاس ملايو‎',
    value: 'ms',
    translateToText: 'Terjemah ke.',
    seeOriginalText: 'Lihat asal',
  },
  {
    label: 'Malayalam',
    native: 'മലയാളം',
    value: 'ml',
    translateToText: 'വിവർത്തനം ചെയ്യുക',
    seeOriginalText: 'ഒറിജിനൽ കാണുക',
  },
  {
    label: 'Maltese',
    native: 'Malti',
    value: 'mt',
    translateToText: 'Ittraduċi għal',
    seeOriginalText: 'Ara oriġinali',
  },
  {
    label: 'Maori',
    native: 'Maori',
    value: 'mi',
    translateToText: 'Whakamāori ki te whakawhiti ki',
    seeOriginalText: 'Tirohia te taketake',
  },
  {
    label: 'Marathi',
    native: 'मराठी',
    value: 'mr',
    translateToText: 'यात अनुवादित करा',
    seeOriginalText: 'मूळ पहा',
  },
  {
    label: 'Mongolian',
    native: 'монгол',
    value: 'mn',
    translateToText: 'Эвдөр орчуулах',
    seeOriginalText: 'Анхны харах',
  },
  {
    label: 'Myanmar (Burmese)',
    native: 'မြန်မာ (ဗမာ)',
    value: 'my',
    translateToText: 'ဘာသာပြန်ရန်',
    seeOriginalText: 'မူရင်းကိုကြည့်ပါ',
  },
  {
    label: 'Nepali',
    native: 'नेपाली',
    value: 'ne',
    translateToText: 'अनुवाद गर्न',
    seeOriginalText: 'सक्कली हेर्नुहोस्',
  },
  {
    label: 'Norwegian',
    native: 'Norsk',
    value: 'no',
    translateToText: 'Oversett til.',
    seeOriginalText: 'Se original',
  },
  {
    label: 'Nyanja (Chichewa)',
    native: 'Chinyanja (ChiCheŵa)',
    value: 'ny',
    translateToText: 'Tanthauzirani',
    seeOriginalText: 'Onani Chiyambi',
  },
  {
    label: 'Odia (Oriya)',
    native: 'ଓଡ଼ିଆ',
    value: 'or',
    translateToText: 'ଅନୁବାଦ କର',
    seeOriginalText: 'ମୂଳ ଦେଖନ୍ତୁ |',
  },
  {
    label: 'Pashto',
    native: 'پښتو',
    value: 'ps',
    translateToText: 'ته ژباړه',
    seeOriginalText: 'اصلي وګورئ',
  },
  {
    label: 'Persian',
    native: 'فارسی',
    value: 'fa',
    translateToText: 'ترجمه به',
    seeOriginalText: 'نگاه کنید به اصل',
  },
  {
    label: 'Polish',
    native: 'język polski, polszczyzna',
    value: 'pl',
    translateToText: 'Przetłumacz na',
    seeOriginalText: 'Zobacz oryginał',
  },
  {
    label: 'Portuguese (Portugal, Brazil)',
    native: 'Português',
    value: 'pt',
    translateToText: 'Traduza para',
    seeOriginalText: 'Veja original',
  },
  {
    label: 'Punjabi',
    native: 'پنجابی',
    value: 'pa',
    translateToText: 'ਦਾ ਅਨੁਵਾਦ',
    seeOriginalText: 'ਅਸਲੀ ਦੇਖੋ',
  },
  {
    label: 'Romanian',
    native: 'limba română',
    value: 'ro',
    translateToText: 'Tradu in',
    seeOriginalText: 'Vedeți originalul',
  },
  {
    label: 'Russian',
    native: 'русский язык',
    value: 'ru',
    translateToText: 'Перевести на',
    seeOriginalText: 'Увидеть оригинал',
  },
  {
    label: 'Samoan',
    native: 'gagana fa`a Samoa',
    value: 'sm',
    translateToText: 'Faaliliu i',
    seeOriginalText: 'Vaai muamua',
  },
  {
    label: 'Scots Gaelic',
    native: 'Gàidhlig na h-Alba',
    value: 'gd',
    translateToText: 'Eadar-theangachadh gu',
    seeOriginalText: 'Faic tùsail',
  },
  {
    label: 'Serbian',
    native: 'српски језик',
    value: 'sr',
    translateToText: 'Превести на',
    seeOriginalText: 'Види оригинал',
  },
  {
    label: 'Sesotho',
    native: 'Sesotho',
    value: 'st',
    translateToText: 'Fetolela ho',
    seeOriginalText: 'Bona ea mantlha',
  },
  {
    label: 'Shona',
    native: 'chiShona',
    value: 'sn',
    translateToText: 'Dudziro kuna',
    seeOriginalText: 'Ona Original',
  },
  {
    label: 'Sindhi',
    native: 'सिन्धी, سنڌي، سندھی‎',
    value: 'sd',
    translateToText: 'جو ترجمو ڪريو',
    seeOriginalText: 'اصل ڏسو',
  },
  {
    label: 'Sinhala (Sinhalese)',
    native: 'සිංහල',
    value: 'si',
    translateToText: 'වෙත පරිවර්තනය කරන්න',
    seeOriginalText: 'මුල් පිටපත බලන්න',
  },
  {
    label: 'Slovak',
    native: 'slovenčina, slovenský jazyk',
    value: 'sk',
    translateToText: 'Preložiť do',
    seeOriginalText: 'Pozri originál',
  },
  {
    label: 'Slovenian',
    native: 'Slovenščina',
    value: 'sl',
    translateToText: 'Prevedi k.',
    seeOriginalText: 'Glejte Original.',
  },
  {
    label: 'Somali',
    native: 'Soomaaliga, af Soomaali',
    value: 'so',
    translateToText: 'U turjun',
    seeOriginalText: 'Eeg asalka',
  },
  {
    label: 'Spanish',
    native: 'Español',
    value: 'es',
    translateToText: 'Traducir a',
    seeOriginalText: 'Ver original',
  },
  {
    label: 'Sundanese',
    native: 'Basa Sunda',
    value: 'su',
    translateToText: 'Narjamahkeun ka',
    seeOriginalText: 'Tingali aslina',
  },
  {
    label: 'Swahili',
    native: 'Kiswahili',
    value: 'sw',
    translateToText: 'Tafsiri kwa',
    seeOriginalText: 'Angalia awali',
  },
  {
    label: 'Swedish',
    native: 'Svenska',
    value: 'sv',
    translateToText: 'Översätt till',
    seeOriginalText: 'Se original',
  },
  {
    label: 'Tagalog (Filipino)',
    native: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
    value: 'tl',
    translateToText: 'Isalin sa.',
    seeOriginalText: 'Tingnan ang orihinal',
  },
  {
    label: 'Tajik',
    native: 'тоҷикӣ, toğikī, تاجیکی‎',
    value: 'tg',
    translateToText: 'Тарҷума ба',
    seeOriginalText: 'Нусхаи аслро бубинед',
  },
  {
    label: 'Tamil',
    native: 'தமிழ்',
    value: 'ta',
    translateToText: 'மொழிபெயர்க்கவும்',
    seeOriginalText: 'அசல் பார்க்க',
  },
  {
    label: 'Tatar',
    native: 'татар теле, tatar tele',
    value: 'tt',
    translateToText: 'Тәрҗемә итү',
    seeOriginalText: 'Оригиналь кара',
  },
  {
    label: 'Telugu',
    native: 'తెలుగు',
    value: 'te',
    translateToText: 'అనువదించు',
    seeOriginalText: 'అసలు చూడండి',
  },
  {
    label: 'Thai',
    native: 'ไทย',
    value: 'th',
    translateToText: 'แปลเป็น',
    seeOriginalText: 'ดูต้นฉบับ',
  },
  {
    label: 'Turkish',
    native: 'Türkçe',
    value: 'tr',
    translateToText: 'Çevirmek',
    seeOriginalText: 'Orijinal görmek',
  },
  {
    label: 'Turkmen',
    native: 'Türkmen, Түркмен',
    value: 'tk',
    translateToText: 'Terjime et',
    seeOriginalText: 'Asyl görüň',
  },
  {
    label: 'Ukrainian',
    native: 'українська мова',
    value: 'uk',
    translateToText: 'Перекладати',
    seeOriginalText: 'Див',
  },
  {
    label: 'Urdu',
    native: 'اردو',
    value: 'ur',
    translateToText: 'ترجمہ کریں',
    seeOriginalText: 'اصل دیکھیں',
  },
  {
    label: 'Uyghur',
    native: 'Uyƣurqə',
    value: 'ug',
    translateToText: 'تەرجىمە قىلىڭ',
    seeOriginalText: 'ئەسلى كۆرۈڭ',
  },
  {
    label: 'Uzbek',
    native: 'O‘zbek, Ўзбек, أۇزبېك‎',
    value: 'uz',
    translateToText: 'Tarjima qilmoq',
    seeOriginalText: 'Aslga qarang',
  },
  {
    label: 'Vietnamese',
    native: 'Tiếng Việt',
    value: 'vi',
    translateToText: 'Dịch sang',
    seeOriginalText: 'Xem bản gốc',
  },
  {
    label: 'Welsh',
    native: 'Cymraeg',
    value: 'cy',
    translateToText: 'Trosi',
    seeOriginalText: 'Gweler y gwreiddiol',
  },
  {
    label: 'Xhosa',
    native: 'isiXhosa',
    value: 'xh',
    translateToText: 'Guqula',
    seeOriginalText: 'Bona Owokuqala',
  },
  {
    label: 'Yiddish',
    native: 'ייִדיש',
    value: 'yi',
    translateToText: 'יבערזעצן צו',
    seeOriginalText: 'זען אָריגינעל',
  },
  {
    label: 'Yoruba',
    native: 'Yorùbá',
    value: 'yo',
    translateToText: 'Tumọ si',
    seeOriginalText: 'Wo atilẹba',
  },
  {
    label: 'Zulu',
    native: 'isiZulu',
    value: 'zu',
    translateToText: 'Humusha ku-',
    seeOriginalText: 'Bona okwangempela',
  },
];
