import React, { useState } from 'react';
import styles from '../Customize.module.scss';
import BackgroundColorPattern from 'components/CustomizePlatform/components/BackgroundColorPattern';
import BackgroundSidebarPattern from 'components/CustomizePlatform/components/BackgroundSidebarPattern';

const SiteSetting = ({ initialValues, handleChange, setFieldValue }) => {
  const [backgroundColorPattern, setBackgroundColorPattern] = useState(initialValues.background_color_sidebar_pattern);
  return (
    <div className={styles.site_setting}>
      <BackgroundColorPattern
        setFieldValue={setFieldValue}
        setBackgroundColorPattern={setBackgroundColorPattern}
        initialValues={initialValues}
        handleChange={handleChange}
      />

      <BackgroundSidebarPattern
        backgroundColorPattern={backgroundColorPattern}
        initialValues={initialValues}
        handleChange={handleChange}
      />
    </div>
  );
};

export default SiteSetting;
