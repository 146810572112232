import { useEffect, useRef, useState } from 'react';
import styles from './StoryFilter.module.scss';
import { ReactComponent as ResizeIcon } from 'assets/icons/resizeIcon.svg';
import SquareRadioButton from 'components/SquareRadioButton/SquareRadioButton';
import { useViewportCheck } from 'app/hooks/useViewportCheck';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { CSSTransition } from 'react-transition-group';
import animationClasses from 'features/animation';

interface propsInterface {
  getlocalizationInfo?: any;
  handleFilter?: Function;
  handleResizeMap?: Function;
  resizedMap?: boolean;
}

const StoryFilter = ({ getlocalizationInfo, handleFilter, handleResizeMap, resizedMap }: propsInterface) => {
  const ref = useRef(null);
  const refFl = useRef(null);

  const isMobile = useViewportCheck('mobile');
  const [selected, setSelected] = useState('students');

  const [showFilter, setShowFilter] = useState(false);

  const filterOptions = [
    {
      name: 'students',
      label: getlocalizationInfo?.students_stories,
    },
    {
      name: 'favourite',
      label: getlocalizationInfo?.favorite_stories,
    },
  ];

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && refFl.current && !refFl.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    handleFilter(selected);
  }, [selected]);

  return (
    <div className={styles.story_filter_wrap}>
      {isMobile ? (
        <>
          <button className={styles.filter_icon} onClick={() => setShowFilter(!showFilter)} ref={refFl}>
            <FilterIcon />
          </button>
          {showFilter && (
            <>
              <CSSTransition in={showFilter} timeout={200} classNames={animationClasses} unmountOnExit nodeRef={ref}>
                <div className={`${styles.opened_filter} ${resizedMap && styles.top}`} ref={ref}>
                  {filterOptions.map((filter, index) => (
                    <div className={styles.story_filter_item} key={index}>
                      <SquareRadioButton
                        name={filter.name}
                        selected={selected}
                        label={filter.label}
                        handleChange={setSelected}
                      />
                    </div>
                  ))}
                </div>
              </CSSTransition>
            </>
          )}
        </>
      ) : (
        <>
          {filterOptions.map((filter, index) => (
            <div className={styles.story_filter_item} key={index}>
              <SquareRadioButton
                name={filter.name}
                selected={selected}
                label={filter.label}
                handleChange={setSelected}
              />
            </div>
          ))}
        </>
      )}

      <button className={styles.resize} onClick={() => handleResizeMap()}>
        <ResizeIcon />
      </button>
    </div>
  );
};

export default StoryFilter;
