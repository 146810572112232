import React from 'react';

const AccessoriesStyle3 = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.01 66.3373L204.308 39.0776C202.569 36.6715 198.902 36.9335 197.519 39.5626L179.873 73.106C178.49 75.7351 180.356 78.8966 183.328 78.9587L216.99 79.6621L236.691 106.922C238.43 109.328 242.098 109.066 243.481 106.437L261.126 72.8934C262.509 70.2643 260.643 67.1028 257.671 67.0407L224.01 66.3373Z"
        fill="#0284C7"
      />
      <ellipse
        rx="11.0368"
        ry="10.6271"
        transform="matrix(0.89881 0.438338 -0.439564 0.898211 220.179 73.0004)"
        fill="#075985"
      />
    </>
  );
};

export default AccessoriesStyle3;
