import React from 'react';

const ClothesStyle6 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59350)">
        <path
          d="M146.771 127.225C146.814 127.205 146.877 127.221 146.908 127.275C148.428 129.911 152.11 129.704 153.257 127.299C153.288 127.234 153.358 127.215 153.403 127.235C155.942 128.386 157.609 129.61 158.748 131.208C159.888 132.809 160.512 134.803 160.927 137.523C160.936 137.579 160.891 137.63 160.836 137.63H139.17C139.114 137.63 139.07 137.579 139.079 137.523C139.527 134.667 140.265 132.649 141.474 131.07C142.682 129.491 144.376 128.334 146.771 127.225Z"
          fill="white"
          stroke="#5B21B6"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.652 129.926H157.363L157.55 130.082L158.019 130.598L158.394 131.02L158.676 131.433H141.339L141.948 130.645L142.652 129.926Z"
          fill="#C084FC"
        />
        <path
          d="M140.766 132.52L159.285 132.473L159.66 133.223L159.936 133.976H140.138L140.391 133.223L140.766 132.52Z"
          fill="#C084FC"
        />
        <path d="M139.782 135.106H160.27L160.598 136.612H139.407L139.782 135.106Z" fill="#C084FC" />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle6;
