import React, { useEffect, useState, useRef } from 'react';
import styles from './SingleStory.module.scss';
import LangSwitcher from 'components/LangSwitcher/LangSwitcher';
import StoryContent from './StoryContent';
import StoryHeader from './StoryHeader';
import UserInfo from './UserInfo';
import RandomStory from 'components/common/Navbar/NavHeader/RandomStory';
import PageHeader from 'components/PageHeader/PageHeader';
import { modalClose } from 'features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import { storiesApi, useGetModuleStoryByIdMutation } from 'features/moduleStory/moduleStory';
import { localizationSite } from 'features/localization/localizationSlice';
import { authorsInfo } from 'features/authorsInfo/AuthorsInfo';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';
import { getLanguage } from 'app/hooks/useGoogleTranslations';
import { languagesList } from 'additionalInfo/languageList';
import FavoriteStoryToggle from 'components/common/Navbar/NavHeader/FavoriteStoryToggle/FavoriteStoryToggle';
import { CSSTransition } from 'react-transition-group';
import animationClasses from 'features/animation';
import { useViewportCheck } from 'app/hooks/useViewportCheck';
import { userInfo } from 'features/userInfo/userInfoSlice';

interface requestParamsInterface {
  user_id?: string;
}

const SingleStory = () => {
  const dispatch = useAppDispatch();
  const setAnalyticsLogger = useAnalyticsLogger();
  const ref = useRef(null);
  const { modalData } = useAppSelector((state) => state.modal);
  const { module_id: moduleId } = useAppSelector((state) => state.currentModule);
  const [requestParams, setRequestParams] = useState<requestParamsInterface>({});

  const lang = useAppSelector((state) => state.login.acceptLanguage);
  const { data: getlocalizationInfo } = localizationSite.useGetTranslationsQuery(lang);
  const { data: moduleStoriesData }: any = storiesApi.useGetModuleStoriesQuery({ moduleId });

  const [randomStoryId, setRandomStoryId] = useState(
    moduleStoriesData?.stories[Math.floor(Math.random() * moduleStoriesData?.stories?.length)]?.id
  );
  const [userInfomation, setUserInfo] = useState(false);
  const [userInfoData, setUserInfoData] = useState([]);

  const [storyData, setStoryData]: any = useState();
  const getUserAllInfo = userInfo.useGetUserInfoRequestQuery(null);
  const [langToShowStory, setLangToShowStory]: any = useState(getLanguage);

  const [getMoreStories] = authorsInfo.useLazyLoadMoreAuthorsStoriesQuery();
  const [getModuleStoryById] = useGetModuleStoryByIdMutation();

  const closeModal = () => dispatch(modalClose());
  const handleLoadMoreStories = (data) => getMoreStories(data);

  const isMobileView = useViewportCheck('mobile', 1100);
  const iconsVariant = isMobileView ? 'square' : undefined;

  const getStoryById = async (id) => {
    try {
      const result = await getModuleStoryById(id);
      if ('data' in result) {
        setStoryData(result.data.story);
        setUserInfo(false);
      } else {
        console.error('error');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getStoryById(modalData);
  }, []);

  const handleRandomStory = () => {
    setAnalyticsLogger('story_view', { referrer: 'random', story_ID: randomStoryId });
    getStoryById(randomStoryId);
    setRandomStoryId(moduleStoriesData?.stories[Math.floor(Math.random() * moduleStoriesData?.stories?.length)]?.id);
  };

  const handleAuthorsInfo = async (id) => {
    setRequestParams({ user_id: id });
    setUserInfo(true);
  };

  return (
    <div className={styles.single_story_wrap}>
      {storyData && (
        <>
          <PageHeader title={storyData?.module?.title} backLink={closeModal}>
            <div className={styles.actions}>
              <RandomStory
                getlocalizationInfo={getlocalizationInfo}
                handleRandomStory={handleRandomStory}
                variant={iconsVariant}
              />
              <LangSwitcher languagesList={languagesList} changeLanguage={setLangToShowStory} variant={iconsVariant} />
              {getUserAllInfo?.currentData?.id !== storyData?.userId && <FavoriteStoryToggle storyData={storyData} />}
            </div>
          </PageHeader>
          <StoryHeader storyData={storyData} handleAuthorsInfo={handleAuthorsInfo} />
          <StoryContent
            langToShowStory={langToShowStory}
            storyData={storyData}
            getlocalizationInfo={getlocalizationInfo}
            handleRandomStory={handleRandomStory}
            authorsData={userInfoData}
          />
          <CSSTransition in={userInfomation} timeout={200} classNames={animationClasses} unmountOnExit nodeRef={ref}>
            <UserInfo
              setUserInfo={setUserInfo}
              handleAuthorStory={getStoryById}
              handleLoadMoreStories={handleLoadMoreStories}
              requestParams={requestParams}
              setUserInfoData={setUserInfoData}
            />
          </CSSTransition>
        </>
      )}
    </div>
  );
};

export default SingleStory;
