import React from 'react';

const HairStyle8 = ({ userColor }) => {
  return (
    <>
      <path
        d="M285.216 224C291.828 224 297.208 218.626 296.994 212.002C296.174 186.686 292.766 161.755 286.897 138.279C280.103 111.102 270.146 86.4084 257.592 65.6081C245.039 44.8078 230.136 28.308 213.734 17.051C197.332 5.79393 179.753 -1.28582e-06 162 0C144.247 1.28582e-06 126.668 5.79393 110.266 17.051C93.8641 28.308 78.9611 44.8078 66.4077 65.6081C53.8544 86.4084 43.8965 111.102 37.1027 138.279C31.2341 161.755 27.8257 186.686 27.0062 212.002C26.7918 218.626 32.1723 224 38.7845 224L285.216 224Z"
        fill={userColor}
      />
    </>
  );
};

export default HairStyle8;
