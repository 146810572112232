import React from 'react';

const ClothesStyle12 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59438)">
        <path
          d="M146.772 127.226C146.815 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.943 128.387 157.61 129.611 158.749 131.209C159.888 132.809 160.512 134.804 160.928 137.523C160.936 137.58 160.892 137.631 160.836 137.631H139.17C139.115 137.631 139.07 137.58 139.079 137.523C139.528 134.667 140.265 132.649 141.474 131.07C142.683 129.492 144.376 128.334 146.772 127.226Z"
          fill="#BAE6FD"
          stroke="#0369A1"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.788 128.983L146.455 132.753C146.488 132.801 146.556 132.806 146.597 132.765L150.028 129.335C150.065 129.297 150.127 129.298 150.163 129.337L153.318 132.763C153.357 132.805 153.425 132.802 153.461 132.757L156.406 128.983"
          stroke="#0369A1"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx="150.191" cy="132.373" rx="0.89453" ry="0.894628" fill="#0369A1" />
        <ellipse cx="150.191" cy="134.916" rx="0.89453" ry="0.894628" fill="#0369A1" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8158_59438"
          x1="164.5"
          y1="110.9"
          x2="150"
          y2="138"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBB9B5" />
          <stop offset="1" stopColor="#FBF0D0" />
        </linearGradient>
        <clipPath id="clip0_8158_59438">
          <rect width="30.38" height="32.8179" fill="white" transform="translate(135 105)" />
        </clipPath>
      </defs>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle12;
