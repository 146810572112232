import React from 'react';

const ClothesStyle10 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59409)">
        <path
          d="M146.771 127.226C146.814 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.942 128.387 157.609 129.611 158.748 131.209C159.888 132.809 160.512 134.804 160.927 137.523C160.936 137.58 160.891 137.631 160.836 137.631H139.17C139.114 137.631 139.07 137.58 139.079 137.523C139.527 134.667 140.265 132.649 141.474 131.07C142.682 129.492 144.376 128.334 146.771 127.226Z"
          fill="#FDF2F8"
          stroke="url(#paint1_linear_8158_59409)"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.895 127.318C147.309 131.307 147.408 133.661 146.942 137.535"
          stroke="url(#paint2_linear_8158_59409)"
          strokeWidth="0.375062"
        />
        <path
          d="M153.418 127.365C153.004 131.354 152.905 133.708 153.371 137.583"
          stroke="url(#paint3_linear_8158_59409)"
          strokeWidth="0.375062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.92 132.253C156.758 131.763 156.053 131.763 155.891 132.253L155.828 132.442C155.756 132.661 155.548 132.809 155.314 132.809H155.112C154.587 132.809 154.369 133.468 154.793 133.771L154.957 133.888C155.147 134.023 155.226 134.263 155.154 134.482L155.091 134.671C154.929 135.161 155.5 135.568 155.924 135.266L156.087 135.149C156.277 135.013 156.534 135.013 156.724 135.149L156.887 135.266C157.311 135.568 157.882 135.161 157.72 134.671L157.657 134.482C157.585 134.263 157.664 134.023 157.854 133.888L158.017 133.771C158.442 133.468 158.224 132.809 157.699 132.809H157.497C157.263 132.809 157.055 132.661 156.983 132.442L156.92 132.253ZM156.405 134.286C156.704 134.286 156.947 134.048 156.947 133.754C156.947 133.46 156.704 133.222 156.405 133.222C156.107 133.222 155.864 133.46 155.864 133.754C155.864 134.048 156.107 134.286 156.405 134.286Z"
          fill="url(#paint4_linear_8158_59409)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8158_59409"
          x1="164.5"
          y1="110.9"
          x2="150"
          y2="138"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBB9B5" />
          <stop offset="1" stopColor="#FBF0D0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8158_59409"
          x1="150.002"
          y1="126.941"
          x2="150.002"
          y2="137.818"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8158_59409"
          x1="147.25"
          y1="132.427"
          x2="146.895"
          y2="132.427"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8158_59409"
          x1="153.062"
          y1="132.474"
          x2="153.418"
          y2="132.474"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8158_59409"
          x1="156.405"
          y1="131.885"
          x2="156.405"
          y2="135.37"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#923260" />
          <stop offset="0.0001" stopColor="#C94584" />
          <stop offset="0.953125" stopColor="#C86E99" />
        </linearGradient>
        <clipPath id="clip0_8158_59409">
          <rect width="30.38" height="32.8179" fill="white" transform="translate(135 105)" />
        </clipPath>
      </defs>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle10;
