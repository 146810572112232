const RenderColorText = (userPattern) => {
  switch (userPattern) {
    case 'pattern_1':
      return '#4F88B3';
    case 'pattern_2':
      return '#669177';
    case 'pattern_3':
      return '#9b688e';
    case 'pattern_4':
      return '#4f3687';
    case 'pattern_5':
      return '#9d6d49';
    case 'pattern_6':
      return '#fff';
    default:
      return '#4F88B3';
  }
};

export default RenderColorText;
