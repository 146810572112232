import React from 'react';

const HairStyle2 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M141.939 113.345C141.939 108.788 145.633 105.094 150.19 105.094C154.747 105.094 158.441 108.788 158.441 113.345V122.44C158.441 123.89 157.266 125.066 155.816 125.066H144.564C143.114 125.066 141.939 123.89 141.939 122.44V113.345Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyle2;
