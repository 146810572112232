import styles from './StoryPreview.module.scss';
import React, { MouseEventHandler } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useViewportCheck } from 'app/hooks/useViewportCheck';
import classNames from 'classnames/bind';
import useLocalization from 'app/hooks/useLocalization';
import { ReactComponent as CloseIcon } from 'assets/icons/closeStory.svg';

interface propsInterface {
  resizedMap?: boolean;
  refPr?: any;
  children?: JSX.Element | JSX.Element[];
  type?: 'mobile' | 'full_map' | 'default';
  handleClose?: MouseEventHandler<HTMLElement | SVGElement>;
}

const StoryPreview = ({ resizedMap, refPr, children, handleClose }: propsInterface) => {
  const isMobile = useViewportCheck('mobile');
  const cn = classNames.bind(styles);
  const { toLocalize, isLoading } = useLocalization();
  const isMapMobileViw = resizedMap && isMobile;

  const storyCls = cn({
    story_preview_wrap: true,
    full_map_mobile: isMapMobileViw,
    // mobile: type === 'mobile',
    default_mobile: !resizedMap && isMobile,
  });

  return (
    <div className={storyCls} ref={refPr}>
      <h3 className={styles.title}>
        {isLoading ? '...' : toLocalize('story_on_location')}
        {isMapMobileViw && <CloseIcon onClick={handleClose} className={styles.close} />}
      </h3>
      <SimpleBar className={styles.list}>{children}</SimpleBar>
    </div>
  );
};

export default StoryPreview;
