import React from 'react';

const AccessoriesStyle4 = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.001 111.219L154.154 108.664C153.991 108.438 153.647 108.463 153.517 108.709L151.863 111.854C151.733 112.1 151.908 112.397 152.187 112.402L155.343 112.468L157.19 115.024C157.353 115.25 157.697 115.225 157.826 114.979L159.481 111.834C159.61 111.587 159.435 111.291 159.157 111.285L156.001 111.219Z"
        fill="#BEF264"
      />
      <ellipse
        cx="1.0347"
        cy="0.996286"
        rx="1.0347"
        ry="0.996286"
        transform="matrix(0.89881 0.438338 -0.439564 0.898211 155.15 110.495)"
        fill="#84CC16"
      />
    </>
  );
};

export default AccessoriesStyle4;
