import React from 'react';
import styles from '../Customize.module.scss';
import BodyColor from 'components/CustomizePlatform/components/BodyColor';
import HairColorSettings from 'components/CustomizePlatform/components/HairColor';
import AvatarBackgroundColorSettings from 'components/CustomizePlatform/components/AvatarBackgroundColor';
import {
  accountSettingsAccessoriesStyles,
  accountSettingsClothesStyles,
  accountSettingsHairStyles,
} from 'additionalInfo/index';
import AccountSliderSetting from 'components/CustomizePlatform/components/Slider/Slider';
import SelectStyleAvatar from 'components/CustomizePlatform/components/SelectStyleAvatar';
import classNames from 'classnames/bind';

const AvatarSetting = ({ initialValues, handleChange, setFieldValue }) => {
  const cn: Function = classNames.bind(styles);
  const disableAvatarSettings = cn({
    disable_avatar_settings: true,
    hide: initialValues.select_style === 'default',
  });
  return (
    <div className={styles.avatar_settings}>
      <SelectStyleAvatar initialValues={initialValues} handleChange={handleChange} />
      <div className={disableAvatarSettings}>
        <BodyColor initialValues={initialValues} handleChange={handleChange} />
        <AccountSliderSetting
          sectionTitle="Hair style"
          initialState={initialValues}
          initialValue={initialValues.hair_style}
          fieldName={'hair_style'}
          sliderItems={accountSettingsHairStyles}
          setFieldValue={setFieldValue}
        />
        <HairColorSettings initialValues={initialValues} handleChange={handleChange} />
        <AccountSliderSetting
          sectionTitle="Accessories"
          initialState={initialValues}
          initialValue={initialValues.accessories}
          fieldName={'accessories'}
          sliderItems={accountSettingsAccessoriesStyles}
          setFieldValue={setFieldValue}
        />
        <AccountSliderSetting
          sectionTitle="Clothes"
          initialState={initialValues}
          initialValue={initialValues.clothes}
          fieldName={'clothes'}
          sliderItems={accountSettingsClothesStyles}
          setFieldValue={setFieldValue}
        />
        <AvatarBackgroundColorSettings initialValues={initialValues} handleChange={handleChange} />
      </div>
    </div>
  );
};

export default AvatarSetting;
