import React from 'react';

const HairStyle12 = ({ hairColor }) => {
  return (
    <>
      <g clipPath="url(#clip0_8170_59685)">
        <path
          d="M151.912 111.47C149.9 115.446 148.242 116.324 143.903 116.721C143.141 111.45 144.103 109.798 148.679 108.789C150.156 108.464 151.722 108.569 153.081 109.231C156.293 110.796 156.407 112.659 156.514 116.721C153.332 115.797 153.048 114.452 151.912 111.47Z"
          fill={hairColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.413 127.974C155.747 127.974 160.071 122.642 160.071 116.065C160.071 109.488 155.747 105 150.413 105C145.079 105 140.755 109.488 140.755 116.065C140.755 122.642 145.079 127.974 150.413 127.974ZM150.225 122.723C153.695 122.723 156.508 119.721 156.508 116.018C156.508 112.315 153.695 109.313 150.225 109.313C146.756 109.313 143.943 112.315 143.943 116.018C143.943 119.721 146.756 122.723 150.225 122.723Z"
          fill="#323232"
        />
        <g filter="url(#filter2_d_8170_59685)">
          <path
            d="M144.183 123.379C142.842 123.379 141.755 124.723 141.755 126.38C141.755 126.649 141.783 126.91 141.838 127.16C141.398 127.765 140.634 128.115 140.728 129.521C140.821 130.928 143.775 135.903 146.025 136.226C151.501 137.012 151.368 133.315 155.918 132.569C157.279 132.345 159.762 131.172 159.762 129.474C159.762 128.5 159.183 127.636 158.659 127.006C158.69 126.816 158.705 126.62 158.705 126.42C158.705 124.741 157.604 123.379 156.245 123.379H144.183Z"
            fill="#323232"
          />
        </g>
      </g>
      <rect x="132.375" y="102.375" width="35.25" height="35.25" rx="17.625" stroke="#E8F6FF" strokeWidth="0.75" />
      <defs>
        <filter
          id="filter2_d_8170_59685"
          x="139.595"
          y="122.441"
          width="21.2928"
          height="15.2062"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.187532" />
          <feGaussianBlur stdDeviation="0.562596" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.137255 0 0 0 0 0.490196 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8170_59685" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8170_59685" result="shape" />
        </filter>
        <clipPath id="clip0_8170_59685">
          <rect width="30.38" height="32.82" fill="white" transform="translate(135 105)" />
        </clipPath>
      </defs>
    </>
  );
};

export default HairStyle12;
