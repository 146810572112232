import React from 'react';

const HairStyle6 = ({ userColor }) => {
  return (
    <>
      <path
        d="M290 183.438C290 260.481 218.327 279 165.757 279C113.188 279 34 260.481 34 183.438C34 106.395 77.0842 0.551315 165.757 0.00210687C254.431 -0.547102 290 106.395 290 183.438Z"
        fill={userColor}
      />
    </>
  );
};

export default HairStyle6;
