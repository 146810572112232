import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks/reduxHooks';
import UserRegistration from 'components/Modal/UserRegistration';
import { ReactComponent as CloseIcon } from 'assets/icons/closeModal.svg';
import constants from 'features/constants';
import styles from './Modal.module.scss';
import {
  modalClose,
  checkModalOpen,
  checkModalType,
  checkModalSize,
  checkModalIsRequired,
  checkModalData,
} from 'features/modal/modalSlice';
import ForgotPassword from './ForgotPassword';
import ChangingPasswordOnAccount from 'components/Modal/ChangingPasswordOnAccount';
import classNames from 'classnames/bind';
import SingleStory from './SingleStory';
import AccessPassword from 'components/Modal/AccessPassword';
import Feedback from './Feedback/Feedback';
import AgeValidation from './AgeValidation';
import SurveyModal from 'components/Modal/Survey/Survey';
import FullModuleMap from 'components/Modal/Map/FullModuleMap/FullModuleMap';
import AdBlockDetected from 'components/Modal/AdBlockDetected';
import FeedbackEducator from './FeedbackEducator/FeedbackEducator';
import ExploreParagraph from './ExploreParagraph';
import FullStoriesMap from 'components/Modal/Map/FullStoriesMap/FullStoriesMap';
import CertificateModal from 'components/Modal/CertificateModal/CertificateModal';

const Modal = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(checkModalOpen);
  const modalType = useAppSelector(checkModalType);
  const modalSize = useAppSelector(checkModalSize);
  const modalData = useAppSelector(checkModalData);
  const modalIsRequired = useAppSelector(checkModalIsRequired);
  const [closeWindowOnClick, setCloseWindowOnClick] = useState(true);

  const closeModal = () => {
    dispatch(modalClose());
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (modalType === constants.SURVEY || modalIsRequired) {
      setCloseWindowOnClick(false);
    } else {
      setCloseWindowOnClick(true);
    }
  }, [modalType]);

  const renderModalContent = () => {
    switch (modalType) {
      case constants.MODAL_REGISTRATION:
        return <UserRegistration />;
      case constants.MODAL_FORGOT_PASSWORD:
        return <ForgotPassword />;
      case constants.CHANGING_PASSWORD_ON_ACCOUNT:
        return <ChangingPasswordOnAccount />;
      case constants.SINGLE_STORY:
        return <SingleStory />;
      case constants.COMPLETE_REGISTRATION:
        return <AccessPassword />;
      case constants.FEEDBACK:
        return <Feedback />;
      case constants.FEEDBACK_EDUCATOR:
        return <FeedbackEducator />;
      case constants.MAP:
        return <FullModuleMap />;
      case constants.STORY_MAP:
        return <FullStoriesMap moduleStories={modalData} />;
      case constants.SURVEY:
        return <SurveyModal />;
      case constants.AGE_VALIDATION:
        return <AgeValidation />;
      case constants.AD_BLOCK_DETECTED:
        return <AdBlockDetected />;
      case constants.EXPLORE_PARAGRAPH:
        return <ExploreParagraph />;
      case constants.CERTIFICATE_MODAL:
        return <CertificateModal data={{ ...modalData, closeModal }} />;
      default:
        return null;
    }
  };
  const cn: Function = classNames.bind(styles);

  const modalClasses = cn({
    active: isModalOpen,
    modal_content: true,
    sm: modalSize === 'sm',
    md: modalSize === 'md',
    xl: modalSize === 'xl',
    xxl: modalSize === 'xxl',
    auto: modalSize === 'auto',
    md_password: modalSize === 'md_password',
    complete_registration: modalSize === 'complete_registration',
    story: modalType === 'SINGLE_STORY',
  });

  return (
    <div
      key={modalSize}
      className={isModalOpen ? `${styles.modal} ${styles.active}` : `${styles.modal}`}
      onClick={closeWindowOnClick ? closeModal : null}>
      <div className={modalClasses} onClick={(event) => event.stopPropagation()}>
        <div className={`${styles.close_modal}`} onClick={closeModal}>
          <div className={styles.icon}>
            <CloseIcon />
          </div>
        </div>
        {renderModalContent()}
      </div>
    </div>
  );
};

export default Modal;
