import React from 'react';

const BangStyle13 = ({ userColor }) => {
  return (
    <>
      <path
        d="M94 125V82C94 55.4903 115.49 34 142 34H180.528C206.83 34 228.234 55.1683 228.525 81.4693L229 124.445C225.396 111.923 221.715 103.88 214.059 96.5988C206.893 89.7832 195.862 91.0575 187.413 96.1983C168.229 107.871 153.562 108.611 134.613 97.4095C125.288 91.897 112.91 90.8432 106.313 99.4354C100.977 106.385 97.8994 114.132 94 125Z"
        fill={userColor}
      />
    </>
  );
};

export default BangStyle13;
