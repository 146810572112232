import { useAppSelector } from 'app/hooks/reduxHooks';
import { localizationSite } from 'features/localization/localizationSlice';

interface useLocalizationInterface {
  localizations: { [slug: string]: string };
  toLocalize: Function;
  isLoading: boolean;
}

const useLocalization = (): useLocalizationInterface => {
  const lang = useAppSelector((state) => state.login.acceptLanguage);
  const { data: localizations = {}, isLoading } = localizationSite.useGetTranslationsQuery(lang);

  const toLocalize = (slug: string): string => {
    return localizations[slug] || slug;
  };

  return { localizations, isLoading, toLocalize };
};

export default useLocalization;
