import React from 'react';

const HairStyle5 = ({ userColor }) => {
  return (
    <>
      <path
        d="M235.5 42C235.5 65.196 216.696 84 193.5 84C170.304 84 151.5 65.196 151.5 42C151.5 18.804 170.304 0 193.5 0C216.696 0 235.5 18.804 235.5 42Z"
        fill={userColor}
      />
    </>
  );
};

export default HairStyle5;
