import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { entryApi } from 'app/entryApi';

interface getUserAccountInterface {
  userSiteSettings: {
    select_style: string;
    body_color: string;
    hair_style: number;
    hair_color: string;
    accessories: number;
    clothes: number;
    avatar_background_color: string;
    background_color: string;
    sidebar_pattern: string;
    background_color_sidebar_pattern: string;
  };
}

const userAccountData = {
  userSiteSettings: {
    select_style: 'default',
    body_color: '#FFFBF2',
    hair_style: 1,
    hair_color: '#323232',
    accessories: 1,
    clothes: 1,
    avatar_background_color: 'linear-gradient(208.15deg, #FBB9B5 19.49%, #FBF0D0 82.57%), #BC983E',
    background_color: 'pattern_1',
    sidebar_pattern: 'pattern_1',
    background_color_sidebar_pattern: '#C0E3FB',
  },
};

const initialState: getUserAccountInterface = userAccountData;

export const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    setUserAccountData: (state, action: PayloadAction<getUserAccountInterface>) => {
      state.userSiteSettings = action.payload.userSiteSettings;
    },
  },
});
export const { setUserAccountData } = userAccountSlice.actions;
export default userAccountSlice.reducer;
export const getUserSiteSettingsState = (state: RootState) => state.userAccount.userSiteSettings;

interface setUserAccountDataInterface {
  account_customization_setting: string;
}

export const userSaveAccountSettings = entryApi.injectEndpoints({
  endpoints: (builder) => ({
    saveAccountSettings: builder.mutation<any, setUserAccountDataInterface>({
      query(data) {
        return {
          url: '/account-logo-customization',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['GET_ME'],
    }),
  }),
});

export const { useSaveAccountSettingsMutation } = userSaveAccountSettings;
