import React from 'react';

const AccessoriesStyle12 = () => {
  return (
    <>
      <circle cx="164" cy="147" r="1.95" fill="url(#paint0_linear_4669_50349)" stroke="#FFCC7F" strokeWidth="0.1" />
      <defs>
        <linearGradient
          id="paint0_linear_4669_50349"
          x1="164"
          y1="145"
          x2="164"
          y2="149"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBF0D0" />
          <stop offset="1" stopColor="#FFCC7F" />
        </linearGradient>
      </defs>
    </>
  );
};

export default AccessoriesStyle12;
