import React from 'react';
import styles from './Slider.module.scss';
import { ReactComponent as ArrowIconLeft } from 'assets/userSetting/customization/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/userSetting/customization/arrowRight.svg';
import classNames from 'classnames/bind';

export default function BtnSlider({ moveSlide, direction }) {
  const cn: Function = classNames.bind(styles);
  const directionStyle = (compare: boolean) =>
    cn({
      btn_slide: true,
      next: compare,
      prev: !compare,
    });
  return (
    <button type="button" onClick={moveSlide} className={directionStyle(direction === 'next')}>
      {direction === 'next' ? <ArrowRightIcon /> : <ArrowIconLeft />}
    </button>
  );
}
