import React from 'react';

const ClothesStyle9 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59397)">
        <path
          d="M146.772 127.226C146.815 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.943 128.387 157.61 129.611 158.749 131.209C159.888 132.809 160.512 134.804 160.928 137.523C160.936 137.58 160.892 137.631 160.836 137.631H139.17C139.115 137.631 139.07 137.58 139.079 137.523C139.528 134.667 140.265 132.649 141.474 131.07C142.683 129.492 144.376 128.334 146.772 127.226Z"
          fill="white"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M139.079 137.523C139.523 134.697 140.25 132.691 141.437 131.119C142.623 129.548 144.283 128.393 146.625 127.294C146.685 127.266 146.755 127.309 146.755 127.377V137.537C146.755 137.589 146.713 137.631 146.661 137.631H139.17C139.115 137.631 139.07 137.58 139.079 137.523Z"
          fill="#323232"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M161.02 137.523C160.577 134.697 159.849 132.691 158.663 131.119C157.476 129.548 155.817 128.393 153.474 127.294C153.415 127.266 153.345 127.309 153.345 127.377V137.537C153.345 137.589 153.387 137.631 153.438 137.631H160.929C160.985 137.631 161.029 137.58 161.02 137.523Z"
          fill="#323232"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle9;
