import React, { useEffect, useState, useCallback } from 'react';
import styles from './Survey.module.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.i18n';
import 'survey-core/i18n/spanish';
import 'survey-core/i18n/arabic';
import { modalClose } from 'features/modal/modalSlice';
import { modulesApi } from 'features/modules/modulesApi';
import routes from 'features/routes/routes';
import { useNavigate } from 'react-router-dom';
import { userInfo } from 'features/userInfo/userInfoSlice';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';
import { coursesApi } from 'features/courses/coursesApi';
import { formatSurveyData } from './helper';

const SurveyModal = () => {
  const dispatch = useAppDispatch();
  const setAnalyticsLogger = useAnalyticsLogger();
  const navigate = useNavigate();
  const [courseID, setCourseID] = useState(null);
  const [moduleID, setModuleID] = useState(null);
  const [surveyID, setSurveyID] = useState(null);
  const { modalData } = useAppSelector((state) => state.modal);
  const { course_id } = useAppSelector((state) => state.currentModule);
  const { isLoading, data: user } = userInfo.useGetUserInfoRequestQuery(null);
  const { data: courseData }: any = coursesApi.useGetSingleCourseQuery(course_id);
  const [surveyJson, setSurveyJson] = useState(null);
  const [sendSurveyData, { data: dataSurvey }] = modulesApi.useSendSurveyDataMutation();
  const surveyLocalization = user?.current_language?.value || 'en';
  const classId = user?.class_id;

  useEffect(() => {
    if (modalData) {
      setCourseID(modalData.course.id);
      setModuleID(modalData.id);
      setSurveyID(modalData.survey.id);
      setSurveyJson(modalData.survey.surveyJSON);
    }
  }, [modalData]);

  const survey = new Model(surveyJson);
  survey.locale = surveyLocalization;

  StylesManager.applyTheme('defaultV2');

  const alertResults = useCallback(
    (sender) => {
      const prunedSurvey = formatSurveyData(sender.data);
      const resultsSurveyData = JSON.stringify(prunedSurvey);

      sendSurveyData({
        survey: resultsSurveyData,
        module_id: moduleID,
        course_id: courseID,
        survey_id: surveyID,
        class_id: classId,
      });
      if (!isLoading) {
        setAnalyticsLogger('quetzal');
      }
    },
    [dispatch, moduleID, courseID, surveyID, sendSurveyData, courseData]
  );

  survey.onComplete.add(alertResults);

  useEffect(() => {
    if (dataSurvey?.msg === 'survey saved') {
      dispatch(modalClose());
      if (courseData?.modules.some((el) => !el.ready_to_review)) {
        navigate(`${routes.courses}/${courseID}`);
      } else {
        navigate(`${routes.courses}/${courseID}/${moduleID}/getInvolved`);
      }
    }
  }, [dataSurvey]);

  return (
    <div className={styles.survey_wrap}>
      <div className={styles.survey_content}>
        <Survey model={survey} isExpanded={true} />
      </div>
    </div>
  );
};

export default SurveyModal;
