import React from 'react';

const BangStyle1 = ({ userColor }) => {
  return (
    <>
      <path
        d="M84.5003 93.7973C82.86 71.724 89.0003 60.2973 107.5 55.7973C107.5 55.7973 162.5 1.29739 211 37.2974C259.5 73.2973 229.5 134.5 229.5 134.5C227.5 102.5 212.966 115.893 214 96.2973C172.157 108.871 154.716 105.147 131.5 79.2973C128.481 95.9869 113 93 101 100C103.147 112.313 98.5 128 95 131.5C83.8362 119.258 85.7234 114.302 84.5003 93.7973Z"
        fill={userColor}
      />
    </>
  );
};

export default BangStyle1;
