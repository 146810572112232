import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from 'app/helpers/baseQueryWithReauth';

export const entryApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'GET_ME',
    'GET_ALL_COURSES',
    'GET_SINGLE_COURSE',
    'GET_ALL_FAVORITE_STORIES',
    'GET_SINGLE_MODULE_STORY',
    'GET_MODULE_STORIES',
    'READ_NOTIFICATIONS',
  ],
  endpoints: () => ({}),
});
