import React from 'react';

const AccessoriesStyle6 = () => {
  return (
    <>
      <path d="M234 167.566L236 167.133V183H234V167.566Z" fill="#FFCC7F" />
      <path
        d="M229.517 199.513C227.779 198.512 227.184 196.293 228.187 194.557L228.723 193.63H227.633C225.626 193.63 224 192.006 224 190.002C224 187.999 225.626 186.374 227.633 186.374H228.726L228.187 185.443C227.184 183.707 227.779 181.488 229.517 180.487C231.254 179.485 233.476 180.079 234.479 181.815L235.017 182.744L235.554 181.815C236.557 180.079 238.779 179.485 240.517 180.487C242.254 181.488 242.849 183.707 241.846 185.443L241.308 186.374H242.367C244.374 186.374 246 187.999 246 190.002C246 192.006 244.374 193.63 242.367 193.63H241.31L241.846 194.557C242.849 196.293 242.254 198.512 240.517 199.513C238.779 200.515 236.557 199.921 235.554 198.185L235.017 197.256L234.479 198.185C233.476 199.921 231.254 200.515 229.517 199.513Z"
        fill="#FFCC7F"
      />
      <circle cx="235" cy="158" r="3" fill="#FFCC7F" />
      <path d="M89 167.566L87 167.133V183H89V167.566Z" fill="#FFCC7F" />
      <path
        d="M93.4834 199.513C95.221 198.512 95.8163 196.293 94.8132 194.557L94.277 193.63H95.3671C97.3735 193.63 99 192.006 99 190.002C99 187.999 97.3735 186.374 95.3671 186.374H94.2745L94.8132 185.443C95.8163 183.707 95.221 181.488 93.4834 180.487C91.7459 179.485 89.524 180.079 88.5208 181.815L87.9834 182.744L87.446 181.815C86.4428 180.079 84.221 179.485 82.4834 180.487C80.7459 181.488 80.1505 183.707 81.1537 185.443L81.6924 186.374H80.6329C78.6265 186.374 77 187.999 77 190.002C77 192.006 78.6265 193.63 80.6329 193.63H81.6898L81.1537 194.557C80.1505 196.293 80.7459 198.512 82.4834 199.513C84.221 200.515 86.4428 199.921 87.446 198.185L87.9834 197.256L88.5208 198.185C89.524 199.921 91.7459 200.515 93.4834 199.513Z"
        fill="#FFCC7F"
      />
      <circle r="3" transform="matrix(-1 0 0 1 88 158)" fill="#FFCC7F" />
    </>
  );
};

export default AccessoriesStyle6;
