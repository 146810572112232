import React from 'react';

const HairStyleFront8 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M151.911 111.47C149.9 115.446 148.242 116.324 143.903 116.721C143.141 111.45 144.103 109.798 148.679 108.789C150.156 108.464 151.722 108.569 153.081 109.231C156.293 110.796 156.407 112.659 156.514 116.721C153.332 115.797 153.048 114.452 151.911 111.47Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyleFront8;
