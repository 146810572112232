export const getFirstWord = (str: string = '') => {
  return str?.length > 0 ? str.split(' ')[0] : str;
};

export const normaliseStatus = (data) => {
  if (data?.documentInStages?.length === 1) return data?.documentInStages[0]?.createdAt;
  return data?.documentInStages?.filter((item) => item.stage === 'PUBLISHED')[0]?.publishedAt;
};

export const toCapitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const Environments = Object.freeze({
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
});
