import React, { useEffect, useRef, useState } from 'react';
import styles from './RecentStories.module.scss';
import { ReactComponent as PinnedStory } from 'assets/images/pinnedStoryIcon.svg';
import { ReactComponent as UnpinnedStory } from 'assets/images/unpinnedStoryIcon.svg';
import { ReactComponent as StoryPlaceholder } from 'assets/placeholders/story_placeholder.svg';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import { modalOpen } from 'features/modal/modalSlice';
import constants from 'features/constants';
import {
  useAddToFavoriteStoryMutation,
  useRemoveFromFavoriteStoryMutation,
} from 'features/favoriteStory/favoriteStory';
import { moduleProgressInterface } from 'types/types';
import { useAnalyticsLogger } from 'app/hooks/useAnalyticsLogger';
import { userInfo } from 'features/userInfo/userInfoSlice';
import Spinner from 'components/common/Spinner/Spinner';
import { storiesApi } from 'features/moduleStory/moduleStory';

export interface recentStoriesInterface extends moduleProgressInterface {
  nested?: boolean;
  moduleStories?: Array<any>;
  closeUserInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateStories?: any;
  getlocalizationInfo?: any;
  handleAuthorStory?: any;
  storiesRequestParams?: { moduleId: string; first: number; skip?: number };
}

const RecentStories = ({
  getlocalizationInfo,
  nested,
  moduleStories,
  handleAuthorStory,
  closeUserInfo,
  storiesRequestParams,
}: recentStoriesInterface) => {
  const dispatch = useAppDispatch();
  const setAnalyticsLogger = useAnalyticsLogger();
  const skipRef = useRef(0);
  const getUserAllInfo = userInfo.useGetUserInfoRequestQuery(null);
  const [addToFavoriteStory] = useAddToFavoriteStoryMutation();
  const [removeFromFavoriteStory] = useRemoveFromFavoriteStoryMutation();
  const [getMoreStories, { data }] = storiesApi.useLazyLoadMoreStoriesQuery();
  const paginationObserver = useRef(null);

  const loadMoreStories = () => {
    skipRef.current = skipRef.current + moduleStories.length;
    getMoreStories({ ...storiesRequestParams, skip: skipRef.current });
  };

  const handleFavStory = async (pinned, id) => {
    setUpdatedStory(id);
    try {
      if (pinned) {
        setAnalyticsLogger('story_favourite_unpinned', { story_favourite_ID: id });
      } else {
        setAnalyticsLogger('story_favourite_pinned', { story_favourite_ID: id });
      }

      const result = await (pinned ? removeFromFavoriteStory(id) : addToFavoriteStory(id));

      if ('data' in result) {
        setTimeout(() => {
          setUpdatedStory(null);
        }, 1000);
      } else {
        console.error('error');
        setUpdatedStory(null);
      }
    } catch (err) {
      console.error(err);
      setUpdatedStory(null);
    }
  };

  const openStoryModal = (id) => {
    if (nested) closeUserInfo(false);

    dispatch(
      modalOpen({
        modalType: constants.SINGLE_STORY,
        modalData: id,
        modalSize: 'xl',
      })
    );
  };

  const [updatedStory, setUpdatedStory] = useState(null);

  const stories = moduleStories?.map((item) => {
    return (
      <div className={styles.recent_stories_item} key={item.id}>
        <div className={styles.item_course_title}>{item?.course?.title}</div>
        <div className={styles.item_module_title}>{item?.module?.title}</div>
        <div className={styles.preview_img}>
          {item?.storyMediaStack.find((obj) => obj.cover)?.media?.url ? (
            <img
              alt="avatar"
              className={styles.item_preview_img}
              src={item?.storyMediaStack.find((obj) => obj.cover)?.media?.url}
            />
          ) : (
            <StoryPlaceholder className={styles.placeholder_img} />
          )}
          {getUserAllInfo?.currentData?.id !== item?.userId && (
            <button className={styles.action} onClick={() => handleFavStory(item.is_favorite, item.id)}>
              {updatedStory === item.id ? <Spinner /> : <>{item.is_favorite ? <PinnedStory /> : <UnpinnedStory />}</>}
            </button>
          )}
        </div>
        <div className={styles.item_author}>{item.fullName}</div>
        <div
          className={styles.item_title}
          onClick={() => {
            handleAuthorStory ? handleAuthorStory(item.id) : openStoryModal(item.id);
            setAnalyticsLogger('story_view', { referrer: 'list', story_ID: item?.id });
          }}>
          {item.title}
        </div>
        <div className={styles.item_preview_text}>{item.description}</div>
      </div>
    );
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMoreStories();
        }
      });
    });

    if (paginationObserver.current) {
      observer.observe(paginationObserver.current);
    }

    if (data?.stories?.length === 0) {
      observer.disconnect();
    }

    return () => {
      if (paginationObserver.current) {
        observer.disconnect();
      }
    };
  }, [paginationObserver.current, data?.stories?.length]);

  return (
    <div className={styles.recent_stories}>
      <h2 className={styles.recent_stories_title}>{getlocalizationInfo.recent_stories}</h2>
      <div className={styles.recent_stories_wrap}>{stories}</div>
      <div
        ref={paginationObserver}
        style={{ position: 'absolute', width: '1px', height: '25%', maxHeight: '800px', bottom: '0', zIndex: -1 }}
      />
    </div>
  );
};

export default RecentStories;
