import React from 'react';
import styles from '../CourseCard/CourseCard.module.scss';
import SkeletonItem from 'components/common/SkeletonComponents/SkeletonItem';
import classNames from 'classnames';
import { classNamesInterface } from 'types/types';

const EmptyCourseCard = ({ className }: classNamesInterface) => {
  return (
    <div className={classNames(styles.card, styles.card_empty, className)}>
      <SkeletonItem className={styles.img} />
      <SkeletonItem className={styles.title} height={25} />
      <SkeletonItem className={styles.title} height={20} />
    </div>
  );
};

export default EmptyCourseCard;
