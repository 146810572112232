import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PostHogProvider } from 'posthog-js/react';
import { store } from 'app/store';
import App from './App';
import './index.scss';
import Modal from 'components/Modal/Modal';
import { setUserTokenAfterRegistration } from 'features/login/loginSlice';
import NotificationModal from 'components/Notifications';
import CustomizePlatform from 'components/CustomizePlatform/CustomizePlatform';

const container = document.getElementById('root');
const root = createRoot(container);
const userToken = localStorage.getItem('userToken') ?? null;
const acceptLanguage = localStorage.getItem('acceptLanguage') ? localStorage.getItem('acceptLanguage') : null;
const dir = acceptLanguage === 'ar' ? 'rtl' : 'ltr';

if (userToken && acceptLanguage) {
  store.dispatch(setUserTokenAfterRegistration({ userToken, acceptLanguage }));
}

const options = { api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST };

document.querySelector('body').setAttribute('dir', dir);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <App />
        <Modal />
        <NotificationModal />
        <CustomizePlatform />
      </PostHogProvider>
    </Provider>
  </BrowserRouter>
);
