import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface ModalState {
  open: boolean;
  modalType: string;
  modalData: any;
  modalSize: string;
  modalIsRequired?: boolean;
}
export interface modalOpenInterface {
  modalType: string;
  modalData: any;
  modalSize: string;
  modalIsRequired?: boolean;
}

const initialState: ModalState = {
  open: false,
  modalType: '',
  modalData: '',
  modalSize: '',
  modalIsRequired: false,
};

export const registrationSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    modalOpen: (state, action: PayloadAction<modalOpenInterface>) => {
      state.open = true;
      state.modalType = action.payload.modalType;
      state.modalData = action.payload.modalData;
      state.modalSize = action.payload.modalSize;
      state.modalIsRequired = action.payload.modalIsRequired;
    },
    modalClose: (state) => {
      state.open = false;
      state.modalType = '';
      state.modalData = '';
      state.modalSize = '';
      state.modalIsRequired = false;
    },
  },
});

export const { modalOpen, modalClose } = registrationSlice.actions;

export const checkModalOpen = (state: RootState) => state.modal.open;
export const checkModalType = (state: RootState) => state.modal.modalType;
export const checkModalSize = (state: RootState) => state.modal.modalSize;
export const checkModalData = (state: RootState) => state.modal.modalData;
export const checkModalIsRequired = (state: RootState) => state.modal.modalIsRequired;

export default registrationSlice.reducer;
