import React from 'react';
import styles from '../SingleStory.module.scss';
import { format } from 'date-fns';
import PreviewUserAvatar from 'components/pages/Account/components/PreviewUserAvatar/PreviewUserAvatar';
import DefaultUserAvatar from 'components/pages/Account/components/PreviewUserAvatar/DefaultUserAvatar';
import { normaliseStatus } from 'helpers/getFirstWord';

interface storyHeaderInterface {
  setUserInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAuthorsInfo?: any;
  storyData?: {
    fullName?: string;
    country?: string;
    publishedAt?: string;
    tags?: any;
    account_customization_setting?: any;
    userId?: string;
  };
}

const StoryHeader = ({ storyData, handleAuthorsInfo }: storyHeaderInterface) => {
  return (
    <div className={styles.story_heading}>
      <div className={styles.story_meta}>
        <div className={styles.author_info}>
          {storyData?.account_customization_setting ? (
            <PreviewUserAvatar
              customStyles={styles.author_avatar}
              userValues={storyData?.account_customization_setting}
            />
          ) : (
            <DefaultUserAvatar customStyles={styles.author_avatar} />
          )}
          <p className={styles.author_name} onClick={() => handleAuthorsInfo(storyData?.userId)}>
            {storyData?.fullName}
          </p>
        </div>
        {storyData.country && (
          <div className={styles.country_info}>
            <p>{storyData?.country}</p>
          </div>
        )}
        <div className={styles.story_data}>{format(new Date(normaliseStatus(storyData)), 'MMM dd, yyyy')}</div>
      </div>

      <div className={styles.story_tags}>
        {storyData.tags && storyData.tags.map((tag, index) => <span key={index}>#{tag}</span>)}
      </div>
    </div>
  );
};

export default StoryHeader;
