import React from 'react';

const ClothesStyle11 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59422)">
        <path
          d="M146.771 127.226C146.814 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.942 128.387 157.609 129.611 158.748 131.209C159.888 132.809 160.512 134.804 160.927 137.523C160.936 137.58 160.891 137.631 160.836 137.631H139.17C139.114 137.631 139.07 137.58 139.079 137.523C139.527 134.667 140.265 132.649 141.474 131.07C142.682 129.492 144.376 128.334 146.771 127.226Z"
          fill="#FEFCE8"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.66 127.271L146.795 131.373C146.797 131.449 146.885 131.491 146.946 131.444L149.861 129.202"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
        />
        <path
          d="M153.439 127.271L153.304 131.373C153.301 131.449 153.213 131.491 153.153 131.444L150.237 129.202"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
        />
        <line
          y1="-0.187531"
          x2="8.19344"
          y2="-0.187531"
          transform="matrix(0.0116259 0.999932 -0.999932 0.0116284 149.861 129.249)"
          stroke="#323232"
          strokeWidth="0.375062"
        />
        <ellipse cx="151.368" cy="132.545" rx="0.564966" ry="0.565028" fill="#323232" />
        <ellipse cx="151.368" cy="135.37" rx="0.564966" ry="0.565028" fill="#323232" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8158_59422"
          x1="164.5"
          y1="110.9"
          x2="150"
          y2="138"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBB9B5" />
          <stop offset="1" stopColor="#FBF0D0" />
        </linearGradient>
        <clipPath id="clip0_8158_59422">
          <rect width="30.38" height="32.8179" fill="white" transform="translate(135 105)" />
        </clipPath>
      </defs>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle11;
