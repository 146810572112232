import React from 'react';
import styles from '../Customize.module.scss';
import { accountSettingsBackgroundSidebar } from 'additionalInfo/index';
import { nanoid } from '@reduxjs/toolkit';
import { ReactComponent as CheckedIcon } from 'assets/icons/checked.svg';
import classNames from 'classnames/bind';
import { setUserAccountData } from 'features/userAccount/userAccountSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const BackgroundSidebarPattern = ({ backgroundColorPattern, initialValues, handleChange }) => {
  const dispatch = useAppDispatch();
  const handleChangeSidebarColor = (event, handleChange) => {
    handleChange(event);
    let spredValues = {
      ...initialValues,
      sidebar_pattern: event.target.value,
    };
    dispatch(setUserAccountData({ userSiteSettings: spredValues }));
  };
  const cn: Function = classNames.bind(styles);
  const backgroundColorStyle = (compare: boolean) =>
    cn({
      pattern_item: true,
      active: compare,
    });

  return (
    <>
      <div className={styles.section_title} id="background-sidebar-pattern">
        Select pattern
      </div>
      <div className={styles.background_sidebar_pattern} role="group" aria-labelledby="background-sidebar-pattern">
        {accountSettingsBackgroundSidebar.map((singleItem) => (
          <div className={backgroundColorStyle(initialValues.sidebar_pattern === singleItem.value)} key={nanoid()}>
            <label className={styles.sidebar_pattern_item}>
              <input
                value={singleItem.value}
                name="sidebar_pattern"
                type="radio"
                checked={initialValues.sidebar_pattern === singleItem.value}
                onChange={(event) => handleChangeSidebarColor(event, handleChange)}
              />
              <div className={styles.color_box}>
                <img
                  style={{ background: backgroundColorPattern }}
                  className={styles.sidebar_pattern_img}
                  src={singleItem.linkToPattern}
                  alt="background pattern image"
                />
                <CheckedIcon className={styles.checked_icon} />
              </div>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default BackgroundSidebarPattern;
