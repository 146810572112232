import React from 'react';

const HairStyleFront14 = ({ hairColor }) => {
  return (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M145.278 114.328C145 114.832 144.61 115.11 144.001 115.502L144.704 109.595C144.704 109.595 145.923 107.86 149.533 107.813C153.143 107.766 156.519 110.86 156.519 110.86V115.596C155.188 115.358 154.151 115.119 153.587 114.188C153.216 113.577 152.409 113.174 151.83 113.594C151.497 113.835 151.045 113.827 150.721 113.573L150.237 113.193C149.825 112.871 149.245 112.874 148.837 113.203L148.435 113.526C148.079 113.814 147.583 113.856 147.183 113.634L147.111 113.594C146.437 113.219 145.651 113.653 145.278 114.328Z"
        fill={hairColor}
      />
    </>
  );
};

export default HairStyleFront14;
