import React from 'react';

const SidebarPattern4 = () => {
  return (
    <>
      <g clipPath="url(#clip3_8054_55345)">
        <g clipPath="url(#clip4_8054_55345)">
          <path
            d="M-341.01 223.135L-303.752 247L-266.48 223.135L-229.209 247L-191.937 223.135L-154.679 247L-117.407 223.135L-80.136 247L-42.8645 223.135L-5.59303 247L31.6785 223.135L68.9367 247L106.208 223.135L143.48 247L180.764 223.135L218.036 247L255.307 223.135L292.579 247L329.85 223.135L367.122 247L404.406 223.135L441.678 247L478.949 223.135L516.221 247L553.492 223.135L590.777 247L628.049 223.135L665.333 247L702.618 223.135L739.903 247"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 414.27L-303.752 438.135L-266.48 414.27L-229.209 438.135L-191.937 414.27L-154.679 438.135L-117.407 414.27L-80.136 438.135L-42.8645 414.27L-5.59303 438.135L31.6785 414.27L68.9367 438.135L106.208 414.27L143.48 438.135L180.764 414.27L218.036 438.135L255.307 414.27L292.579 438.135L329.85 414.27L367.122 438.135L404.406 414.27L441.678 438.135L478.949 414.27L516.221 438.135L553.492 414.27L590.777 438.135L628.049 414.27L665.333 438.135L702.618 414.27L739.903 438.135"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 127.568L-303.752 151.433L-266.48 127.568L-229.209 151.433L-191.937 127.568L-154.679 151.433L-117.407 127.568L-80.136 151.433L-42.8645 127.568L-5.59303 151.433L31.6785 127.568L68.9367 151.433L106.208 127.568L143.48 151.433L180.764 127.568L218.036 151.433L255.307 127.568L292.579 151.433L329.85 127.568L367.122 151.433L404.406 127.568L441.678 151.433L478.949 127.568L516.221 151.433L553.492 127.568L590.777 151.433L628.049 127.568L665.333 151.433L702.618 127.568L739.903 151.433"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 318.703L-303.752 342.568L-266.48 318.703L-229.209 342.568L-191.937 318.703L-154.679 342.568L-117.407 318.703L-80.136 342.568L-42.8645 318.703L-5.59303 342.568L31.6785 318.703L68.9367 342.568L106.208 318.703L143.48 342.568L180.764 318.703L218.036 342.568L255.307 318.703L292.579 342.568L329.85 318.703L367.122 342.568L404.406 318.703L441.678 342.568L478.949 318.703L516.221 342.568L553.492 318.703L590.777 342.568L628.049 318.703L665.333 342.568L702.618 318.703L739.903 342.568"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 509.73L-303.752 533.703L-266.48 509.73L-229.209 533.703L-191.937 509.73L-154.679 533.703L-117.407 509.73L-80.136 533.703L-42.8645 509.73L-5.59303 533.703L31.6785 509.73L68.9367 533.703L106.208 509.73L143.48 533.703L180.764 509.73L218.036 533.703L255.307 509.73L292.579 533.703L329.85 509.73L367.122 533.703L404.406 509.73L441.678 533.703L478.949 509.73L516.221 533.703L553.492 509.73L590.777 533.703L628.049 509.73L665.333 533.703L702.618 509.73L739.903 533.703"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 79.7305L-303.752 103.703L-266.48 79.7305L-229.209 103.703L-191.937 79.7305L-154.679 103.703L-117.407 79.7305L-80.136 103.703L-42.8645 79.7305L-5.59303 103.703L31.6785 79.7305L68.9367 103.703L106.208 79.7305L143.48 103.703L180.764 79.7305L218.036 103.703L255.307 79.7305L292.579 103.703L329.85 79.7305L367.122 103.703L404.406 79.7305L441.678 103.703L478.949 79.7305L516.221 103.703L553.492 79.7305L590.777 103.703L628.049 79.7305L665.333 103.703L702.618 79.7305L739.903 103.703"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 270.865L-303.752 294.73L-266.48 270.865L-229.209 294.73L-191.937 270.865L-154.679 294.73L-117.407 270.865L-80.136 294.73L-42.8645 270.865L-5.59303 294.73L31.6785 270.865L68.9367 294.73L106.208 270.865L143.48 294.73L180.764 270.865L218.036 294.73L255.307 270.865L292.579 294.73L329.85 270.865L367.122 294.73L404.406 270.865L441.678 294.73L478.949 270.865L516.221 294.73L553.492 270.865L590.777 294.73L628.049 270.865L665.333 294.73L702.618 270.865L739.903 294.73"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 462L-303.752 485.865L-266.48 462L-229.209 485.865L-191.937 462L-154.679 485.865L-117.407 462L-80.136 485.865L-42.8645 462L-5.59303 485.865L31.6785 462L68.9367 485.865L106.208 462L143.48 485.865L180.764 462L218.036 485.865L255.307 462L292.579 485.865L329.85 462L367.122 485.865L404.406 462L441.678 485.865L478.949 462L516.221 485.865L553.492 462L590.777 485.865L628.049 462L665.333 485.865L702.618 462L739.903 485.865"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 175.297L-303.752 199.269L-266.48 175.297L-229.209 199.269L-191.937 175.297L-154.679 199.269L-117.407 175.297L-80.136 199.269L-42.8645 175.297L-5.59303 199.269L31.6785 175.297L68.9367 199.269L106.208 175.297L143.48 199.269L180.764 175.297L218.036 199.269L255.307 175.297L292.579 199.269L329.85 175.297L367.122 199.269L404.406 175.297L441.678 199.269L478.949 175.297L516.221 199.269L553.492 175.297L590.777 199.269L628.049 175.297L665.333 199.269L702.618 175.297L739.903 199.269"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 366.434L-303.752 390.299L-266.48 366.434L-229.209 390.299L-191.937 366.434L-154.679 390.299L-117.407 366.434L-80.136 390.299L-42.8645 366.434L-5.59303 390.299L31.6785 366.434L68.9367 390.299L106.208 366.434L143.48 390.299L180.764 366.434L218.036 390.299L255.307 366.434L292.579 390.299L329.85 366.434L367.122 390.299L404.406 366.434L441.678 390.299L478.949 366.434L516.221 390.299L553.492 366.434L590.777 390.299L628.049 366.434L665.333 390.299L702.618 366.434L739.903 390.299"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 557.566L-303.752 581.431L-266.48 557.566L-229.209 581.431L-191.937 557.566L-154.679 581.431L-117.407 557.566L-80.136 581.431L-42.8645 557.566L-5.59303 581.431L31.6785 557.566L68.9367 581.431L106.208 557.566L143.48 581.431L180.764 557.566L218.036 581.431L255.307 557.566L292.579 581.431L329.85 557.566L367.122 581.431L404.406 557.566L441.678 581.431L478.949 557.566L516.221 581.431L553.492 557.566L590.777 581.431L628.049 557.566L665.333 581.431L702.618 557.566L739.903 581.431"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 55.8652L-303.752 79.7302L-266.48 55.8652L-229.209 79.7302L-191.937 55.8652L-154.679 79.7302L-117.407 55.8652L-80.136 79.7302L-42.8645 55.8652L-5.59303 79.7302L31.6785 55.8652L68.9367 79.7302L106.208 55.8652L143.48 79.7302L180.764 55.8652L218.036 79.7302L255.307 55.8652L292.579 79.7302L329.85 55.8652L367.122 79.7302L404.406 55.8652L441.678 79.7302L478.949 55.8652L516.221 79.7302L553.492 55.8652L590.777 79.7302L628.049 55.8652L665.333 79.7302L702.618 55.8652L739.903 79.7302"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 247L-303.752 270.865L-266.48 247L-229.209 270.865L-191.937 247L-154.679 270.865L-117.407 247L-80.136 270.865L-42.8645 247L-5.59303 270.865L31.6785 247L68.9367 270.865L106.208 247L143.48 270.865L180.764 247L218.036 270.865L255.307 247L292.579 270.865L329.85 247L367.122 270.865L404.406 247L441.678 270.865L478.949 247L516.221 270.865L553.492 247L590.777 270.865L628.049 247L665.333 270.865L702.618 247L739.903 270.865"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 438.135L-303.752 462L-266.48 438.135L-229.209 462L-191.937 438.135L-154.679 462L-117.407 438.135L-80.136 462L-42.8645 438.135L-5.59303 462L31.6785 438.135L68.9367 462L106.208 438.135L143.48 462L180.764 438.135L218.036 462L255.307 438.135L292.579 462L329.85 438.135L367.122 462L404.406 438.135L441.678 462L478.949 438.135L516.221 462L553.492 438.135L590.777 462L628.049 438.135L665.333 462L702.618 438.135L739.903 462"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 151.432L-303.752 175.297L-266.48 151.432L-229.209 175.297L-191.937 151.432L-154.679 175.297L-117.407 151.432L-80.136 175.297L-42.8645 151.432L-5.59303 175.297L31.6785 151.432L68.9367 175.297L106.208 151.432L143.48 175.297L180.764 151.432L218.036 175.297L255.307 151.432L292.579 175.297L329.85 151.432L367.122 175.297L404.406 151.432L441.678 175.297L478.949 151.432L516.221 175.297L553.492 151.432L590.777 175.297L628.049 151.432L665.333 175.297L702.618 151.432L739.903 175.297"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 342.568L-303.752 366.433L-266.48 342.568L-229.209 366.433L-191.937 342.568L-154.679 366.433L-117.407 342.568L-80.136 366.433L-42.8645 342.568L-5.59303 366.433L31.6785 342.568L68.9367 366.433L106.208 342.568L143.48 366.433L180.764 342.568L218.036 366.433L255.307 342.568L292.579 366.433L329.85 342.568L367.122 366.433L404.406 342.568L441.678 366.433L478.949 342.568L516.221 366.433L553.492 342.568L590.777 366.433L628.049 342.568L665.333 366.433L702.618 342.568L739.903 366.433"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 533.703L-303.752 557.568L-266.48 533.703L-229.209 557.568L-191.937 533.703L-154.679 557.568L-117.407 533.703L-80.136 557.568L-42.8645 533.703L-5.59303 557.568L31.6785 533.703L68.9367 557.568L106.208 533.703L143.48 557.568L180.764 533.703L218.036 557.568L255.307 533.703L292.579 557.568L329.85 533.703L367.122 557.568L404.406 533.703L441.678 557.568L478.949 533.703L516.221 557.568L553.492 533.703L590.777 557.568L628.049 533.703L665.333 557.568L702.618 533.703L739.903 557.568"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 103.703L-303.752 127.568L-266.48 103.703L-229.209 127.568L-191.937 103.703L-154.679 127.568L-117.407 103.703L-80.136 127.568L-42.8645 103.703L-5.59303 127.568L31.6785 103.703L68.9367 127.568L106.208 103.703L143.48 127.568L180.764 103.703L218.036 127.568L255.307 103.703L292.579 127.568L329.85 103.703L367.122 127.568L404.406 103.703L441.678 127.568L478.949 103.703L516.221 127.568L553.492 103.703L590.777 127.568L628.049 103.703L665.333 127.568L702.618 103.703L739.903 127.568"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 294.73L-303.752 318.703L-266.48 294.73L-229.209 318.703L-191.937 294.73L-154.679 318.703L-117.407 294.73L-80.136 318.703L-42.8645 294.73L-5.59303 318.703L31.6785 294.73L68.9367 318.703L106.208 294.73L143.48 318.703L180.764 294.73L218.036 318.703L255.307 294.73L292.579 318.703L329.85 294.73L367.122 318.703L404.406 294.73L441.678 318.703L478.949 294.73L516.221 318.703L553.492 294.73L590.777 318.703L628.049 294.73L665.333 318.703L702.618 294.73L739.903 318.703"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 485.865L-303.752 509.73L-266.48 485.865L-229.209 509.73L-191.937 485.865L-154.679 509.73L-117.407 485.865L-80.136 509.73L-42.8645 485.865L-5.59303 509.73L31.6785 485.865L68.9367 509.73L106.208 485.865L143.48 509.73L180.764 485.865L218.036 509.73L255.307 485.865L292.579 509.73L329.85 485.865L367.122 509.73L404.406 485.865L441.678 509.73L478.949 485.865L516.221 509.73L553.492 485.865L590.777 509.73L628.049 485.865L665.333 509.73L702.618 485.865L739.903 509.73"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 199.27L-303.752 223.135L-266.48 199.27L-229.209 223.135L-191.937 199.27L-154.679 223.135L-117.407 199.27L-80.136 223.135L-42.8645 199.27L-5.59303 223.135L31.6785 199.27L68.9367 223.135L106.208 199.27L143.48 223.135L180.764 199.27L218.036 223.135L255.307 199.27L292.579 223.135L329.85 199.27L367.122 223.135L404.406 199.27L441.678 223.135L478.949 199.27L516.221 223.135L553.492 199.27L590.777 223.135L628.049 199.27L665.333 223.135L702.618 199.27L739.903 223.135"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 605.297L-303.752 629.269L-266.48 605.297L-229.209 629.269L-191.937 605.297L-154.679 629.269L-117.407 605.297L-80.136 629.269L-42.8645 605.297L-5.59303 629.269L31.6785 605.297L68.9367 629.269L106.208 605.297L143.48 629.269L180.764 605.297L218.036 629.269L255.307 605.297L292.579 629.269L329.85 605.297L367.122 629.269L404.406 605.297L441.678 629.269L478.949 605.297L516.221 629.269L553.492 605.297L590.777 629.269L628.049 605.297L665.333 629.269L702.618 605.297L739.903 629.269"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 390.297L-303.752 414.269L-266.48 390.297L-229.209 414.269L-191.937 390.297L-154.679 414.269L-117.407 390.297L-80.136 414.269L-42.8645 390.297L-5.59303 414.269L31.6785 390.297L68.9367 414.269L106.208 390.297L143.48 414.269L180.764 390.297L218.036 414.269L255.307 390.297L292.579 414.269L329.85 390.297L367.122 414.269L404.406 390.297L441.678 414.269L478.949 390.297L516.221 414.269L553.492 390.297L590.777 414.269L628.049 390.297L665.333 414.269L702.618 390.297L739.903 414.269"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-341.01 581.432L-303.752 605.297L-266.48 581.432L-229.209 605.297L-191.937 581.432L-154.679 605.297L-117.407 581.432L-80.136 605.297L-42.8645 581.432L-5.59303 605.297L31.6785 581.432L68.9367 605.297L106.208 581.432L143.48 605.297L180.764 581.432L218.036 605.297L255.307 581.432L292.579 605.297L329.85 581.432L367.122 605.297L404.406 581.432L441.678 605.297L478.949 581.432L516.221 605.297L553.492 581.432L590.777 605.297L628.049 581.432L665.333 605.297L702.618 581.432L739.903 605.297"
            stroke="#4F88B3"
            strokeOpacity="0.1"
            strokeWidth="2.09559"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
      </g>
    </>
  );
};

export default SidebarPattern4;
