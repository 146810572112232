import React from 'react';

const AccessoriesStyle10 = () => {
  return (
    <>
      <g clipPath="url(#clip0_8158_59122)">
        <path
          d="M149.101 117.169C149.94 116.823 150.395 116.814 151.18 117.169"
          stroke="#323232"
          strokeWidth="0.375"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.007 114.661L147.342 114.996L144.571 117.766L144.44 117.635C144.425 117.575 144.412 117.514 144.402 117.453C144.37 117.269 144.361 117.087 144.372 116.91L146.624 114.658C146.753 114.649 146.881 114.65 147.007 114.661Z"
          fill="white"
          fillOpacity="0.15"
        />
        <path
          d="M148.987 116.673C149.191 117.872 148.374 119.015 147.157 119.222C145.94 119.429 144.79 118.621 144.586 117.421C144.382 116.222 145.2 115.079 146.417 114.872C147.634 114.665 148.783 115.473 148.987 116.673Z"
          fill="url(#paint1_linear_8158_59122)"
          fillOpacity="0.3"
          stroke="#323232"
          strokeWidth="0.375"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.906 117.234C155.922 117.04 155.914 116.841 155.88 116.641C155.872 116.596 155.863 116.551 155.853 116.507L155.712 116.367L152.786 119.288L152.858 119.36C153.127 119.432 153.413 119.458 153.704 119.432L155.906 117.234Z"
          fill="white"
          fillOpacity="0.15"
        />
        <path
          d="M155.695 116.673C155.899 117.872 155.082 119.015 153.864 119.222C152.647 119.429 151.498 118.621 151.294 117.421C151.09 116.222 151.907 115.079 153.124 114.872C154.341 114.665 155.491 115.473 155.695 116.673Z"
          fill="url(#paint2_linear_8158_59122)"
          fillOpacity="0.3"
          stroke="#323232"
          strokeWidth="0.375"
        />
      </g>

      <defs>
        <linearGradient
          id="paint1_linear_8158_59122"
          x1="146.385"
          y1="114.687"
          x2="148.625"
          y2="119.285"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#061049" />
          <stop offset="1" stopColor="#7283EC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8158_59122"
          x1="153.093"
          y1="114.687"
          x2="155.332"
          y2="119.285"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#061049" />
          <stop offset="1" stopColor="#7283EC" />
        </linearGradient>
        <clipPath id="clip0_8158_59122">
          <rect width="30.375" height="32.8125" fill="white" transform="translate(135 105)" />
        </clipPath>
      </defs>
    </>
  );
};

export default AccessoriesStyle10;
