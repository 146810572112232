export const directionSite = (language: string) => {
  // ar === Arabic
  // he === Hebrew
  // fa === Persian
  // ps === Pashto
  // ur === Urdu
  //    === Kashmiri
  // sd === Sindhi

  const arrLanguages = ['ar', 'he', 'fa', 'ps', 'ur', 'sd'];
  return arrLanguages.includes(language) ? 'rtl' : 'ltr';
};
