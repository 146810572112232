import React from 'react';
import HairStyle2 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style1';
import HairStyle3 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style2';
import HairStyle4 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style3';
import HairStyle5 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style4';
import HairStyle6 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style5';
import HairStyle7 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style6';
import HairStyle8 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style7';
import HairStyle9 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style8';
import HairStyle14 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style9';
import HairStyle18 from 'components/pages/Account/components/PreviewUserAvatar/RenderHairStyle/style10';

const RenderHairStyle = (userValues, userColor) => {
  switch (userValues) {
    case 1:
    case 10:
    case 11:
    case 12:
    case 13:
    case 15:
    case 16:
    case 17:
      return <></>;
    case 2:
      return <HairStyle2 userColor={userColor} />;
    case 3:
      return <HairStyle3 userColor={userColor} />;
    case 4:
      return <HairStyle4 userColor={userColor} />;
    case 5:
      return <HairStyle5 userColor={userColor} />;
    case 6:
      return <HairStyle6 userColor={userColor} />;
    case 7:
      return <HairStyle7 userColor={userColor} />;
    case 8:
      return <HairStyle8 userColor={userColor} />;
    case 9:
      return <HairStyle9 userColor={userColor} />;
    case 14:
      return <HairStyle14 userColor={userColor} />;
    case 18:
      return <HairStyle18 />;
    default:
      return <></>;
  }
};

export default RenderHairStyle;
