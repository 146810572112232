import React from 'react';
import styles from '../Customize.module.scss';
import { accountSettingsBodyColor } from 'additionalInfo/index';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames/bind';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import { setUserAccountData } from 'features/userAccount/userAccountSlice';

const BodyColorSettings = ({ initialValues, handleChange }) => {
  const dispatch = useAppDispatch();

  const handleChangeBodyColor = (event, handleChange) => {
    handleChange(event);
    let spredValues = {
      ...initialValues,
      body_color: event.target.value,
    };
    dispatch(setUserAccountData({ userSiteSettings: spredValues }));
  };
  const cn: Function = classNames.bind(styles);
  const bodyColorStyle = (compare: boolean) =>
    cn({
      body_color_box_item: true,
      active: compare,
    });
  const bodyColorBoxStyles = cn({ hair_color_box: true });

  return (
    <>
      <div className={styles.section_title} id="body-color">
        Body color
      </div>
      <div className={`${styles.body_color_box_wrap}`} role="group" aria-labelledby="body-color">
        {accountSettingsBodyColor.map((singleItem) => (
          <label className={bodyColorStyle(initialValues.body_color === singleItem.value)} key={nanoid()}>
            <input
              value={singleItem.value}
              name="body_color"
              type="radio"
              checked={initialValues.body_color === singleItem.value}
              onChange={(event) => handleChangeBodyColor(event, handleChange)}
            />
            <div style={{ background: singleItem.value }} className={bodyColorBoxStyles}></div>
          </label>
        ))}
      </div>
    </>
  );
};

export default BodyColorSettings;
