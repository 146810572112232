import React from 'react';

const BangStyle16 = ({ userColor }) => {
  return (
    <>
      <svg width="324" height="350" viewBox="0 0 324 350" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M94 125V82C94 55.4903 115.49 34 142 34H180.528C206.83 34 228.234 55.1683 228.525 81.4693L229 124.445C225.396 111.923 221.715 103.88 214.059 96.5988C206.893 89.7832 195.862 91.0575 187.413 96.1983C168.229 107.871 153.562 108.611 134.613 97.4095C125.288 91.897 112.91 90.8432 106.313 99.4354C100.977 106.385 97.8994 114.132 94 125Z"
          fill={userColor}
        />
        <g filter="url(#filter0_f_4638_52299)">
          <rect x="94" y="86" width="134" height="13" fill="#1A1A1A" fillOpacity="0.55" />
        </g>
        <path
          d="M93.3906 46.6469C93.3906 23.1816 113.489 4.98638 136.934 5.95255C153.228 6.62401 168.329 6.62897 184.837 5.94877C208.284 4.98264 228.391 23.1853 228.391 46.6526V83H93.3906V46.6469Z"
          fill="#323232"
        />
        <g filter="url(#filter1_f_4638_52299)">
          <rect x="94" y="74" width="134" height="11" fill="#1A1A1A" fillOpacity="0.45" />
        </g>
        <g filter="url(#filter2_d_4638_52299)">
          <rect x="78" y="78" width="167" height="19" rx="4" fill="#323232" />
        </g>
        <defs>
          <filter
            id="filter0_f_4638_52299"
            x="82"
            y="74"
            width="158"
            height="37"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_4638_52299" />
          </filter>
          <filter
            id="filter1_f_4638_52299"
            x="82"
            y="62"
            width="158"
            height="35"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_4638_52299" />
          </filter>
          <filter
            id="filter2_d_4638_52299"
            x="66"
            y="70"
            width="191"
            height="43"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.101961 0 0 0 0 0.101961 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4638_52299" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4638_52299" result="shape" />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default BangStyle16;
