import React from 'react';

const ClothesStyle4 = () => {
  return (
    <>
      <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_8158_59319)">
        <path
          d="M146.771 127.226C146.814 127.206 146.877 127.222 146.908 127.276C148.428 129.911 152.11 129.705 153.257 127.3C153.288 127.235 153.358 127.215 153.403 127.236C155.942 128.387 157.609 129.611 158.748 131.209C159.888 132.809 160.512 134.804 160.927 137.523C160.936 137.58 160.891 137.631 160.836 137.631H139.17C139.114 137.631 139.07 137.58 139.079 137.523C139.527 134.667 140.265 132.649 141.474 131.07C142.682 129.492 144.376 128.334 146.771 127.226Z"
          fill="#DBEAFE"
          stroke="#323232"
          strokeWidth="0.375062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M149.014 129.154H151.085V137.63H149.014V129.154Z" fill="#323232" />
        <ellipse cx="150.049" cy="132.545" rx="0.564966" ry="0.565028" fill="white" />
        <ellipse cx="150.049" cy="134.993" rx="0.564966" ry="0.565028" fill="white" />
      </g>
      <clipPath id="clip0_8134_58100">
        <rect x="134.812" y="104.719" width="30.375" height="32.8125" rx="15.1875" fill="white" />
      </clipPath>
    </>
  );
};

export default ClothesStyle4;
