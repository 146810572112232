import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../SingleStory.module.scss';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Button from 'components/common/Button/Button';
import { customGoogleTranslations } from 'app/hooks/useGoogleTranslations';
import { directionSite } from 'app/hooks/directionSite';
import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import { setIsStoryViewedFromExplore } from 'features/moduleStory/moduleStory';

const StoryContent = ({ langToShowStory, storyData, getlocalizationInfo, handleRandomStory }: any) => {
  const observerRef = useRef();
  const dispatch = useAppDispatch();
  const isAnyStoryViewed = useAppSelector((state) => state.userModuleStory.storyViewedFromExplore);
  const { title, storyMediaStack, description } = storyData;
  const [storyTranslatedTitle, setStoryTranslatedTitle]: any = useState(null);
  const [storyTranslatedDescription, setStoryTranslatedDescription]: any = useState(null);
  const direction = directionSite(langToShowStory);

  const endPageObserver = useMemo(() => {
    return new IntersectionObserver(() => {
      dispatch(setIsStoryViewedFromExplore(true));
      endPageObserver.unobserve(observerRef.current);
    });
  }, [dispatch]);

  const storyVideo = storyMediaStack.find((obj) => obj?.type === 'video');
  const storyImageCover = storyMediaStack.find((obj) => obj?.cover && obj?.type === 'image');

  useEffect(() => {
    if (title && description) {
      customGoogleTranslations(title, langToShowStory, setStoryTranslatedTitle);
      customGoogleTranslations(description, langToShowStory, setStoryTranslatedDescription);
    }
  }, [storyData, langToShowStory]);

  useEffect(() => {
    if (!isAnyStoryViewed) {
      const observerRefCurrent = observerRef.current;
      if (observerRefCurrent) endPageObserver.observe(observerRefCurrent);

      return () => (observerRefCurrent ? endPageObserver.unobserve(observerRefCurrent) : null);
    }
  }, [isAnyStoryViewed, endPageObserver]);

  return (
    <SimpleBar dir={direction} className={styles.story_content}>
      <h1 className={styles.story_title}>{storyTranslatedTitle || title}</h1>
      {storyVideo?.media?.url && (
        <div className={styles.video_wrap}>
          <video controls width={'100%'} height={'auto'}>
            <source type="video/mp4" src={storyVideo?.media?.url} />
          </video>
        </div>
      )}
      {storyImageCover?.media?.url && (
        <img
          alt={storyImageCover.media?.assetDescription || storyImageCover?.caption || 'story image'}
          className={styles.story_image}
          src={storyMediaStack.find((obj) => obj?.cover && obj?.type === 'image')?.media?.url}
        />
      )}
      <div className={styles.story_text}>{storyTranslatedDescription || description}</div>
      <div className={styles.gallery}>
        {/* eslint-disable-next-line operator-linebreak */}
        {storyMediaStack?.filter((item) => !item.cover && item.type === 'image')?.length > 0 &&
          storyMediaStack
            ?.filter((item) => !item.cover && item.type === 'image')
            .map((item) => {
              return (
                <img
                  src={item.media.url}
                  alt={`${item.media?.assetDescription || item.caption || 'gallery image'}`}
                  className={styles.gal_image}
                  key={item.id}
                />
              );
            })}
      </div>
      <Button onClick={handleRandomStory} type="button" className={'button button_border'}>
        {getlocalizationInfo?.next_story}
      </Button>
      <div ref={observerRef} style={{ visibility: 'hidden' }} />
    </SimpleBar>
  );
};

export default StoryContent;
