import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { entryApi } from 'app/entryApi';
import { RootState } from 'app/store';

interface progressInterface {
  module_id: string;
  course_id: string;
  get_ready: number;
  explore: number;
  share: number;
  lang?: string;
}

interface articleInterface {
  id: string;
  title: string;
  contents: [];
}

export const modulesApi = entryApi.injectEndpoints({
  endpoints: (build) => ({
    getSingleModule: build.query({
      query: (module) => {
        if (typeof module === 'object') {
          return { headers: { 'accept-language': module.lang }, url: `/module/${module.id}` };
        }
        return { url: `/module/${module}` };
      },
      transformResponse: (data: { module: { [key: string]: any } }) => data.module,
    }),
    getSingleArticle: build.query({
      query: ({ id, lang }) => {
        return { headers: { 'accept-language': lang }, url: `/article/${id}` };
      },
      transformResponse: (data: { page: articleInterface }) => (data.page ? [data.page] : []),
    }),
    getAllArticles: build.query({
      async queryFn({ ids, lang }, _queryApi, _extraOptions, fetchWithBQ) {
        const langHeader = { 'accept-language': lang };
        /* eslint-disable */
        try {
          const articles = await Promise.all(
            ids.map((id) =>
              fetchWithBQ({
                url: `/article/${id}`,
                headers: langHeader,
              })
            )
          ).then((resp) => resp.map((articles) => articles.data.page));

          return { data: articles };
        } catch (err) {
          return { error: err.message };
        }
      },
    }),
    saveModuleProgress: build.mutation({
      query(progress: progressInterface) {
        return {
          url: '/module/progress',
          method: 'POST',
          body: progress,
        };
      },
      invalidatesTags: ['GET_SINGLE_COURSE', 'GET_ALL_COURSES'],
      onQueryStarted({ module_id, lang, ...updates }, { dispatch, queryFulfilled }) {
        const initialQueryParams = lang ? { id: module_id, lang } : module_id;

        const progressUpdate = dispatch(
          modulesApi.util.updateQueryData('getSingleModule', initialQueryParams, (storeModule) => {
            Object.assign(storeModule, updates);
          })
        );
        queryFulfilled.catch(progressUpdate.undo);
      },
    }),
    sendSurveyData: build.mutation({
      query: (surveyData) => {
        return {
          url: '/module/survey',
          method: 'POST',
          body: surveyData,
        };
      },
      invalidatesTags: ['GET_SINGLE_COURSE'],
    }),
  }),
});

export interface currentModuleState {
  module_id?: string;
  course_id?: string;
}

const initialState: currentModuleState = { module_id: '', course_id: '' };

export const currentModuleSlice = createSlice({
  name: 'currentModule',
  initialState,
  reducers: {
    setCurrentModule: (state, action: PayloadAction<string>) => {
      state.module_id = action.payload;
    },
    setCurrentCourse: (state, action: PayloadAction<string>) => {
      state.course_id = action.payload;
    },
  },
});

export const { setCurrentModule, setCurrentCourse } = currentModuleSlice.actions;
export const getCurrentState = (state: RootState) => state.currentModule;

export default currentModuleSlice.reducer;
