import React from 'react';
import styles from '../Customize.module.scss';
import { accountSettingsHairColor } from 'additionalInfo/index';
import { nanoid } from '@reduxjs/toolkit';
import ColorSelect from 'components/pages/Account/components/ColorSelect';
import classNames from 'classnames/bind';
import { setUserAccountData } from 'features/userAccount/userAccountSlice';
import { useAppDispatch } from 'app/hooks/reduxHooks';

const HairColorSettings = ({ initialValues, handleChange }) => {
  const [defaultHairColorSelect, setDefaultHairColorSelect] = React.useState('#666');
  const dispatch = useAppDispatch();
  const handleChangeHairColor = (event, handleChange) => {
    handleChange(event);
    let spredValues = {
      ...initialValues,
      hair_color: event.target.value,
    };
    dispatch(setUserAccountData({ userSiteSettings: spredValues }));
  };
  const cn: Function = classNames.bind(styles);
  const colorPikerStyle = (compare: boolean) =>
    cn({
      hair_color_box_item: true,
      hair_color_color_piker: true,
      active: compare,
    });
  const labelStyles = (compare: boolean) =>
    cn({
      hair_color_box_item: true,
      active: compare,
    });
  const colorBoxStyles = cn({ hair_color_box: true });

  return (
    <>
      <div className={styles.section_title} id="hair-color">
        Hair color
      </div>
      <div className={`${styles.hair_color_box_wrap}`} role="group" aria-labelledby="hair-color">
        {accountSettingsHairColor.map((singleItem) => (
          <label className={labelStyles(initialValues.hair_color === singleItem.value)} key={nanoid()}>
            <input
              value={singleItem.value}
              name="hair_color"
              type="radio"
              checked={initialValues.hair_color === singleItem.value}
              onChange={(event) => handleChangeHairColor(event, handleChange)}
            />
            <div style={{ background: singleItem.value }} className={colorBoxStyles}></div>
          </label>
        ))}
        <label className={colorPikerStyle(initialValues.hair_color === defaultHairColorSelect)}>
          <input
            value={defaultHairColorSelect}
            name="hair_color"
            type="radio"
            checked={initialValues.hair_color === defaultHairColorSelect}
            onChange={(event) => handleChangeHairColor(event, handleChange)}
          />
          <ColorSelect
            defaultHairColorSelect={defaultHairColorSelect}
            setDefaultHairColorSelect={setDefaultHairColorSelect}
          />
        </label>
      </div>
    </>
  );
};

export default HairColorSettings;
